import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";

import {
  Brush,
  CartesianGrid,
  DotProps,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { graphDataType } from "utils/data-types";
import BatchCompletionLabels from "./batch-completion-labels";
import { ParseFloat } from "utils/common-methods";

interface lineGraphDataProps {
  graphData: graphDataType[];
}

interface CustomDotProps extends DotProps {
  payload?: {
    name: string;
    oee: number;
    dotted: boolean;
  };
}

const CustomizedDot: React.FC<CustomDotProps> = ({
  cx,
  cy,
  stroke,
  payload,
}) => {
  if (cx === undefined || cy === undefined || !payload || !payload.dotted) {
    return null;
  }

  return payload.dotted ? (
    <svg
      x={cx - 3.5}
      y={cy - 3.5}
      width={7}
      height={7}
      fill={stroke}
      viewBox="0 0 7 7"
    >
      <circle cx={3.5} cy={3.5} r={3.5} />
    </svg>
  ) : null;
};

const LineComposedChart: React.FC<lineGraphDataProps> = (
  props: lineGraphDataProps
) => {
  const { graphData } = props;

  const CustomTooltip = (childProp: any) => {
    if (childProp.active && childProp.payload && childProp.payload?.length) {
      const defaultHValue = "00:00"
      const HValue = childProp.payload[0].payload.h || defaultHValue;
      const [date, time] = HValue.split('T');
      const [hours, minutes] = time.split(':');
      const formattedTime = `${hours}:${minutes}`;
      return (
        <div className="aoh-custom-tooltip-batch aoh-pl-2 aoh-pt-2 aoh-pb-2 aoh-pr-4">
          <div className="aoh-pb-1 aoh-batch-date">
            <span className="aoh-article-title ops-fw-semibold">DATE:</span> {date}
          </div>
          <div className="aoh-pb-1 aoh-batch-time">
            <span className="aoh-article-title ops-fw-semibold">TIME:</span> {formattedTime}
          </div>
          <div className="aoh-pb-1 aoh-batch-actual">Actual :&nbsp;{childProp.payload[0].payload.actual}%</div>
          <div className="aoh-pb-1 aoh-batch-target">Target :&nbsp;{childProp.payload[0].payload.target}%</div>
        </div>
      );
    }
    return null;
  };

  const renderCustomAxisTick = ({ x, y, payload }: { x: number, y: number, payload: any }) => {
    const date = new Date(payload.value).toLocaleString("en", {
      month: "2-digit",
      day: "2-digit"
    })
    const time = new Date(payload.value).toLocaleString("en", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit"
    })
    return (
      <>
        <text offset="5" x={x} y={y + 4}>{date}</text>
        <text offset="5" x={x} y={y + 20}>{time}</text>
      </>
    );
  }

  return (
    graphData && (
      <>
        <div className="aoh-pd-0 aoh-mt-1 aoh-row aoh-batch-completion-graph">
          <ResponsiveContainer
            height={"100%"}
            width={"96%"}
          >
            <ComposedChart
              width={500}
              height={300}
              data={[...graphData]}
            
              margin={{
                top: 5,
                right: 0,
                left: -25,
                bottom: 5,
              }}
            >
              <XAxis
                interval={23}
                padding={'gap'}
                // tickFormatter={(val, i) => {
                //   return `${ParseFloat((parseInt(val)/60).toFixed(2),2)}H`;
                // }}
                dataKey={"h"}
                tickLine={false}
                axisLine={false}
                height={26}
                tick={renderCustomAxisTick}
              />
              <YAxis
                tickFormatter={(val) => `${val}%`}
                padding={{ bottom: 10 }}
                stroke="#9c9c9c"
                tickLine={false}
                axisLine={false}
              />
              <CartesianGrid
                vertical={false}
                stroke="#e3e3e3"
                horizontalPoints={ graphData.length > 196 ? [6, 36, 66, 97, 128, 139] : [6, 36, 66, 97, 128, 159]}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                strokeWidth={"2px"}
                stroke="#0063C3"
                dot={<CustomizedDot />}
                type="monotone"
                dataKey={"actual"}
              />
              <Line
                strokeWidth={"2px"}
                stroke="#75A8DD"
                dot={<CustomizedDot />}
                type={"monotone"}
                dataKey={"target"}
                strokeDasharray="3 3"

              />
              {graphData.length >  196 && <Brush 
                dataKey={'h'} 
                tickFormatter={(value) => `${ParseFloat((parseInt(value)/60).toFixed(2),2)}H`} 
                startIndex={0} 
                stroke="#0063c3" 
                endIndex={23 * 4} 
                height={18} 
                color="#0063c3" 
              />}
            </ComposedChart>
            
          </ResponsiveContainer>
        </div>
        <div className="aoh-pl-1">
          <BatchCompletionLabels />
        </div>
      </>
    )
  );
};
export default LineComposedChart;
