import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import type { MenuProps } from 'antd';
import dropDowncss from './aohdropdown.module.css'

interface AohDropdownType {
    items: MenuProps['items']
    handleMenuClick?: MenuProps['onClick']
    title: string
    dropOptions?: string
}

const AohDropdown: React.FC<AohDropdownType> = ({ items, handleMenuClick, title, dropOptions}) => {

    const { label, drop, customDropdown} = dropDowncss
    const menuProps = {
        items,
        onClick: handleMenuClick,
        horizontalItemHoverColor: '#1F1F1F'   
    }
    return (
        <div className={drop}>
            <span className={label}>{title} : </span>
            <Space wrap>

                <Dropdown menu={menuProps} overlayClassName={customDropdown}>
                    <Button>
                        <Space>
                            {dropOptions}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>

            </Space>
        </div>
    )
}

export default AohDropdown
