import React from "react";
import CardComponent from "../../resuable/card";
import StageSection from "./stage-section";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { statusOverviewDataType } from "utils/data-types";
interface StatusOverviewProps {
  statusOverviewData: statusOverviewDataType;
  isLoading: boolean;
  isError: boolean;
  isRefetching : boolean
}

const StatusOverview: React.FC<StatusOverviewProps> = (
  props: StatusOverviewProps
) => {
  const { statusOverviewData, isError, isLoading ,isRefetching} = props;

  return (
    <CardComponent header={"Status Overview"}>
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {!isLoading && !isError && statusOverviewData ? (
        <StageSection
        currentTime={statusOverviewData.current_time}
        nextLotTime={statusOverviewData.next_lot_time}
        colorCode={statusOverviewData.color_code}
        progressValue={statusOverviewData.progress_value}
        progressValueColorCode={statusOverviewData.progress_value_color_code}
        ncaStartOrder={statusOverviewData.nca_start_order}
        ncaStartDesc={statusOverviewData.nca_start_desc}
        ncaEndOrder={statusOverviewData.nca_end_order}
        ncaEndDesc={statusOverviewData.nca_end_desc}
        isRefetching={isRefetching}
        data = {statusOverviewData}
        />
      ) : !isLoading && !isError && !statusOverviewData ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    </CardComponent>
  );
};

export default StatusOverview;
