import "./index.scss";
import "../../../assets/bootstrap.scss";
import CardComponent from "../../resuable/card";
import CompletionSection from "./completion-section";
import OeeSection from "./oee-section";
import LineComposedChart from "./line-composed-chart";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import KpiModal from "./kpi-modal";
import { useState } from "react";
import { useParams } from "react-router-dom";

interface KpiDataProps {
  kpiData: any;
  isLoading: boolean;
  isError: boolean;
}

const Kpi: React.FC<KpiDataProps> = (props: KpiDataProps) => {
  const { kpiData, isError, isLoading } = props;
  const oeeSection = kpiData?.oee;
  const [kpiModalState, setKpiModalState] = useState<boolean>(false);
  const { line } = useParams();
  return (
    <CardComponent header="Key Performance Indicators" isToolTip={true}>
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {!isLoading && !isError && (!kpiData || kpiData?.length === 0) ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        kpiData && (
          <div className="aoh-line-container aoh-mt-2">
            <OeeSection
              colorCode={oeeSection?.color_code}
              label={oeeSection?.label}
              progressLabel={oeeSection?.progress_label}
              value={0}
            />
            {kpiData?.oee_graph?.length > 0 ? (
              <LineComposedChart graphData={kpiData?.oee_graph ?? []} />
            ) : (
              <APIErrorMessage apierror={"No  Graph Data Available"} />
            )}
            <CompletionSection
              availability={kpiData?.availability}
              performance={kpiData?.performance}
              quality={kpiData?.quality}
              currentUpm={kpiData?.cupm}
              targetUpm={kpiData?.tupm}
            />
            <div className="aoh-d-flex aoh-kpi-view-more aoh-pt-3">
              {/* <PrimaryButton
              title="View More"
              onClick={() => {
                setKpiModalState(true);
              }}
            ></PrimaryButton> */}
            </div>
          </div>
        )
      )}
      <div className="aoh-kpi-modal-container aoh-pt-0">
        <KpiModal
          title={`Key Performance Indicators - Line ${line?.toUpperCase()}`}
          opened={kpiModalState}
          close={() => {
            setKpiModalState(false);
          }}
        />
      </div>
    </CardComponent>
  );
};

export default Kpi;
