import { fetchApi, shiftPayloadType } from "api/fetch-api";

const baseUrl: string  = process.env.REACT_APP_API_URL ?? "";

export const getStatusList = async (data:shiftPayloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${baseUrl}/shopfloorExpanded/get_status_list`,
    data 
  })
 
  return response;
   
}
