import { useState } from 'react';
import { Button, Modal } from 'antd';

interface WithModalProps {
  openModal: () => void;
  title: string;
}

function withModal<T extends object> (WrappedComponent: React.ComponentType<T & WithModalProps>) {
  const HOC: React.FC<T> = (props: T) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const showLoading = () => {
      setOpen(true);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    const openModal = () => {
      setOpen(true);
      showLoading();
    };

    return (
      <div>
        <WrappedComponent {...props} openModal={openModal} title="Modal Title" />

        <Modal
        title={"Modal Title"}
        footer={
          <Button type="primary" onClick={showLoading}>
            Reload
          </Button>
        }
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
      >
        {(loading && <p>Loading...</p>) ||  (
          <div>
            {/* {children} */}
            <p>Some contents...</p>
            <p>Some contents...</p>
          </div>
        )}
        </Modal>
      </div>
    );
  };

  HOC.displayName = `WithModal(${WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'})`;

  return HOC;
}

export default withModal;
