import "./index.scss";
import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  DotProps,
} from "recharts";
import KpiModalStatusLabels from "../kpi-modal-status-labels";
import KpiDateTooltip from "../../kpi-tooltips/kpi-date-tooltip";
import KpiBatchTooltip from "../../kpi-tooltips/kpi-batch-tooltip";
import KpiShiftTooltip from "../../kpi-tooltips/kpi-shift-tooltip";
import "../../../../../assets/bootstrap.scss";

interface CustomDotProps extends DotProps {
  payload?: {
    name: string;
    uv: number;
    dotted: boolean;
  };
}

interface KpiLineChartGraphProps {
  graphData: OeePercentageValue[];
  oeeDataSelect: string;
}

interface OeePercentageValue {
  date: string;
  oee: number;
  target: number;
  availability: number;
  performance: number;
  quality: number;
  dotted: boolean;
}

const KpiModalLineChart: React.FC<KpiLineChartGraphProps> = (props) => {
  const { graphData, oeeDataSelect } = props;
  const [lineDisplay, setLineDisplay] = useState({
    oee: true,
    target: false,
    availability: false,
    performance: false,
    quality: false,
  });
  const [lineOeeData, setLineOeeData] = useState<any>([]);

  const CustomizedDot: React.FC<CustomDotProps> = ({
    cx,
    cy,
    stroke,
    payload,
  }) => {
    if (cx === undefined || cy === undefined || !payload || !payload.dotted) {
      return null;
    }

    return payload.dotted ? (
      <svg
        x={cx - 3.5}
        y={cy - 3.5}
        width={7}
        height={7}
        fill={stroke}
        viewBox="0 0 7 7"
      >
        <circle cx={3.5} cy={3.5} r={3.5} />
      </svg>
    ) : null;
  };

  // const xaxisTicks = props?.graphData?.map((singleData) => {
  //  
  //   return singleData.date;
  // });

  const CustomTicks = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={3} y={2} dy={12} fill="#666">
          <tspan textAnchor="middle" x="0">
            {payload.value.split(" ")[0]}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="15">
            {payload.value.split(" ")[1]}
          </tspan>
        </text>
      </g>
    );
  };

  const CustomTooltip = (childProp: any) => {
    if (childProp.active && childProp.payload && childProp.payload?.length) {
      return (
        <div className="aoh-custom-tooltip aoh-mb-4">
          {oeeDataSelect === "Batch" ? (
            <KpiBatchTooltip kpiBatchData={undefined} isThroughput={false} />
          ) : oeeDataSelect === "Shift" ? (
            <KpiShiftTooltip kpiShiftData={undefined} isThroughput={false} />
          ) : oeeDataSelect === "Time" ? (
            <KpiDateTooltip kpiDateData={undefined} isThroughput={true} />
          ) : null}
        </div>
      );
    }
    return null;
  };

  const lineDisplayHandler = (title: string) => {
    if (title === "oee") {
      setLineDisplay({ ...lineDisplay, oee: !lineDisplay.oee });
    } else if (title === "target") {
      setLineDisplay({ ...lineDisplay, target: !lineDisplay.target });
    } else if (title === "availability") {
      setLineDisplay({
        ...lineDisplay,
        availability: !lineDisplay.availability,
      });
    } else if (title === "performance") {
      setLineDisplay({ ...lineDisplay, performance: !lineDisplay.performance });
    } else if (title === "quality") {
      setLineDisplay({ ...lineDisplay, quality: !lineDisplay.quality });
    }
  };

  return (
    <>
      <div className="aoh-pd-0 aoh-mt-2 aoh-row aoh-kpi-graph aoh-mb-2">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={graphData ?? []}
            onMouseMove={(data) => {
              setLineOeeData(data);
            }}
            margin={{
              top: 20,
              right: 0,
              left: -26,
              bottom: 5,
            }}
          >
            <CartesianGrid
              // strokeDasharray="none"
              vertical={false}
              stroke="#e3e3e3"
              horizontalPoints={[20, 49, 77, 106, 136, 165]}
            />
            <XAxis
              dataKey="date"
              tick={<CustomTicks />}
              // ticks={xaxisTicks}
              stroke="#e3e3e3"
              // tickLine={false}
              axisLine={false}
              interval={0}
              padding={{ left: 17, right: 17 }}
              height={30}
            ></XAxis>
            <YAxis
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
              tickFormatter={(val) => `${val}%`}
              stroke="#9c9c9c"
              tickLine={false}
              axisLine={false}
            ></YAxis>
            <Tooltip
              content={<CustomTooltip />}
              position={{
                x: lineOeeData.activeCoordinate?.x - 100,
                y: lineOeeData.activeCoordinate?.y - 300,
              }}
            />
            {lineDisplay.oee && (
              <Line
                // type="monotone"
                
                dataKey="oee"
                stroke="#0063c3"
                // dot={<CustomizedDot />}
                dot={{r: 2}}
                strokeWidth={"1px"}
                isAnimationActive={false}
              />
            )}
            {lineDisplay.target && (
              <Line
                // type="monotone"
                
                dataKey="target"
                stroke="#686868"
                strokeDasharray="3 3"
                // dot={<CustomizedDot />}
                dot={false}
                strokeWidth={1}
                isAnimationActive={false}
              />
            )}
            {lineDisplay.availability && (
              <Line
                // type="monotone"
                
                dataKey="availability"
                stroke="#2FBCB6"
                dot={<CustomizedDot />}
                strokeWidth={"1px"}
                isAnimationActive={false}
              />
            )}
            {lineDisplay.performance && (
              <Line
                // type="monotone"
                dataKey="performance"
                stroke="#D62728"
                dot={<CustomizedDot />}
                strokeWidth={"1px"}
                isAnimationActive={false}
              />
            )}
            {lineDisplay.quality && (
              <Line
                // type="monotone"
                
                dataKey="quality"
                stroke="#FFFF00"
                dot={<CustomizedDot />}
                strokeWidth={"1px"}
                isAnimationActive={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div>
        <KpiModalStatusLabels
          lineDisplayHandler={lineDisplayHandler}
          lineDisplayValues={lineDisplay}
        />
      </div>
    </>
  );
};
export default KpiModalLineChart;
