import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import "./index.scss";

const ProductionKpiLabels: React.FC = () => {

  return (
    <div className="aoh-d-flex aoh-production-labels aoh-mt-3 aoh-mb-2">
      <div className="aoh-running-status aoh-pl-2 align-items-center">
        <div
          className="aoh-d-flex aoh-label-container align-items-center"
        >
          <div className="aoh-pr-1 align-items-center aoh-d-flex">
            <CircleIcon
              fill={"#0063c3"}
              stroke={"#0063c3"}
            />
          </div>
          <div className="aoh-d-flex aoh-running-label aoh-text-uppercase ops-fs-6 ops-fw-bold align-items-center">
            {"actual"}
          </div>
        </div>
      </div>
      <div className="aoh-running-status aoh-ml-2 ">
        <div
          className="aoh-d-flex aoh-label-container align-items-center"
        >
          <div className="aoh-pr-1 aoh-d-flex">
          <CircleIcon
              fill={"#686868"}
              stroke={"#686868"}
            />
          </div>
          <div className="aoh-d-flex aoh-running-label aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"target"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductionKpiLabels;
