// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workCenterTeam_dropContainer__dIKKY{
    display: flex;
    margin-top: 5px;
    width: 1070px;
    height: 52px;
    justify-content: end;
    padding: 9px 0px 5px 0px;
    border-bottom: 1px solid #D9D9D9;
}`, "",{"version":3,"sources":["webpack://./src/page/workCenterTeam.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,wBAAwB;IACxB,gCAAgC;AACpC","sourcesContent":[".dropContainer{\n    display: flex;\n    margin-top: 5px;\n    width: 1070px;\n    height: 52px;\n    justify-content: end;\n    padding: 9px 0px 5px 0px;\n    border-bottom: 1px solid #D9D9D9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropContainer": `workCenterTeam_dropContainer__dIKKY`
};
export default ___CSS_LOADER_EXPORT___;
