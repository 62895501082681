import "./index.scss";
import "../../../assets/bootstrap.scss";
import ArticleCard from "./article-card";
import CardComponent from "../../resuable/card";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { upcomingLotsDataType, upcomingLotsDataObject } from "utils/data-types";

interface UpcomingLotsProps {
  upcomingLotsData: upcomingLotsDataType;
  isLoading: boolean;
  isError: boolean;
  delayTime: string;
  delayStatus: string;
  absoluteDelay: string;
}

const UpcomingLotsLine1: React.FC<UpcomingLotsProps> = (
  props: UpcomingLotsProps
) => {

  const { upcomingLotsData, isError, isLoading, delayTime, delayStatus, absoluteDelay } = props;
  const articleLength = upcomingLotsData?.length;
  const defaultDelayTime = "00:00";
  const [hours, minutes] = (delayTime || defaultDelayTime).split(':');
  const formattedDelayTime = `${hours}:${minutes}`;
  return (
    <CardComponent header={"Upcoming Schedule - for Next 24 Hours"}>
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {upcomingLotsData && upcomingLotsData?.length > 0 ? (<div className="delay-status-container" style={{ paddingBottom: 4 }}>
        {absoluteDelay !== null ? <div className={`ops-fs-4 ops-fw-bold ${delayStatus === "Delayed by ~" ? 'delay-status-heading' : 'ahead-status-heading'}`}>{delayStatus} {formattedDelayTime} hours</div> : null}
      </div>) : null}
    
      {!isLoading && !isError && upcomingLotsData && upcomingLotsData?.length > 0 ? (
        upcomingLotsData?.map((article: upcomingLotsDataObject, index: number) => {
          return (
            <ArticleCard
              totalCard={articleLength}
              index={index}
              key={index}
              materialDesc={article.material_desc}
              batchNo={article.batch_no}
              changeOver={article.changeover}
              dateTime={article.date_time}
              unitsPlanned={article.units_planned}
              // delayStatus={article.delay_status}
              // absoluteDelay={article.absolute_delay}
              // delayTime={article.delay_time}
            />
          );
        })
      ) : !isLoading &&
        !isError &&
        (!upcomingLotsData || upcomingLotsData?.length === 0) ? (
        <APIErrorMessage apierror={"No Upcoming Lots"} />
      ) : (
        <></>
      )}
      {/* <div className="aoh-upcoming-schedule-view">
        <Clickthrough
          className="aoh-w-100"
          label="VIEW SCHEDULE IN VIRTECH"
          target="_blank"
          type="secondary"
          href={""}
          iconPosition="right"
          icon={<ExternalLinkIcon width={12} height={12} />}
          disabled={false}
        />
      </div> */}
    </CardComponent>
  );
};

export default UpcomingLotsLine1;
