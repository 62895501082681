/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from "axios";
import { getOktaToken } from "utils/common-methods";

interface configureDataProp {
  url: string;
  method: string;
  header: object;
  data:
  | machineModalHourType
  | payloadType
  | shiftPayloadType
  | allLinesPayloadType
  | nonCommTilePayloadType;
}

export const fetchApi: (configureData: configureDataProp) => any = async (
  configureData
) => {
  const token: string = getOktaToken();
  try {
    return await axios({
      ...configureData,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export interface lineRowType {
  manufacturing_stage: any;
  building: any;
  batch_no: string;
  line_desc: string;
  line_status: string;
  line_status_color_code: string;
  machine_status: string;
  material_desc: string;
  oee: string;
  phase: string;
  progress: [];
  progress_label: string;
  is_non_commercial: boolean;
}

export interface payloadType {
  site: string;
  line_no: string;
  curr_stage: string;
  mfg_stage: string;
  building: string;
}
export interface machineModalHourType {
  date: string;
  type: string;
  status: string;
}

export interface shiftPayloadType {
  site: string;
  line_no: string;
  mfg_stage: string;
  building: string;

}
export interface allLinesPayloadType {
  site: string;
}
export interface nonCommTilePayloadType {
  line_no: string;
  site: string;
  mfg_stage: string;
  building: string;

}

export interface idpDetailsPayloadType {
  site: string;
  building: string;
  line_no: string;
}
