import { Status } from "@gitlab-rtsensing/component-library";
import React from "react";
import { BatchDataResponse } from "../../../assets/types/get-batch-information";
import './index.scss'
import StatusLogo from "components/resuable/status-logo";
const StatusAndHeader: React.FC<BatchDataResponse> = (
  props: BatchDataResponse
) => {
  const { title, status } = props;
  return (
    <div className="aoh-d-flex aoh-line-tab-data">
      {title !== "All Lines"
? (
        <div className="d-flex aoh-line-status align-items-center">
          <StatusLogo color={status?.toLowerCase()} size={15} alertClassName="aoh-disable-status-hover" className={`${status?.toLowerCase()==="grey" ? "ops-text-light aoh-gray-icon-all-lines" : ""}`}/>
        </div>
      )
: (
        <div className="d-flex aoh-all-lines align-items-center">
          <Status alert="Green" height={0} alertClassName="aoh-disable-status-hover" />
        </div>
      )}
      <div className="d-flex align-items-center aoh-mr-2 aoh-pl-1 aoh-pr-2 ops-fs-5">
        {title}
      </div>
    </div>
  );
};
export default StatusAndHeader;