import  './index.scss';

interface DctDropDownType {
    handlerChange: (e:any) => void
    label: string
    selectedValue: string | null
    labelTop?: boolean
    optionList: {
        name: string
        value: string
    }[]
}
const DctDropDown: React.FC<DctDropDownType> = ({handlerChange, label, optionList, selectedValue, labelTop}) => {
    return (
        <div className={`aoh-d-flex ${labelTop ? 'aoh-flex-column ': 'aoh-align-items-center'} 'ops-mt-2' `}>
        <span className="aoh-dropdown-value ops-mt-2 ops-fw-semibold ops-fs-6">{label}</span>  
          <select
            className={`aoh-dropdown ops-fs-5 ops-fw-medium ops-mt-2 ${!labelTop && 'aoh-ml-2'}`}
            onChange={(e) => {
              handlerChange(e.target.value)
            }}
            value={selectedValue ?? label} 
            defaultValue="Line Status"
          >
            {optionList.map((item: any, index: number) => {
              return (
                <option key={index} value={item.value} className={`aoh-dropdown-value aoh-dropdown-text `}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
    )
}
export default DctDropDown
