import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import { idpDetailsPayloadType } from "api/fetch-api";
import RteStatus from ".";

interface RteStatusContainerProps {
  getIdpDetails: (data: idpDetailsPayloadType) => Promise<any>
}
const RteStatusContainer = (props: RteStatusContainerProps) => {
  const { getIdpDetails } = props
  const { appState } = useContext(AppContext);
  const location: any = useParams();
  const {
    data: idpDetailsData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getIdpDetails", location.line],
    queryFn: getIdpDetails.bind(this, {
      site: location.site,
      line_no: location.line,
      building: appState.building
    }),
    refetchInterval: appState.refetchInterval,
    // enabled: ""
  });
  interface IDPRTEHUDataProps {
    hu_number_final: string,
    time_out_of_cold_storage: string,
    remaining_time: string,
    updated_on: string,
  }
  const [idpRteHuData, setIdpRteHuData] = useState<IDPRTEHUDataProps[]>(idpDetailsData?.data?.data)
  useEffect(() => {
    setIdpRteHuData(idpDetailsData?.data?.data)
  }, [idpDetailsData?.data?.data])
  return (
    <RteStatus
      idpDetailsData={idpDetailsData?.data?.data}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
export default RteStatusContainer;
