import "./index.scss";
import "../../../assets/bootstrap.scss";
import React, { useContext, useState } from "react";
import MetricCardContent from "./metric-card-content";
import MetricHeader from "./metric-header";
import CardComponent from "../../resuable/card";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { AppContext } from "context/app-context";
import {
  machineStatusDataType,
  machineStatusDataObject,
} from "utils/data-types";
 import MachineStatusModal from "./machine-status-modal";
import {
  Button,
  ExpandArrowsIcon,
} from "@gitlab-rtsensing/component-library";
import { useParams } from "react-router-dom";
import { MatomoContext } from "utils/matomo-context-provider";

interface MachineStatusDataProps {
  machineStatusData: machineStatusDataType;
  isLoading: boolean;
  isError: boolean;
}
const headersData = ["MACHINE", "STATUS", "ACTIVE ALARMS"];
const MachineStatus: React.FC<MachineStatusDataProps> = (
  props: MachineStatusDataProps
) => {
  const { machineStatusData } = props;
  const context = useContext(MatomoContext);
  const [MachineStatusModalState, setMachineStatusModalState] =
    useState<boolean>(false);

  const isActiveAlarms =
    machineStatusData?.filter(
      (el: machineStatusDataObject) =>
        el.active_alarams &&
        ((typeof el.active_alarams === "number" && el.active_alarams > 0) ||
          typeof el.active_alarams === "string")
    )?.length > 0;

  const machineListData = machineStatusData;
  const { appState } = useContext(AppContext);
  const location: any = useParams();

  const updateMachineStatus = (eventName: string) => {
    setMachineStatusModalState(true);
    context.trackEvent("btn_click_event", "Current Machine Status", eventName);
  };

  return (
    <CardComponent header="Current Machine Status" headerStatusColor={"Green"}>
      <Errorhandler
        isLoading={props.isLoading}
        isError={props.isError}
        loaderColor={"BLUE"}
      />
      {!props.isLoading &&
      !props.isError &&
      props.machineStatusData &&
      machineStatusData?.length > 0 ? (
        <>
          <div className="aoh-pl-2 aoh-pr-2">
            <MetricHeader
              isActiveAlarms={isActiveAlarms}
              machine={headersData[0]}
              status={
                appState.phase === "Closedown"
                  ? "CLOSEDOWN STATUS"
                  : headersData[1]
              }
              activeAlarms={headersData[2]}
            />
            {machineListData.map(
              (
                el: machineStatusDataObject,
                i: React.Key | null | undefined
              ) => {
                return (
                  <MetricCardContent
                    isActiveAlarms={isActiveAlarms}
                    key={i}
                    machineValue={el.machine}
                    machineFlag={el.status_color_code}
                    status={el.status}
                    activeAlarms={el.active_alarams}
                    downTime={el.down_time}
                  />
                );
              }
            )}
          </div>
          <div
            className="aoh-machine-status-view"
            onClick={() => updateMachineStatus("View More")}
          >
            <Button
              className="aoh-w-100"
              label="VIEW MORE"
              // target="_blank"
              type="secondary"
              iconPosition="right"
              icon={<ExpandArrowsIcon width={12} height={12} fill="#0063c3"/>}
              disabled={false}
            />
          </div>
          <div className="aoh-machine-modal-container ">
            <MachineStatusModal
              title={`Machine Status - ${location.line}`}
              opened={MachineStatusModalState}
              close={() => {
                setMachineStatusModalState(false);
              }}
            />
          </div>
        </>
      ) : !props.isLoading &&
        !props.isError &&
        (!props.machineStatusData ||
          props.machineStatusData === null ||
          machineStatusData?.length === 0) ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    
    </CardComponent>
  );
};

export default MachineStatus;
