import { fetchApi,  idpDetailsPayloadType } from "api/fetch-api";

export const getIdpDetails = async (data: idpDetailsPayloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/walk_by/idp_details`,
    data
  })

  return response;

}