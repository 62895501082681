/* eslint-disable @typescript-eslint/restrict-plus-operands */
import PageLayout from "components/resuable/layout";
import SegmentHeader from "components/resuable/segment-header";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "context/app-context";
import { Breadcrumb, ConfigProvider } from "antd";
import { 
  permissionObject, personas, tierItems, shiftItems, managerItems, costCenterItems, tierMapping
} from "utils/constants";
import { BreadcrumbMenuItemType, MCT, PermissionType, PersonasAuthorization } from "types/permission-type";
import Header from "./aoh/wct/header/Header"
import type { MenuProps } from 'antd';
import AohDropdown from "components/common/dropdown/aohdropdown";
import wctCss from './workCenterTeam.module.css'
import AohTable from "components/common/table/AohTable";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { increment, decrement, incrementByAmount } from '../counterSlice';
import UserList from '../UserList';
import ColorCodedTable from '../components/common/table/ColorCodedTable';
import MyComponent from "./MyComponent";

interface ShopFloorType {
  permissions: PermissionType[];
  setNavList: (navList: any) => void;
}

const WorkCenterTeam = (props: ShopFloorType) => {
  const dispatch = useDispatch();
  const count = useSelector((state: RootState) => state.counter.value);
  const { dropContainer } = wctCss
  const { appState } = useContext(AppContext);
  const { permissions, setNavList } = props;
  const { stage, site, plant } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [persona, setPersona] = useState<string>('Work Center Team')
  const [tier, setTier] = useState('Tier 0')

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const { key } = e;
    setTier(tierMapping[Number(key)]);
  };

  const mfgItems = [
    {
      key: '1',
      label: <a href="/manufacturing">Manufacturing</a>
    }
    // ,
    // {
    //   key: '2',
    //   label: <a href="/cct">CCT</a>
    // }
  ]

  const avaialableSites: Record<string, boolean> = Object.fromEntries(
    Object.entries(permissionObject).filter(([, value]: [string, boolean]) => value)
  )

  const siteItems: BreadcrumbMenuItemType[] = Object.entries(avaialableSites).map(([key,], index) => {
    return {
      key: `${index + 1}`,
      label: (
        <a rel="noopener noreferrer" key={`${key}-menu-${index + 1}`} href={`/manufacturing/${key}`}>
          {key.toUpperCase()}
        </a>
      )
    }
  })

  const personaObject: PersonasAuthorization | undefined = permissions[1]?.workstreams?.mct
    ?.filter((permission: MCT) => permission.url === site)[0]?.personas

  const personaItems = Object.entries(personaObject ? personaObject : {}).filter(([, value]) => value)
    ?.map(([key,], index) => {
      const persona = personas.filter(persona => persona.name === key)[0]

      return {
        key: `${index + 1}`,
        label: (
          <a rel="noopener noreferrer" href={`/manufacturing/${site}/${persona?.url}`}>
            {persona?.label}
          </a>
        )
      }
    })

  useEffect(() => {
    setNavList({
      label: site?.toUpperCase() ?? "",
      authorized: true,
      externalSections: [],
      internalSections: [
        {
          sectionLabel: "WORK CENTER TEAM",
          links: [{
            label: "SQDIP",
          },
          ...appState.allLines.map((line: string, i: number) => {
            return {
              label: "",
              url: "",
            }
          })]
        },
      ]
    });
  }, [appState]);

  useEffect(() => {
    if (location.pathname.includes("shop-floor")) {
      setPersona('Shop Floor')
    } else {
      setPersona("Work Center Team")
    }
  }, [location])

  // const Ecomp = withModal(MyComponent)

  return (
    <div>
      <PageLayout>
        <div className="aoh-px-64">
          <ConfigProvider theme={{ token: { fontSize: 12 } }}>
            <Breadcrumb
              className="breadcrumb sf-breadcrumb"
              items={[
                {
                  title: <a href="/">Digital Control Tower</a>,
                },
                {
                  title: <span>Manufacturing</span>,
                  menu: { items: mfgItems }
                },
                {
                  title: <span>{site?.toUpperCase()}</span>,
                  menu: { items: siteItems },
                },
                {
                  title: <span>{persona}</span>,
                  menu: { items: personaItems },
                }
              ]}
            />
          </ConfigProvider>
          <div className="sticky-header-shop-floor ">
            <SegmentHeader
              id="work-center-team"
              title={`${site?.toUpperCase() ?? ""} Work Center Team`}
            />
          </div>

          <div>
            <Header items={tierItems} handleMenuClick={handleMenuClick} title='TIER' dropOptions={tier}/>
              {tier !== 'Tier 3' && <div className={dropContainer}>
              {(tier === 'Tier 0' || tier === 'Tier 1') && <AohDropdown items={shiftItems} title='SHIFT' dropOptions='Shift'/>}
              {tier === 'Tier 0' && <AohDropdown items={managerItems} title='MANAGER' dropOptions='Manager Name'/>}
              {tier === 'Tier 2' && <AohDropdown items={costCenterItems} title='COST CENTER' dropOptions='Cost Center Name'/>}
            </div>}
          </div>  
          <MyComponent/>        
          <AohTable/>
          <ColorCodedTable />
        </div>
      </PageLayout>
    </div>
  );
};
export default WorkCenterTeam;