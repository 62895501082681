import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import CompletionSection from "../completion-section";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import CardComponent from "components/resuable/card";
import ShiftSection from "./shift-section";
import ThroughputSection from "../throughput-section";

interface KpiModalTooltipDataProps {
  kpiShiftData: any;
  isThroughput: Boolean;

}

const KpiShiftTooltip: React.FC<KpiModalTooltipDataProps> = (
  props: KpiModalTooltipDataProps
) => {
  const { kpiShiftData, isThroughput } = props;
  return (
    <CardComponent header={""}>
      {/* <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      /> */}
      {/* {!isLoading &&
      !isError &&
      batchCompletionData  ? ( */}
      <div className="aoh-shift-tooltip-container">
        <div className="aoh-shift-container aoh-w-100">
          <ShiftSection
            actual={"actual"}
            comparisionColorCode={"RED"}
            predictedCompletion={"predicted_completion"}
            target={"target"}
            targetCompletion={"target_completion"}
            unitsProduced={"units_produced"}
            unitsPlanned={"units_planned"}
          />
        </div>
        <div className="aoh-avg-target-container aoh-pt-3 aoh-pb-3">
        {!isThroughput && (
            <CompletionSection
              actual={"actual"}
              comparisionColorCode={"RED"}
              predictedCompletion={"predicted_completion"}
              target={"target"}
              targetCompletion={"target_completion"}
              unitsProduced={"units_produced"}
              unitsPlanned={"units_planned"}
            />
          )}
          {isThroughput && (
            <ThroughputSection
              actual={""}
              comparisionColorCode={"GREEN"}
              predictedCompletion={""}
              target={""}
              targetCompletion={""}
              unitsPlanned={""}
              unitsProduced={""}
            />
          )}
        </div>
      </div>

      {/* ) : !isLoading &&
        !isError &&
        !batchCompletionData  ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )} */}
    </CardComponent>
  );
};

export default KpiShiftTooltip;
