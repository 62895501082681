import { 
  useEffect,
  useRef, 
  useState 
} from "react";
import './index.scss'

interface percentDetail {
  percentage: number;
  flag: "BLUE";
}
interface ProgressType {
  percentageArray: percentDetail[];
}
const CleanProgressBar: React.FC<ProgressType> = ({
  percentageArray,
}) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);

  return (
    <div ref={containerRef} className="aoh-clean-progress-container">
      { percentageArray.map((data: percentDetail, i: number) => (
        <div
          key={i}
          className="child-div-container"
          style={{
            width: `${data.percentage}%`,
            // backgroundColor: "#D62728",
          }}
        />
      ))}
    </div>
  );
};
export default CleanProgressBar;
