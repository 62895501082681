
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import RefreshTag from "components/resuable/refresh-tag";
import { refreshNonCommDateType } from "utils/data-types";
import "./index.scss";

interface NonCommHeaderProps {
  refreshData: refreshNonCommDateType;
  isRefreshError: boolean;
  isRefreshLoading: boolean;
}
const NonCommHeader: React.FC<NonCommHeaderProps> = (
  props: NonCommHeaderProps
) => {
  return (
    <div className="aoh-row aoh-title d-flex align-items-center ops-mt-2 ">
      <Errorhandler
        isLoading={props.isRefreshLoading}
        isError={props.isRefreshError}
        loaderColor={"BLUE"}
      />
      {!props.isRefreshLoading && !props.isRefreshError && props.refreshData ? (
        <>
          <div className="d-flex aoh-mt-2 aoh-non-comm-refreshed-tag">
            <RefreshTag data={props.refreshData?.refresh_date} />
          </div>
        </>
      ) : !props.isRefreshLoading &&
        !props.isRefreshError &&
        !props.refreshData ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default NonCommHeader;
