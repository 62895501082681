import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useContext } from "react";
import { AppContext } from "context/app-context";
import StatusOverview from ".";
import { getStatusOverviewContent } from "api/batch-information/status-overview-api";

const StatusOverviewContainer: React.FC = () => {
  const { appState } = useContext(AppContext);

  const location: any = useParams();
  const {
    data: statusOverviewData,
    isLoading,
    isError,
    isRefetching
  } = useQuery({
    queryKey: "getStatusOverview",
    queryFn: getStatusOverviewContent.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !=="",

  });
  return (
    <StatusOverview
      statusOverviewData={statusOverviewData?.data?.data}
      isLoading={isLoading}
      isError={isError}
      isRefetching={isRefetching}
    />
  );
};
export default StatusOverviewContainer;
