/* eslint-disable react/react-in-jsx-scope */
import './index.scss'
import '../../../assets/bootstrap.scss'
export interface SegmentHeaderType {
  title: string
  id: string
}
const SegmentHeader = (props: SegmentHeaderType) => {
  return (
    <div
      id={props.id}
      className="aoh-d-flex aoh-segment-header"
    >
      {props.title}
    </div>
  )
}
SegmentHeader.displayName = 'SegmentHeader'
export default SegmentHeader
