
import React from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import PolygonIcon from "assets/icons/polygon-icon";

interface SortingProps {
  selectedSort : string
  columnName : string
  selectedSortDirection: string
}

const Sorting: React.FC<SortingProps> = (props: SortingProps) => {
  const { selectedSort,columnName, selectedSortDirection } = props;
  
  return (
    (selectedSort=== columnName ? <>
      {selectedSortDirection === "asc" && <span className="aoh-pl-1 aoh-rotate-icon "><PolygonIcon/></span>}
      {selectedSortDirection === "desc" && <span className="aoh-pl-1"><PolygonIcon/></span>}
    </> : <></>)
  );
};

export default Sorting;