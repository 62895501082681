import "./index.scss";
import "../../../assets/bootstrap.scss";
import Sorting from "components/resuable/sorting-icon";

interface sortCriteriaType { 
  value: string, 
  name: string, 
  type: string, 
  direction: string 
}

interface LineCardHeaderProps {
  selectedSort : sortCriteriaType
  sortLine: (value: sortCriteriaType)=>void
  sortCriteriaList: sortCriteriaType[]
}
const LineCardHeader = ({selectedSort, sortCriteriaList, sortLine} : LineCardHeaderProps) => {

  const handleSorting = (sortType: string): void => {
    const sortObject = sortCriteriaList.filter((item: sortCriteriaType) => {
      if (item.value === sortType) {
        return true;
      }
      return false;
    })
    if (sortObject[0].value === selectedSort.value) {
      sortObject[0].direction = selectedSort.direction === "asc" ? "desc" : "asc";
    }
    sortLine(sortObject[0])
  };

  return (
    <div className="aoh-row aoh-metric-header aoh-d-flex justify-content-between aoh-mt-4 aoh-w-100">
      <div className="aoh-pointer aoh-d-flex aoh-col-1 aoh-pl-4 align-items-center aoh-summary aoh-status ops-fs-6 aoh-text-uppercase ops-fw-semibold">
        {"line"}
      </div>
      <div onClick={() => { handleSorting("line_status") }} className="aoh-pointer aoh-d-flex aoh-col-1 aoh-pl-0 align-items-center aoh-summary aoh-status ops-fs-6 aoh-text-uppercase ops-fw-semibold ">
        {"line status"}<Sorting selectedSort={selectedSort.value} selectedSortDirection={selectedSort.direction} columnName={"line_status"}></Sorting>
      </div>

      <div  className="aoh-pointer aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold">
        {"batch no."}
      </div>
      <div className="aoh-pointer aoh-d-flex aoh-col-2 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold">
        {"batch name"}
      </div>
      <div  className="aoh-pointer aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold">
        {"phase"}
      </div>
      <div  className="aoh-pointer aoh-d-flex aoh-col-2 align-items-center aoh-status ops-fs-6 aoh-text-uppercase aoh-status ops-fw-semibold">
        {"batch progress"}
      </div>
      <div className="aoh-pointer aoh-d-flex aoh-col-2 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold">
        {"machine status"}
      </div>
      <div  className="aoh-pointer aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold aoh-margin-rte">
        {"RTE Status"}
      </div>
      <div  className="aoh-pointer aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-6 aoh-status aoh-text-uppercase ops-fw-semibold">
        {"oee3"}
      </div>
      <div className="aoh-d-flex aoh-col-1 align-items-center aoh-status aoh-pt-2 ops-fs-6"></div>
    </div>
  );
};

export default LineCardHeader;
