import { fetchApi, payloadType } from "../fetch-api"

export const getKPIContent = async (data: payloadType) => {
  const response = await fetchApi({
    header: {},
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/shopfloor/kpi`,
    data,
  });
    return response;
}
