// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/@gitlab-rtsensing/component-library/lib/global.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{margin:0px;background-color:#f4f4f4}.site-body{background:#eee;min-height:100vh}.site-wrapper{margin:0px;background-color:#f4f4f4}`, "",{"version":3,"sources":["webpack://./src/aoh-site.scss","webpack://./src/assets/colors.scss"],"names":[],"mappings":"AAGA,KACI,UAAA,CACA,wBCuBO,CDrBX,WACI,eC4BO,CD3BP,gBAAA,CAEJ,cACI,UAAA,CACA,wBCeO","sourcesContent":["@import \"./assets/colors.scss\";\n@import \"./assets/fonts.scss\";\n@import \"../node_modules/@gitlab-rtsensing/component-library/lib/global.min.css\";\nbody{\n    margin:0px;\n    background-color: $gray-150;\n}\n.site-body{\n    background: $gray-400;\n    min-height:100vh;\n}\n.site-wrapper {\n    margin:0px;\n    background-color: $gray-150;\n}","// @import \"../../assets/colors.scss\";\n\n$green: #08ad56;\n$green-200: #008533;\n$green-500:#3b6b24;\n$red: #db0000;\n$red-200: #D62728;\n$yellow: #d07804;\n\n$white: #FFFFFF;\n$offset_white: #f8f9fa;\n$white-300: #f8f9fa;\n$white-100: #FBFBFB;\n//blue shades\n\n$amgen_blue: #0063c3;\n\n$light_blue: #f4faff;\n$light_grey: #AEAEAE;\n\n\n\n//gray shades\n\n$gray: #2F2F2F;\n\n$gray-100: #DDDBDA;\n\n$gray-150: #F4F4F4;\n\n$gray-200: #f9f9f9;\n$gray-225:#d9eed9;\n$gray-250: #e0e0e0;\n$gray-275:#FBE9EA;\n$gray-300: #f5f5f5;\n\n$gray-400: #eeeeee;\n\n$gray-500: #8c8c8c;\n\n$gray-600: #9C9C9C;\n\n$gray-650: #a3a3a3;\n$gray-670: #E3E3E3;\n$gray-700: #757575;\n$gray-800: #585858;\n$gray-900: #5B5B5B;\n\n\n//black shades\n\n$black: #000000;\n\n$secondary_black: #1a0505;\n\n\n\n//silver\n\n$silver-100: #AAAAAA;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
