import PageLayout from "components/resuable/layout";
import "./index.scss";
import { Button, MetricCard } from "@gitlab-rtsensing/component-library";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { BreadcrumbMenuItemType, MCT, PermissionType } from "types/permission-type";
import { MatomoContext } from "utils/matomo-context-provider";
import { availableApps, permissionObject, personas } from "utils/constants"
import { Breadcrumb, ConfigProvider } from "antd";
import { NavLink } from "components/side-nav/types";
import HeroBanner from "assets/icons/hero-banner";
import GroupIcon from "assets/icons/group-icon";
import ProjectBookBuildingIcon from "assets/icons/project-book-icon";
import RealEstateActionIcon from "assets/icons/real-estate-action-icon";
import persona from "../assets/sample-data/personas/perosna-content.json"

interface PageType {
    permissions: PermissionType[];
    setNavlist: Dispatch<SetStateAction<NavLink>>
}

const personaContent: Record<string, string> = persona.content

const PersonasContainer = (props: PageType) => {
    const { permissions } = props;
    const navigate = useNavigate();
    const { site } = useParams()
    const context = useContext(MatomoContext);

    interface PersonasAuthorization {
        shopFloor: boolean;
        wct: boolean;
        eTOC: boolean;
    }

    const currentRoute = availableApps.filter(item => item.route === site)
    const sectionLabel = currentRoute[0].label
    const sectionSubLabel = currentRoute[0].subLabel
    const sideNavLabel = currentRoute[0].sideNavLabel
    const personaObject: PersonasAuthorization | undefined = permissions[1]?.workstreams?.mct
        ?.filter((permission: MCT) => permission.url === site)[0]?.personas

    const arr: { label: string; url?: string; authorized: boolean; disabled: boolean; }[] = []
    arr.push({
        label: `${site?.toUpperCase()} Home`,
        url: `/manufacturing/${site}`,
        authorized: true,
        disabled: false
    })

    const NavList = {
        label: site?.toUpperCase() ?? "",
        authorized: permissionObject.manufacturing,
        jumpToSections: [],
        externalSections: [],
        internalSections: [
            {
                sectionLabel: sideNavLabel,
                links: arr
            },
        ],
    }

    personaObject && Object.entries(personaObject).map((item) => {
        const data = personas.filter(items => items.name === item[0])[0];
        if (data.name === 'shopFloor') {
            arr.push({
                label: data.label,
                url: `/manufacturing/${site}/${data.url}`,
                authorized: item[1],
                disabled: item[1]
            })
        } else if (data.name === 'eTOC') {
            arr.push({
                label: `${site?.toUpperCase()} ${data.label}`,
                authorized: item[1],
                disabled: item[1]
            })
        } else {
            arr.push({
                label: data.label,
                authorized: item[1],
                disabled: item[1]
            })
        }
        return true
    })

    useEffect(() => {
        props.setNavlist(NavList)
    }, [site])

    const isAuthorized: boolean | undefined = permissions[1]?.workstreams?.mct
        ?.filter((permission: MCT) => permission.url === site)[0]?.isAuthorized

    const mfgItems = [
        {
            key: '1',
            label: <a href="/manufacturing">Manufacturing</a>
        }
        // ,
        // {
        //     key: '2',
        //     label: <a href="/cct">CCT</a>
        // }
    ]

    const avaialableSites: Record<string, boolean> = Object.fromEntries(
        Object.entries(permissionObject).filter(([, value]: [string, boolean]) => value)
    )

    const siteItems: BreadcrumbMenuItemType[] = Object.entries(avaialableSites).map(([key,], index) => {
        return {
            key: `${index + 1}`,
            label: (
                <a rel="noopener noreferrer" key={`${key}-menu-${index + 1}`} href={`/manufacturing/${key}`}>
                    {key.toUpperCase()}
                </a>
            )
        }
    })

    const icons: Record<string, JSX.Element> = {
        shopFloor: <RealEstateActionIcon />,
        wct: <GroupIcon />,
        eTOC: <ProjectBookBuildingIcon />
    }

    return (
        <PageLayout>
            <div className="d-flex aoh-w-100 aoh-flex-column">
                <div className="aoh-pb-3">
                    <div className="image-container">
                        <HeroBanner />
                    </div>
                    <ConfigProvider theme={{ token: { fontSize: 12 } }}>
                        <Breadcrumb
                            className="breadcrumb pc-breadcrumb"
                            items={[
                                {
                                    title: <a href="/">Digital Control Tower</a>,
                                },
                                {
                                    title: <span>Manufacturing</span>,
                                    menu: { items: mfgItems }
                                },
                                {
                                    title: <span>{site?.toUpperCase()}</span>,
                                    menu: { items: siteItems },
                                }
                            ]}
                        />
                    </ConfigProvider>
                    <div className="sticky-header">
                        <div className="ops-fw-semibold landing-header aoh-margin-header" id="dct-header">
                            {sectionLabel}
                        </div>
                        <div className="ops-fs-1 ops-fw-medium landing-sub-header aoh-margin-header" id="dct-sub-header">
                            {sectionSubLabel}
                        </div>
                    </div>
                    <div id="landing-content" className="landing-content">
                        {personaObject && isAuthorized && Object.entries(personaObject).map((item, index) => {
                            const data = personas.filter(items => items.name === item[0])[0]
                            let label = data.label
                            let subLabel = data.subLabel

                            if (item[1] === false) {
                                return null
                            }

                            if (data.name === 'eTOC') {
                                label = `${site?.toUpperCase()} ${data.label}`
                                subLabel = `${site?.toUpperCase()} ${data.subLabel}`
                            }
                            return (
                                <MetricCard key={index}>
                                    {data &&
                                        <div className="aoh-d-flex aoh-flex-column aoh-h-100">
                                            <MetricCard.Header className="aoh-d-flex">
                                                {icons[data.name]}
                                                <MetricCard.Title className="icon-title" title={label}></MetricCard.Title>
                                            </MetricCard.Header>
                                            <MetricCard.Content className="aoh-flex-grow-1">
                                                <div className="aoh-h-100 aoh-d-flex aoh-flex-column aoh-justify-content-between">
                                                    <p className="persona-content">{personaContent[data.name]}</p>
                                                    <div className="aoh-landing-button-wrapper">
                                                        <Button
                                                            className="aoh-view-more"
                                                            iconPosition="right"
                                                            onClick={() => {
                                                                context.trackEvent(
                                                                    "btn_click_event",
                                                                    "Home Page",
                                                                    label
                                                                );
                                                                context.trackPageView(
                                                                    window.location.origin + `/manufacturing/${site}/${data.url}`,
                                                                    document.title
                                                                );
                                                                navigate(`/manufacturing/${site}/${data.url}`, {
                                                                    replace: true,
                                                                });
                                                            }}
                                                            label={`Go to ${subLabel}`}
                                                            type="primary"
                                                            disabled={data.name === 'shopFloor' ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            </MetricCard.Content>
                                        </div>
                                    }
                                </MetricCard>
                            )
                        })}
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};
export default PersonasContainer;
