import React from 'react';

const GroupIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75057 17.5C2.19983 17.5 0.938232 16.2383 0.938232 14.6875C0.938232 13.1367 2.19983 11.875 3.75057 11.875C5.30131 11.875 6.56291 13.1367 6.56291 14.6875C6.56291 16.2383 5.30131 17.5 3.75057 17.5ZM3.75057 13.125C2.88895 13.125 2.18816 13.8258 2.18816 14.6875C2.18816 15.5492 2.88895 16.25 3.75057 16.25C4.61219 16.25 5.31298 15.5492 5.31298 14.6875C5.31298 13.8258 4.61219 13.125 3.75057 13.125Z" fill="#595959" />
            <path d="M0.62483 19.9983C0.52317 19.9983 0.421509 19.9725 0.330681 19.925C0.0265319 19.7633 -0.0884614 19.3842 0.0731959 19.08C0.464006 18.3475 1.05731 17.7542 1.78893 17.3642C2.38973 17.0442 3.06552 16.875 3.74298 16.875C5.28873 16.875 6.70031 17.72 7.4261 19.08C7.58859 19.3842 7.47277 19.7633 7.16862 19.9258C7.07862 19.9725 6.97696 19.9983 6.8753 19.9983C6.64365 19.9983 6.43283 19.8717 6.32367 19.6675C5.8162 18.715 4.82792 18.1233 3.74465 18.1233C3.27051 18.1233 2.79804 18.2417 2.37723 18.4658C1.86476 18.7392 1.44978 19.1542 1.17647 19.6667C1.06814 19.8717 0.856484 19.9983 0.62483 19.9983Z" fill="#595959" />
            <path d="M19.3745 20.0008C19.1428 20.0008 18.9312 19.8742 18.8228 19.67C18.4562 18.9833 17.8437 18.4792 17.0988 18.2525C16.8204 18.1675 16.533 18.125 16.2463 18.125C15.7738 18.125 15.3005 18.2442 14.8772 18.47C14.3648 18.7433 13.9498 19.1583 13.6765 19.6708C13.5673 19.8742 13.3557 20.0008 13.1248 20.0008C13.0232 20.0008 12.9215 19.975 12.8307 19.9275C12.5265 19.765 12.4115 19.3858 12.5732 19.0817C12.964 18.3492 13.5565 17.7558 14.2889 17.3658C14.4673 17.27 14.6531 17.1883 14.8422 17.12C14.0031 16.6317 13.4373 15.7225 13.4373 14.6875C13.4373 13.1367 14.6989 11.875 16.2496 11.875C17.8004 11.875 19.062 13.1367 19.062 14.6875C19.062 15.7225 18.4962 16.6317 17.6571 17.12C18.6337 17.4692 19.4345 18.1592 19.9261 19.0808C20.0044 19.2283 20.0211 19.3975 19.9728 19.5575C19.9244 19.7167 19.8161 19.8483 19.6686 19.9267C19.5786 19.975 19.477 20.0008 19.3745 20.0008ZM16.2496 13.1258C15.388 13.1258 14.6872 13.8267 14.6872 14.6883C14.6872 15.55 15.388 16.2508 16.2496 16.2508C17.1113 16.2508 17.8121 15.55 17.8121 14.6883C17.8121 13.8267 17.1113 13.1258 16.2496 13.1258Z" fill="#595959" />
            <path d="M10.0001 5.625C8.44934 5.625 7.18774 4.36333 7.18774 2.8125C7.18774 1.26167 8.44934 0 10.0001 0C11.5508 0 12.8124 1.26167 12.8124 2.8125C12.8124 4.36333 11.5508 5.625 10.0001 5.625ZM10.0001 1.25C9.13846 1.25 8.43767 1.95083 8.43767 2.8125C8.43767 3.67417 9.13846 4.375 10.0001 4.375C10.8617 4.375 11.5625 3.67417 11.5625 2.8125C11.5625 1.95083 10.8617 1.25 10.0001 1.25Z" fill="#595959" />
            <path d="M12.7075 7.50083C12.5241 7.50083 12.3508 7.42083 12.2317 7.28083C11.6742 6.62667 10.8601 6.25083 9.99678 6.25083C9.30099 6.25083 8.62686 6.49917 8.09772 6.94917C7.9794 7.05083 7.86774 7.1625 7.76691 7.28083C7.64775 7.42083 7.47443 7.50083 7.2911 7.50083C7.14278 7.50083 6.99862 7.4475 6.88529 7.35167C6.75863 7.24333 6.68114 7.0925 6.66781 6.92583C6.65447 6.75917 6.70697 6.5975 6.8153 6.47083C6.95946 6.30083 7.11861 6.1425 7.28777 5.99833C8.04356 5.35417 9.00517 5 9.99345 5C10.1059 5 10.2193 5.00417 10.3326 5.01333C11.4467 5.1025 12.4591 5.62 13.1824 6.47083C13.2908 6.59833 13.3433 6.75917 13.3299 6.92583C13.3166 7.0925 13.2391 7.24333 13.1124 7.35167C12.9999 7.4475 12.8558 7.50083 12.7075 7.50083Z" fill="#595959" />
            <path d="M9.99925 17.4996C9.0818 17.4996 8.18019 17.3338 7.32024 17.0054C7.16441 16.9463 7.04109 16.8296 6.97276 16.6771C6.90443 16.5246 6.89943 16.3546 6.95943 16.1988C7.05109 15.9579 7.28524 15.7963 7.54273 15.7963C7.61939 15.7963 7.69438 15.8104 7.76605 15.8379C8.48267 16.1113 9.23429 16.2496 9.99925 16.2496C10.7775 16.2496 11.5417 16.1063 12.2691 15.8238C12.3416 15.7954 12.4174 15.7812 12.4949 15.7812C12.7508 15.7812 12.9849 15.9413 13.0774 16.1804C13.2024 16.5021 13.0424 16.8646 12.7208 16.9896C11.8491 17.3279 10.9325 17.4996 9.99925 17.4996Z" fill="#595959" />
            <path d="M3.15555 11.25C2.83057 11.25 2.56309 11.0058 2.53392 10.6817C2.51059 10.4275 2.49976 10.2108 2.49976 10C2.49892 7.94083 3.36054 5.95167 4.86379 4.54417C4.98045 4.435 5.1321 4.375 5.29126 4.375C5.46375 4.375 5.62957 4.4475 5.74707 4.5725C5.86123 4.69417 5.92122 4.85333 5.91539 5.02C5.90956 5.18667 5.83956 5.34167 5.7179 5.45583C4.46631 6.62833 3.74885 8.285 3.74968 10C3.74968 10.1717 3.75885 10.3525 3.77885 10.5683C3.81051 10.9108 3.55719 11.2158 3.21388 11.2475C3.19222 11.2492 3.17388 11.25 3.15555 11.25Z" fill="#595959" />
            <path d="M16.843 11.2492C16.8247 11.2492 16.8072 11.2483 16.7888 11.2467C16.6214 11.2317 16.4705 11.1533 16.3639 11.025C16.2564 10.8967 16.2055 10.735 16.2205 10.5683C16.2405 10.3425 16.2497 10.1617 16.2497 9.99917C16.2505 8.28333 15.5331 6.6275 14.2807 5.45583C14.029 5.22 14.0165 4.82333 14.2515 4.5725C14.369 4.44667 14.5348 4.375 14.7073 4.375C14.8665 4.375 15.0181 4.435 15.1339 4.54333C16.6389 5.95083 17.5005 7.93917 17.4996 9.99917C17.4996 10.2008 17.4888 10.4167 17.4655 10.6792C17.4363 11.0042 17.1688 11.2492 16.843 11.2492Z" fill="#595959" />
        </svg>
    )
}

export default GroupIcon