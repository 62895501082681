import { useQuery } from "react-query";
import { useContext } from "react";
import BatchCompletion from ".";
import { AppContext } from "context/app-context";
import { useParams } from "react-router-dom";
import { payloadType } from "api/fetch-api";

interface BatchCompletionContainerProps {
  getBatchCompletionContent: (data: payloadType) => Promise<any>;
}

const BatchCompletionContainer: React.FC<BatchCompletionContainerProps> = (
  props: BatchCompletionContainerProps
) => {
  const { getBatchCompletionContent } = props;

  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: batchCompletionData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getBatchCompletionContent", location.line],
    queryFn: getBatchCompletionContent.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !==""
  });

  return (
    <BatchCompletion
      batchCompletionData={batchCompletionData?.data?.data}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
export default BatchCompletionContainer;
