import { useQuery } from "react-query";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import MachineStatus from ".";
import { AppContext } from "context/app-context";
import { useParams } from "react-router-dom";
import { getMachineStatusContent } from "api/batch-information/machine-status-api";

const MachineStatusContainer = () => {
  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: machineStatusData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getMachineStatusContent", location.line],
    queryFn: getMachineStatusContent.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: !!appState.curr_stage && (appState.mfg_stage !== "" && appState.building !==""),
  });

  return (
    <>
      <MachineStatus 
      machineStatusData={machineStatusData?.data?.data} 
      isLoading={isLoading} isError={isError} />
    </>
  );
};
export default MachineStatusContainer;
