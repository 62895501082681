import React from "react";
import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import Title from "components/shop-floor-tab/batch-info-header/title";

interface CompletionSectionProps {
  actual: string;
  comparisionColorCode: string;
  predictedCompletion: string;
  target: string;
  targetCompletion: string;
  unitsPlanned: string;
  unitsProduced: string;
}

const CompletionSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    actual,
    comparisionColorCode,
    predictedCompletion,
    target,
    targetCompletion,
    unitsPlanned,
    unitsProduced,
  } = props;

  return (
    <>
      <div className="aoh-d-flex aoh-sm-row aoh-md-row aoh-avg-target-content-header aoh-justify-content-between">
        <div className="aoh-pb-0 aoh-avg-target-title">
          <Title
            title="Batch Avg vs Target"
            height={"ops-fs-5"}
            weight={"ops-fw-semibold"}
          />
        </div>
      </div>
      <div className="aoh-completion aoh-row aoh-mt-2 aoh-mb-1">
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"OEE"}
          </div>
          <div className="aoh-actual-target-content ops-fs-5 ops-fw-semibold">
            {"85%" ?? "N/A"}
            <span
              className={
                (comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : "") +
                " " +
                "aoh-pl-1"
              }
            >
              {"(-2%)" ?? "N/A"}
            </span>
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6 aoh-pl-4">
          <div className="aoh-target-predict-heading ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"AVAILABILITY"}
          </div>
          <div className="aoh-w-100 aoh-actual-target-content ops-fw-semibold ops-fs-5">
            {"76%" ?? "N/A"}
            <span
              className={
                (comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : "") +
                " " +
                "aoh-pl-1"
              }
            >
              {"(-2%)" ?? "N/A"}
            </span>
          </div>
        </div>
      </div>
      <div className="aoh-completion aoh-row aoh-mt-1 aoh-mb-0">
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"PERFORMANCE"}
          </div>
          <div className="aoh-actual-target-content ops-fs-5 ops-fw-semibold">
            {"67%" ?? "N/A"}
            <span
              className={
                (comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : "") +
                " " +
                "aoh-pl-1"
              }
            >
              {"(-2%)" ?? "N/A"}
            </span>
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6 aoh-pl-4">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"QUALITY"}
          </div>
          <div className="aoh-w-100 aoh-actual-target-content aoh-text-uppercase ops-fw-semibold ops-fs-5">
            {"90%" ?? "N/A"}
            <span
              className={
                (comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : "") +
                " " +
                "aoh-pl-1"
              }
            >
              {"(-2%)" ?? "N/A"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompletionSection;
