/* eslint-disable react/no-unknown-property */
import { useEffect, useRef, useState } from "react";
import "./index.scss";
import HourMachineStatusTooltip from "../hour-machine-bar-tooltip";
import { Tooltip } from "react-tooltip";
interface ProductivityType {
  graphArray: any;
  tooltipToggle: (value: any) => void;
  idMachineList: React.Key | null | undefined;
}
const HourMachineStatusBar: React.FC<ProductivityType> = ({
  graphArray,
  idMachineList,
  tooltipToggle,
}) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);
  const handleMouseEnter = (statusData: string) => {
    tooltipToggle(statusData);
  };

  return (
    <>
      <div ref={containerRef} className="aoh-hour-container-bar">
        {graphArray?.map((data: any, i: number) => (
          <>
            <div
              id={`aoh-data-anchor${i}${idMachineList}`}
              onMouseEnter={() => handleMouseEnter(data)}
              key={`${i}${idMachineList}`}
              className={`${
                graphArray?.length === i + 1
                  ? "second-child-div-border"
                  : i === 0
                  ? "first-child-div-border"
                  : "child-div"
              }`}
              style={{
                width: `${data.percentage}%`,
                backgroundColor: `${
                  data.status === "RUNNING" || data.status === "Running"
                    ? "#008533"
                    : data.status === "DOWN" || data.status === "Down"
                    ? "#D62728"
                    : data.status === "MAINTENANCE" ||
                      data.status === "Maintenance"
                    ? "#2FBCB6"
                    : "#597B7C"
                }`,
              }}
            />

            <Tooltip
              noArrow={true}
             
              float
              className={""}
              render={() => {
                return (
                  <div
                    key={parseInt((Math.random() * 500).toString()).toString()}
                  >
                    <HourMachineStatusTooltip
                      currentStatusTooltipData={data}
                      key={parseInt(
                        (Math.random() * 500).toString()
                      ).toString()}
                    />
                  </div>
                );
              }}
              opacity={1}
              style={{ backgroundColor: "white", padding: "0px", zIndex: 1000 }}
              anchorSelect={`#aoh-data-anchor${i}${idMachineList}`}
            ></Tooltip>
          </>
        ))}
      </div>
    </>
  );
};
export default HourMachineStatusBar;
