import { 
  MetricCard,
  Modal,
  
} from "@gitlab-rtsensing/component-library";
import Tabs from "components/resuable/dct-tab";
import './index.scss'
import DayTimelineContainer from "./day-timeline/container.aoh";
import HourTimelineContainer from "./hour-timeline/container.aoh";
interface MachineStatusModalInterface {
  opened: boolean;
  close: () => void;
  title: string;
}
const MachineStatusModal: React.FC<MachineStatusModalInterface> = ({ opened, close, title }) => {

  return (
    <Modal
      className={"aoh-machine-status-modal"}
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex aoh-machine-modal-header align-items-center">
        <Modal.Title title={title} className="ops-fw-semibold" />
      </Modal.Header>
      <MetricCard.Content>
        <div className="d-flex aoh-flex-column aoh-machine-modal-content w-100">
          <Tabs
            tabs={[
              {
                header: {
                  label: "24 Hour Timeline",
                },
                content: <HourTimelineContainer/>,
              },
              {
                header: {
                  label: "Last 7 Days",
                },
                content: <DayTimelineContainer/>,
              },
            ]}
          />
        </div>
      </MetricCard.Content>
    </Modal>
  );
};
export default MachineStatusModal;
