import React from "react";
import "./index.scss";
import { Tooltip } from "react-tooltip";
import HourMachineStatusTooltip from "../hour-machine-bar-tooltip";
interface FloatGraphDataProps {
  idMachineList: React.Key | null | undefined;
  floatBarGraphData: any;
}

const HourMachineStatusFloatBar: React.FC<FloatGraphDataProps> = (
  props: FloatGraphDataProps
) => {
  const { floatBarGraphData, idMachineList } = props;
  const floatBarHeight = 7;

  return (
    <div
      className="ops-pt-0 aoh-ml-1 aoh-machine-graph-div aoh-pl-0 aoh-mb-0 aoh-pb-0"
    >
      <div
        style={{
          width: "100%",
          marginTop: "2px",
          height: `${floatBarHeight}px`,
          position: "relative",
        }}
      >
       {floatBarGraphData?.map((floatBarGraphData: any, index: number) => { 
          return (
            <>
              {" "} 
              <div
                id={`aoh-float-data-anchor${idMachineList}${index}`}
                key={index}
                style={{
                  position: "absolute",
                  left: `${floatBarGraphData.start_percentage}%`,
                  width: `${floatBarGraphData.end_percentage - floatBarGraphData.start_percentage}%`,
                  height: "100%",
                  background: "#2FBCB6",
                }}
              ></div> 
              <Tooltip
                noArrow={true}
                float
                className={"aoh-hour-machine-float-tooltip"}
                render={() => {
                  return (
                    <div key={index}>
                      <HourMachineStatusTooltip
                        currentStatusTooltipData={floatBarGraphData}
                        key={index}
                      />
                    </div>
                  );
                }}
                style={{
                  backgroundColor: "white",
                  padding: "0px",
                  zIndex: 1000,
                }}
                opacity={1}
                anchorSelect={`#aoh-float-data-anchor${idMachineList}${index}`}
              ></Tooltip>
            </>
          );
        })} 
      </div>
    </div>
  );
};
export default HourMachineStatusFloatBar;
