import {  useQuery } from "react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import HeaderAlert from ".";
import { getNotification } from "api/batch-information/notification-api";
import './index.scss';

const HeaderAlertContainer = () => {
  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data,
    isLoading,
    isError,
  } = useQuery({
    queryKey: "getNotification",
    queryFn: getNotification.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !=="",

  });
  if (!data?.data?.data || !data?.data?.data?.is_line_down) {
    return <div className="aoh-header-alert-wrapper--dummy fade-out"></div>
  }
  return (
    <>
      <HeaderAlert data={data?.data?.data}
        isError={isError}
        isLoading={isLoading}
        />
        
    </>
  );
};
export default HeaderAlertContainer;
