import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useContext } from "react";
import { AppContext } from "context/app-context";
import { getBatchScheduleContent } from "api/batch-information/batch-schedule-api";
import BatchSchedule from ".";
import { batchScheduleDataType } from "utils/data-types";

const BatchScheduleContainer: React.FC = () => {
  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: batchScheduleData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getBatchScheduleContent", location.line],
    queryFn: getBatchScheduleContent.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: !!appState.curr_stage && (appState.mfg_stage !== "" && appState.building !==""),
    
  });
  return (
    <BatchSchedule
      batchScheduleData={batchScheduleData?.data?.data.sort(function (
        a: batchScheduleDataType,
        b: batchScheduleDataType
      ) {
        return a?.order_id - b?.order_id;
      })}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
export default BatchScheduleContainer;
