import { useQuery } from "react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import { getNonCommHeaderContent } from "api/batch-information/non-comm-header-api";
import NonCommHeader from ".";

const NonComHeaderContainer = () => {
  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: nonCommHeaderData,
    isLoading: isRefreshLoading,
    isError: isRefreshError,
  } = useQuery({
    queryKey: "getNonCommHeaderContent",
    queryFn: getNonCommHeaderContent.bind(this, {
      site: location.site,
      line_no: location.line,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !=="",

  });

  return (
    <NonCommHeader
      refreshData = {nonCommHeaderData?.data?.data}
      isRefreshLoading = {isRefreshLoading}
      isRefreshError = {isRefreshError}
    />
  );
};
export default NonComHeaderContainer;
