import { useQuery } from "react-query";
import { useContext } from "react";
import Kpi from ".";
import { AppContext } from "context/app-context";
import { useParams } from "react-router";
import { payloadType } from "api/fetch-api";
interface KpiContainerProps {
  getKPIContent: (data: payloadType) => Promise<any>;
}

const KpiContainer: React.FC<KpiContainerProps> = (
  props: KpiContainerProps
) => {
  const { getKPIContent } = props;
  const { appState } = useContext(AppContext);

  const location: any = useParams();
  const {
    data: kpiData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getKPIContent", location.line],
    queryFn: getKPIContent.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: !!appState.curr_stage && (appState.mfg_stage !== "" && appState.building !==""),
  });

  return (
      <Kpi
        kpiData={kpiData?.data?.data}
        isLoading={isLoading}
        isError={isError}
      />
  );
};
export default KpiContainer;
