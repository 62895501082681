import React from "react";
import Tag from "../../../resuable/tag";
import "./index.scss";

import CalenderIcon from "../../../../assets/icons/calender-icon";
import "../../../../assets/bootstrap.scss";
import BatchUnitSection from "../batch-unit-section";

interface ArticleProps {
  materialDesc: string;
  totalCard: number;
  index: number;
  batchNo: string;
  changeOver: any;
  dateTime: string;
  unitsPlanned: string;
  // delayStatus:string;
  // absoluteDelay:string;
  // delayTime:string;
}

const ArticleCard: React.FC<ArticleProps> = (props: ArticleProps) => {
  const {
    materialDesc,
    batchNo,
    totalCard,
    index,
    changeOver,
    dateTime,
    unitsPlanned,
    // delayStatus,
    // absoluteDelay,
    // delayTime
  } = props;
  // const [hours, minutes] = delayTime.split(':');
  // const formattedDelayTime = `${hours}:${minutes}`;
  return (
    <div
      className={` ${
        totalCard !== index + 1
          ? "aoh-article-card"
          : "aoh-article-card-borderless"
      }`}
    >
      <div className="article-details d-flex aoh-mt-0">
        <div className="aoh-w-100">
          {/* {absoluteDelay !== null ? <div className="aoh-article-title ops-fs-4 ops-fw-bold aoh-amgen-color" style={{color: "#008533"}}>{delayStatus} {formattedDelayTime} hours</div> : null} */}
          <div
            // onClick={() => handleTopicClick(articleId)}
            className="ops-fs-5 ops-fw-bold aoh-amgen-color"
          >
            {materialDesc ?? "N/A"}
          </div>
          <div className="aoh-d-flex aoh-w-100">
            <div className="aoh-article-info aoh-col-5 align-items-center aoh-d-flex ops-fs-4">
              <div className="aoh-mt-1 aoh-pb-0">
                <CalenderIcon fill="#2F2F2F" classname="" />
              </div>
              <div className="aoh-article-publish-date aoh-ml-2 ops-fs-6 ops-fw-semibold">
                {dateTime}
              </div>
            </div>
            {changeOver === 1 ? <div className="aoh-article-tags aoh-col-6 aoh-d-flex">
              <Tag key={"1"} name={changeOver} canClick={false} />
            </div>
              : null}
          </div>
          <BatchUnitSection batchNumber={batchNo ?? "N/A"} unitsPlanned={unitsPlanned ?? "N/A"}/>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
