import './index.scss'

interface MetricBoxInterface {
    label: string;
    metricValue: string;
    metricValue2?: string;
    colorCode: string;
}
const MetricBox: React.FC<MetricBoxInterface> = ({label, metricValue, metricValue2, colorCode}) => {
    return (
        <div className='aoh-metric-card-box'>
            <div className='label aoh-mt-3'>{label}</div>
            <div className="d-flex aoh-mt-1 ">
                <div className='metric'>{metricValue}</div>
                <div className={`metric ${colorCode === 'RED'? 'metric--red': 'metric--green'}`}>{metricValue2}</div>
            </div>
        </div>
    )
}
export default MetricBox