import React, { useContext, useEffect, useState } from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import { Tabs } from "../resuable/dct-tab";
import ShopFloorTabContent from "../shop-floor-tab";
import StatusAndHeader from "./status-header";
import { useNavigate, useParams } from "react-router-dom";
import AllLinesComponent from "components/all-lines-component";
import "./index.scss";
import { useWindowSizeListener } from "hooks/use-windowsize-listener";
import ErrorPage from "page/error-page";
import { AppContext } from "context/app-context";
import { MatomoContext } from "utils/matomo-context-provider";

interface BatchInformationProps {
  data: any;
  isError: boolean;
  isLoading: boolean;
  sqdipRef: any;
  batchInfoRef: any;
}

const BatchInformation: React.FC<BatchInformationProps> = ({
  data,
  isError,
  isLoading,
  sqdipRef,
  batchInfoRef,
}: BatchInformationProps) => {
  const navigate = useNavigate();
  const context = useContext(MatomoContext)
  const [tabState, setTabState] = useState<any[]>([]);
  const { line, site } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const { setAppState, appState } = useContext(AppContext);
  const screenType = useWindowSizeListener();
  const isMobile = screenType === "mobile";

  useEffect(() => {
    if (data) {
      const result = data.map((a: { line_desc: string }) => a.line_desc);
      if (!result.includes(line) && (line!=="all-lines" && line!=="")) {
        navigate(`/manufacturing/${site}/shop-floor/invalid_line`);
      }
      const allLinesTabData = [
        {
          batch_no: "",
          line_desc: "all-lines",
          line_status: "No",
          line_status_color_code: "",
          machine_status: "",
          material_desc: "",
          oee: "",
          phase: "",
          progress: [],
          progress_label: "",
          is_non_commercial: false,
        },
        ...data,
      ];
      allLinesTabData.map((indLine, indIndex) => {
        if (indLine.line_desc === line) {
          setTabIndex(indIndex);
        }
        return indLine;
      });

      const tabData = allLinesTabData?.map((item: any, index: number) => {
        setAppState({
          ...appState,
          building: item.building,
          mfg_stage: item.manufacturing_stage,
        });

        return {
          content: (
            <>
              {(line === "" ||
                line === "all-lines" ||
                item.line_desc === "all-lines") &&
              data ? (
                <AllLinesComponent
                  linesDataList={data}
                  viewChangeFn={function (): void {}}
                />
              ) : (
                <>
                  <ShopFloorTabContent
                    batchInfoRef={batchInfoRef}
                    sqdipRef={sqdipRef}
                    id="aoh-sqdip"
                  />
                </>
              )}
            </>
          ),
          header: {
            onClick: (index: number) => {
              if (item.line_desc !== line) {
                const navigationPath = `/manufacturing/${site}/shop-floor/${item.line_desc}`
                context.trackPageView(window.location.origin+ navigationPath, navigationPath)
                setAppState({ ...appState, curr_stage: null, phase: null });
                navigate(navigationPath);
              }
            },
            label: (
              <>
                {item.line_desc === "all-lines" ? (
                  <>
                    <StatusAndHeader
                      title="All Lines"
                      status={item.line_status_color_code}
                      link={item.line_desc}
                    />
                  </>
                ) : (
                  <>
                    <StatusAndHeader
                      title={item.line_desc}
                      status={item.line_status_color_code}
                      link={item.line_desc}
                    />
                  </>
                )}
              </>
            ),
          },
        };
      });
      const getIndex = allLinesTabData.findIndex((lineData: any) => {
        return lineData.line_desc === line;
      });
      setTabIndex(getIndex);
      setTabState(tabData);
    }
  }, [data, isError, isLoading, line]);

  if (tabState?.length < 1) {
    return (
      <div className="shop-floor-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {isError || data?.length === 0 ? (
        <>
          <ErrorPage errorMessage="No active lines available" />
        </>
      ) : (
        <>
          {!isMobile ? (
            <div id="dct-tab">
              <Tabs
                defaultTab={tabIndex}
                key={1}
                tabs={tabState}
              />
            </div>
          ) : (line === "" || line === "all-lines") && data ? (
            <>
              <div className="aoh-mobile-container aoh-mt-6 aoh-ml-4 aoh-mr-4">
                <AllLinesComponent
                  linesDataList={data}
                  viewChangeFn={function (): void {
                    setAppState({ ...appState, curr_stage: null, phase: null });
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="aoh-ml-4 aoh-mr-4 ">
                <ShopFloorTabContent
                  batchInfoRef={batchInfoRef}
                  sqdipRef={sqdipRef}
                  id="aoh-sqdip"
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BatchInformation;
