import { Status } from "@gitlab-rtsensing/component-library";
import "./index.scss";
interface StepsTimelineType {
  status: string;
  index: number;
  nextLine: number;
  lineType: string;
}

export const StepsTimeline: React.FC<StepsTimelineType> = ({
  status,
  index,
  nextLine,
  lineType,
}) => {
  const dashedCircle = () => {
    return (
      <div style={{ position: "relative", width: "16px", height: "16px" }}>
        <svg
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <circle
            cx="50%"
            cy="50%"
            r="48%"
            fill="transparent"
            stroke="lightgray"
            strokeWidth="1px"
            strokeDasharray="3 3" // This controls the dash size and spacing
          />
        </svg>
      </div>
    );
  };
 
  return (
    <div className="align-items-center d-flex">
      {/* Grey Dot -- */}
      {(status === "Up Next" || status === "") && (
        <div className="relative">{dashedCircle()}</div>
      )}
      {/* Green Dot */}
      {status === "Complete" && (
        <div className="relative rotation-2">
          <Status className="color-complete aoh-disable-status-hover" alert="Green"></Status>
        </div>
      )}
      {/* Running Dot */}
      {(status === "In Progress" || status === null) && (
        <div className="relative">
          <div className="running-circle"></div>
        </div>
      )}
      {/* Next Line */}
      {nextLine !== index + 1 && lineType === "SOLID" && (
        <div className="solid-border" />
      )}

      {nextLine !== index + 1 && lineType === "DASH" && (
        <div className="dashed-border" />
      )}
    </div>
  );
};
