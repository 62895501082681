import React from "react";
import APIErrorMessage from "./api-error";
import { Loader } from "@gitlab-rtsensing/component-library";
interface HandlerCompoType {
  isLoading: boolean;
  isError: boolean;
  hideLoader?: boolean;
  loaderColor: string;
}
const ErrorHandler: React.FC<HandlerCompoType> = ({
  isLoading,
  isError,
  loaderColor,
  hideLoader
}) => {
  return (
    <>
      {isLoading && (
        <div className="w-full h-full flex justify-center items-center aoh-m-auto">
          {!hideLoader && <Loader />}
        </div>
      )}
      {!isLoading && isError && (
        <APIErrorMessage apierror="Something went wrong" />
      )}
    </>
  );
};
export default React.memo(ErrorHandler);
