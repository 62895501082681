import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
  ResponsiveContainer
} from "recharts";
import RefreshTag from "components/resuable/refresh-tag";
import "./index.scss";
import Errorhandler from "utils/error-handler";
import CircleIcon from "assets/icons/circle-icon";
import APIErrorMessage from "utils/api-error";

interface DayTimelineProps {
  data: any;
  isLoading: boolean;
  isError: boolean;
}

const statusArray = [
  { name: "Running", color: "#008533" , labelName: "RunningLabel"},
  { name: "Downtime", color: "#D62728",labelName: "DowntimeLabel" },
  { name: "Idle", color: "#9C9C9C",labelName: "IdleLabel" },

];

const DayTimeline = ({data, isLoading,isError}: DayTimelineProps) => {
  
  const graphArray = data?.graph?.sort(function (
    a: any,
    b: any
  ) {
    return a?.order_id - b?.order_id;
  }).map((el: any) => {
    return {
      machine_name: el.machine_name,
      Running: el.running.time_decimal,
      Downtime: el.down.time_decimal,
      Idle: el.idle.time_decimal,
      RunningLabel: el.running.label,
      DowntimeLabel: el.down.label,
      IdleLabel: el.idle.label,
    };
  });
  
  return (
    <div className="aoh-mt-2">
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {!isLoading &&
      !isError &&
      data 
       ? (
      <>
      <div className="aoh-d-flex aoh-w-100 aoh-justify-content-between aoh-flex-wrap">
        <div className="ops-fw-semibold ops-fs-5 aoh-mt-2 aoh-d-flex aoh-modal-sub-header">
          Machine Status Breakdown - Last 7 Days
        </div>

        <div className="aoh-mt-1">
          <RefreshTag data={data.refresh_date}  backgroundColor="grey"/>
        </div>
      </div>
      <div className="aoh-d-flex align-items-center aoh-my-2 aoh-flex-wrap">
        <div className="d-flex aoh-timestamp aoh-tag-data aoh-p-1 ops-fs-6 align-items-center aoh-text-uppercase ops-fw-semibold">
          {data.start_date} - {data.end_date}
        </div>
        <div className="aoh-ml-4 d-flex">
          {statusArray.map((el: any) => {
            return (
              <>
                <div className="aoh-pr-2 aoh-pl-4 aoh-status-dot aoh-d-flex">
                  <CircleIcon
                    fill={el.color}
                    
                  />
                </div>
                <div className="aoh-status-graph-label aoh-d-flex aoh-bold-500  ops-fs-5 ">
                  {el.name}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <ResponsiveContainer width={'100%'} height={380}>
      <BarChart
        data={graphArray}
        layout="vertical"
        barSize={15}
        margin={{
          top: 5,
          right: 50,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid horizontal={false} stroke="#E9E9E9" />
        <XAxis
          axisLine={false}
          tickLine={false}

          style={{
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: 700,
            lineHeight: "14px",
            color: "#9c9c9c",
            fill: "#9c9c9c"
          }}
          type="number"
        />
        <YAxis
          type="category"
          dataKey="machine_name"
          tickLine={false}
          axisLine={false}
          width={70}
          style={{
            fill: "#9c9c9c",
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: 700,
            lineHeight: "14px",
            color: "#9c9c9c",
            paddingLeft: "10px"
            
          }}
        />
        {statusArray.map((el: any) => { 

          return (
            <>
              <Bar dataKey={el.name} fill={el.color} style={{ padding: "0px" }}>                
                <LabelList
                  dataKey={el.labelName}
                  position="right"
                  style={{
                    fontSize: "10px",
                    fill: "#585858",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    color: "#585858",
                  }}
                />
              </Bar>
            </>
          );
        })}
      </BarChart>
      </ResponsiveContainer>
      
      <div className="aoh-graph-label">HOURS</div>
      </>
      ) : !isLoading &&
      !isError &&
      !data  ? (
      <APIErrorMessage apierror={"No Data Available"} />
    ) : (
      <></>
    )}
    </div>
  );
};
export default DayTimeline;
