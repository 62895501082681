import { NavSection } from '../types';
import './index.scss';

interface SectionTitleProps {
  color?: string;
  section: NavSection;
}

const SectionTitle = (props: SectionTitleProps) => {
  const { color, section } = props;
  return (
    <p className="nav-sub-section-title" style={{ color }}>
      {section.sectionLabel.toUpperCase()}
    </p>
  );
};

export default SectionTitle;