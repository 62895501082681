// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aohdropdown_label__MeSCt{
    font-size: 12px;
    font-weight: 700;
    color: #595959;
    margin-right: 4px;
}

.aohdropdown_drop__Zu\\+r3{
    margin-left: 15px;
}

.aohdropdown_customDropdown__zicNF .ant-dropdown-menu-item:hover {
    background-color: #E6F7FF !important;  
    color: #1F1F1F !important;            
    font-weight: 700 !important; 
}`, "",{"version":3,"sources":["webpack://./src/components/common/dropdown/aohdropdown.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,2BAA2B;AAC/B","sourcesContent":[".label{\n    font-size: 12px;\n    font-weight: 700;\n    color: #595959;\n    margin-right: 4px;\n}\n\n.drop{\n    margin-left: 15px;\n}\n\n.customDropdown :global(.ant-dropdown-menu-item:hover) {\n    background-color: #E6F7FF !important;  \n    color: #1F1F1F !important;            \n    font-weight: 700 !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `aohdropdown_label__MeSCt`,
	"drop": `aohdropdown_drop__Zu+r3`,
	"customDropdown": `aohdropdown_customDropdown__zicNF`
};
export default ___CSS_LOADER_EXPORT___;
