import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "context/app-context";
import { getStatusList } from "../../../../../api/machine-status/status-list-api";
import HourTimeline from ".";
import { getMachineStatusModal24h } from "api/batch-information/machine-status-modal-24-h-api";
import { MatomoContext } from "utils/matomo-context-provider";

const HourTimelineContainer: React.FC = () => {
  const { appState } = useContext(AppContext);
  let userId = false;
  const [showStatusValue, setShowStatusValue]= useState('');
  const [timeFrameValue, setTimeFrameValue]= useState('');
  const location: any = useParams();
  const context = useContext(MatomoContext);

  const stateHandleFn = (state: string, timeDate: string) => {
    if (state) {
      queryObj.status = state;
    }
    if (timeDate) {
      queryObj.date = timeDate;
    }
    refetch();
  };

  const statusPayload = {
    site: location.site,
    line_no: location.line,
    mfg_stage: appState.mfg_stage,
      building: appState.building
      
  };

  const {
    data: statusStateData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getStatusListData", location.line],
    queryFn: getStatusList.bind(this, statusPayload),
    enabled: appState.mfg_stage !== "" && appState.building !=="",
    refetchOnWindowFocus: false,

  });
  useEffect(() => {
    userId =
    statusStateData?.data?.data?.show_status_drop_down?.length > 0 &&
    statusStateData?.data?.data?.time_frame_drop_down?.length > 0;

  if (userId) {
    setShowStatusValue(statusStateData?.data?.data?.show_status_drop_down[0]);
    setTimeFrameValue(statusStateData?.data?.data?.time_frame_drop_down[0]);
  }
  }, [statusStateData?.data?.data?.show_status_drop_down.length,statusStateData?.data?.data?.time_frame_drop_down.length])
  
  const queryObj = {
    date: timeFrameValue,
    type: "24_hour",
    status: showStatusValue,
    site: location.site,
    line_no: location.line,
    mfg_stage: appState.mfg_stage,
      building: appState.building
      
  };

  const {
    data: machineStatusHourData,
    isLoading: isModalLoading,
    isError: isModalError,
    refetch,
    isRefetching
  } = useQuery({
    queryKey: ["getMachineStatusModal24h", location.line],
    queryFn: getMachineStatusModal24h.bind(this, queryObj),
    enabled: showStatusValue.length > 0 && timeFrameValue.length > 0  && (appState.mfg_stage !== "" && appState.building !==""),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (machineStatusHourData) {
      context.trackEvent("tab_click_event", "Machine Status", "24 Hour Timeline");
    }
  }, [machineStatusHourData]);
  
  return (
    <HourTimeline
      statusStateData={statusStateData?.data?.data}
      machineStatusHourData={machineStatusHourData?.data?.data}
      isLoading={isModalLoading}
      isRefetching={isRefetching}
      isError={isModalError}
      dropdownClickFn={stateHandleFn}
    />
  );
};
export default HourTimelineContainer;
