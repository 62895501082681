import { useEffect, useRef, useState } from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import VectorDropdownIcon from "components/resuable/vector-dropdown-icon";

interface DctDropDownType {
  handlerChange: (e: any) => void;
  label?: string;
  selectedValue: string | null;
  labelTop?: boolean;
  initialLabel?: string | undefined;
  optionList: {
    name: string;
    value: string;
  }[];
}

const CustomDropdown = (props: DctDropDownType) => {
  const {
    handlerChange,
    label,
    optionList,
    selectedValue,
    labelTop,
    initialLabel,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const ref = useRef<any>(null);

  const handleClickOutside = (event: { target: any }) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div ref={ref} className="aoh-custom-dropdown-container">
      <div className="aoh-custom-dropdown aoh-align-items-center ops-mt-2">
        <span className="dropdown-label ops-mt-2 ops-fw-semibold ops-fs-6">
          {label}
        </span>
        <div className="aoh-d-flex aoh-flex-column">
          <div
            onClick={() => {
              setIsActive(!isActive);
            }}
            className="dropdown-btn ops-fs-5 aoh-ml-2 aoh-ml-sm-0 ops-mt-2"
          >
            {selectedValue ? selectedValue : optionList[0].name}
            <div>
              <VectorDropdownIcon selectedDirection={!isActive} />
            </div>
          </div>
          <div
            className="dropdown-content"
            style={{ display: isActive ? "block" : "none" }}
          >
            {optionList.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handlerChange(item.name);
                    setIsActive(false);
                  }}
                  className={
                    (index === 0 ? "first-option " : " ") +
                    "aoh-option-item ops-fs-5"
                  }
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDropdown;
