import moment from 'moment'
const configureTimer = (apitime: string, modifiedTime:string) => {
    const inDays = moment.duration({
        hours: parseInt(apitime.split(':')[0]),
    }).asDays();

    const changedtime =modifiedTime.split(':').map((item, i) => {
        return i === 0 ? parseInt(item) + Math.floor(inDays) * 24: item
    }).join(':')
    return changedtime;
}

export default configureTimer
