import { allLinesPayloadType, fetchApi } from "api/fetch-api";

export const getAllLines = async (data:allLinesPayloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/shopfloor/all_lines`,
    data 
  })
  return response; 
}
