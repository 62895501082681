import "./index.scss";
import "../../../../../../../assets/bootstrap.scss";
import React, { useContext, useState } from "react";

interface AlarmsByDurationTooltipType {
  currentAlarmsTooltipData: any;
}

const AlarmsByDurationTooltip: React.FC<AlarmsByDurationTooltipType> = (
  props: AlarmsByDurationTooltipType
) => {
  const { currentAlarmsTooltipData } = props;

  return (
    <div className="aoh-alarms-tooltip">
      <div className="aoh-tooltip-heading ops-fw-semibold aoh-pl-3 aoh-pt-3 aoh-pr-3">
        {currentAlarmsTooltipData?.alarm}
      </div>
      <div className="aoh-tooltip-container aoh-row aoh-mt-3 aoh-pl-3">
        <div className="aoh-col-6 ">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"duration"}
          </div>
          <div className="aoh-tooltip-content ops-fs-6 ops-fw-semibold">
            {currentAlarmsTooltipData?.duration}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6 aoh-pl-0">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"count"}
          </div>
          <div className="aoh-w-100 aoh-tooltip-content ops-fw-semibold ops-fs-6">
            {currentAlarmsTooltipData?.count}
          </div>
        </div>
      </div>
      <div className="aoh-tooltip-container aoh-mt-2 aoh-mb-0 aoh-pl-3">
        <div className="aoh-col-6 aoh-col-lg-6 aoh-last-tooltip-container">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"alarm text"}
          </div>
          <div className="aoh-tooltip-content ops-fs-5 ops-fw-medium">
            {currentAlarmsTooltipData?.details}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmsByDurationTooltip;
