import React, { 
  Key,
  useEffect, 
  useState 
} from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";
import StageStatusSection from "./stage-status-section";
import CleanProgressBar from "../clean-progress-bar";
import Timer from "components/resuable/timer";

interface CompletionSectionProps {
  currentTime: string;
  nextLotTime: string;
  colorCode: string;
  progressValue: number;
  progressValueColorCode: string;
  isRefetching: boolean
  ncaStartOrder: string;
  ncaStartDesc: string;
  ncaEndOrder: string;
  ncaEndDesc: string;
  data: any
}

const StageSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    currentTime,
    nextLotTime,
    colorCode,
    progressValue,
    progressValueColorCode,
    isRefetching,
    ncaStartOrder,
    ncaStartDesc,
    ncaEndOrder,
    ncaEndDesc,
    data
  } = props;
  const progressPercentage = progressValue ?? 0;

  const [incrementalTimer, setIncrementalTimer] = useState<string>("");
  const [elapsedTimer, setElapsedTimer] = useState<string>("")

  useEffect(() => {
    if (data) {
      setIncrementalTimer(data?.current_time);
    }
  }, [data, isRefetching]);
  useEffect(() => {
    if (currentTime) {
      setElapsedTimer(currentTime)
    }
  }, [currentTime])
  return (
    <div className="aoh-stage">
      <StageStatusSection />
      <div className="aoh-col-6 aoh-col-lg-6">
        <div className="aoh-status-progress-bar-header ops-fs-6 ops-fw-semibold aoh-mb-2">
          {"ACTIVITY PROGRESS"}
        </div>
      </div>
      <div className="aoh-status-progress-bar  aoh-mb-2">
        <CleanProgressBar
          percentageArray={[
            { percentage: progressPercentage > 100 ? 100 : progressPercentage, flag: progressValueColorCode },
          ]}
        />
      </div>
      <div className="aoh-stage-heading ops-fs-5 ops-fw-semibold">
        {(ncaStartOrder !== null || ncaEndOrder !== null) &&
          (ncaStartOrder === ncaEndOrder) ? (
          <>
            <span className="aoh-stage-flex">{ncaStartOrder} - {ncaStartDesc}</span>
          </>
        ) : (
          <>
            <span className="aoh-stage-flex">{ncaStartOrder} - {ncaStartDesc}</span>
            <span>{ncaEndOrder} - {ncaEndDesc}</span>
          </>
        )
        }
      </div>

      {nextLotTime !== "Unscheduled" ? (
        <div className="aoh-stage-container aoh-mt-3 aoh-row aoh-mt-2 aoh-mb-2">
          <div className="aoh-col-6  ">
            <div className="aoh-stage-heading  aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1 aoh-pl-1">
              {"Total time for Non-Commercial Window"}
            </div>
            <div
              className={
                (colorCode === "RED"
                  ? "aoh-lot-red"
                  : colorCode === "GREEN"
                  ? "aoh-lot-green"
                  : " ") +
                " aoh-target-content ops-fs-5 ops-fw-medium  aoh-pl-1 aoh-mb-1"
              }
            >
              {nextLotTime ?? "N/A"}
            </div>
          </div>
          <div className="aoh-col-6 aoh-col-lg-6">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
              {"elapsed time"}
            </div>
            <div className="aoh-w-100 aoh-predicted-content ops-fw-medium ops-fs-5 aoh-mb-1">
            {incrementalTimer !== "" && (
              <Timer
                startTime={incrementalTimer}
                setIncrementalTimer={(timer) => {
                  setIncrementalTimer(timer);
                }}
              />
            )}
            </div>
          </div>
        </div>
      ) : (
        <div className="aoh-stage-container aoh-mt-3 aoh-row aoh-mt-2 aoh-mb-2">
          <div className="aoh-col-12  aoh-mb-2">
            <div className="aoh-stage-heading  aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1 ">
              {"No upcoming Lots in Schedule"}
            </div>
          </div>
          <div className="aoh-col-12 aoh-col-lg-6">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
              {"elapsed time"}
            </div>
            <div className="aoh-w-100 aoh-predicted-content ops-fw-medium ops-fs-5 aoh-mb-1">
              {<Timer startTime={elapsedTimer} setIncrementalTimer={(item) => {setElapsedTimer(item)}} /> ?? "N/A"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StageSection;
