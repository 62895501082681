import React, { useEffect, useState } from "react";
import StageStatusSection from "./stage-status-section";
import StatusLabels from "./status-labels";
import Timer from "components/resuable/timer";
import "./index.scss";
import "../../../../assets/bootstrap.scss";
import BatchStatusProgressBar from "../batch-status-progress-bar";

interface CompletionSectionProps {
  data: any;
  isRefetching: boolean;
  phase: string;
}

const StageSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const { data, isRefetching, phase } = props;
  const [incrementalTimer, setIncrementalTimer] = useState<string>("");
  useEffect(() => {
    if (data) {
      setIncrementalTimer(data?.stage_elapsed_time);
    }
  }, [data, isRefetching]);

  const alert = data.target_color;

  const percentageArray = data?.progress?.map(
    (progressEntity: { status: string; percentage: string }) => {
      if (progressEntity.status === "RUNNING") {
        return { percentage: progressEntity.percentage, flag: "GREEN" };
      } else if (progressEntity.status === "DOWN") {
        return { percentage: progressEntity.percentage, flag: "RED" };
      } else if (progressEntity.status === "CHANGEOVER") {
        return { percentage: progressEntity.percentage, flag: "BLUE" };
      } else return { percentage: progressEntity.percentage, flag: "GREY" };
    }
  );
 
  if (percentageArray) {
    return (
      <div className="aoh-stage">
        <StageStatusSection data={data} />
        <div className="aoh-pl-1">
          <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
            {"Batch Progress"}
          </div>
          <div className="aoh-status-progress-bar">
            {percentageArray?.length > 0 ? (
              <BatchStatusProgressBar percentageArray={percentageArray} />
            ) : (
              <span>N/A</span>
            )}
          </div>
        </div>
        <div className="aoh-mb-0">
          <StatusLabels />
        </div>
        <div className="aoh-stage-container aoh-mt-3  aoh-row aoh-mt-2 aoh-mb-2">
          <div className="aoh-col-6  aoh-pl-3">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
              {"stage elapsed time"}
            </div>
            <div className="aoh-target-content ops-fs-5 ops-fw-bold">
              {incrementalTimer !== "" && (
                <Timer
                  startTime={incrementalTimer}
                  setIncrementalTimer={(timer) => {
                    setIncrementalTimer(timer);
                  }}
                />
              )}
            </div>
          </div>
          <div className="aoh-col-6 aoh-col-lg-6">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
              {"stage est. end time"}
            </div>
            <div className="aoh-w-100 aoh-predicted-content ops-fw-bold ops-fs-5">
              {data.stage_end_time ?? "N/A"}
            </div>
          </div>
        </div>
        <div className="aoh-stage-container aoh-row aoh-mt-2 aoh-mb-0">
          <div className="aoh-col-6 aoh-pl-3 aoh-col-lg-6">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
              {phase === "Setup" || phase === "Closedown"
                ? "time left"
                : "Completion vs Target"}
            </div>
            <div className="aoh-completion-target-content ops-fs-5 ops-fw-bold">
            {phase === "Setup" || phase === "Closedown"
                ? (data.time_left ?? "N/A") 
                : (data.completion ?? "N/A")}{" "}
              {data.completion && <span
                className={`${
                  alert === "GREEN"
                    ? "aoh-completion-target-green"
                    : alert === "RED"
                    ? "aoh-completion-target-red"
                    : alert === "YELLOW"
                    ? "aoh-completion-target-yellow"
                    : ""
                }`}
              >
                {data.target ?? "N/A"}
              </span>}
            </div>
          </div>
          <div className="aoh-col-6 aoh-col-lg-6">
            <div className="aoh-stage-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
              {"undefined downtime"}
            </div>
            <div className="aoh-w-100 aoh-predicted-content  ops-fw-bold ops-fs-5">
              {data.undefined_downtime_percentage ?? "N/A"} 
              
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
export default StageSection;
