import { fetchApi, payloadType } from "api/fetch-api";

export const getNotification = async (data: payloadType) => {
  const response = await fetchApi({
    header: {},
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/shopfloor/message`,
    data,
  });

  return response;
};
