import React from "react";
import "./index.scss";
import "../../../../../../../../assets/bootstrap.scss";
import Label from "components/resuable/label";

interface MetricProps {
  statusValue: string;
  timePercentage: string;
  totalTime: string;
}

const MetricCardContent: React.FC<MetricProps> = (props: MetricProps) => {
  const { statusValue, timePercentage, totalTime } = props;

  return (
    <div
      className={
        (statusValue === "Maintenance" ? "aoh-line " : " ") +
        "aoh-row aoh-metric-content aoh-pr-1"
      }
    >
      <div className={ "d-flex aoh-col-lg-4 aoh-col-sm-4 aoh-col-md-4 aoh-col-4 aoh-label-text-wrap aoh-pt-1 ops-lh-3"}>
        <div className="ops-mx-3  aoh-pb-2 ops-fs-5 ops-fw-bold">
          <Label
            StatusValue={statusValue}
            circleColor={
              statusValue === "Running" ||statusValue === "RUNNING"
                ? "#008533"
                : statusValue === "Down"|| statusValue === "DOWN"
                ? "#D62728"
                : statusValue === "IDLE" || statusValue === 'Idle'
                ?"#9C9C9C" : "#2FBCB6"
                 
            }
          />
        </div>
      </div>
      <div className=" d-flex aoh-col-lg-3 aoh-col-sm-4 aoh-col-md-4 aoh-col-3 ops-fs-5 aoh-total-time ops-fw-medium aoh-pt-1 ops-lh-3">
        {timePercentage}%
      </div>
      <div className="d-flex aoh-col-lg-5 aoh-col-sm-4 aoh-col-md-4 aoh-col-5 ops-fs-5 aoh-total-time ops-fw-medium ops-lh-3 aoh-pt-1">
        {totalTime}
      </div>
    </div>
  );
};

export default MetricCardContent;
