import React from "react";
import "./index.scss";

interface BatchUnitSectionProps{
  batchNumber:string 
  unitsPlanned:string
}

const BatchUnitSection: React.FC<BatchUnitSectionProps> = (props:BatchUnitSectionProps) => {
  const {batchNumber,unitsPlanned}= props
  return (
    <div className="aoh-oee aoh-row  aoh-mb-0">
      <div className="aoh-oee-heading aoh-col-6 aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
        {"batch no."}&nbsp;{" "}
        <br/>
        <span className="aoh-pl-1 aoh-oee-percent-wrapper aoh-batch-padding ops-fs-5">
          {batchNumber ?? "N/A"}
        </span>
      </div>
      <div className="aoh-pl aoh-curent-oee aoh-col-6 aoh-pr-4 aoh-text-uppercase ops-fs-6 ops-fw-semibold  aoh-mb-2">
        {"units planned"}{" "}
        <br/>
        <span className="aoh-pl-1 aoh-oee-percent-wrapper ops-fs-5 aoh-batch-padding">
          {unitsPlanned ?? "N/A"}
        </span>
      </div>
    </div>
  );
};
export default BatchUnitSection;
