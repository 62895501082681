import React, { useEffect, useState } from "react";
import Timer from "components/resuable/timer";
import CleanProgressBar from "../clean-progress-bar";
import "./index.scss";
import "../../../../assets/bootstrap.scss";
interface CompletionSectionProps {
  data: any;
  phase: string;
  isRefetching: boolean;
}

const PhaseSection: React.FC<CompletionSectionProps> = ({
  data,
  phase,
  isRefetching,
}: CompletionSectionProps) => {
  const [incrementalTimer, setIncrementalTimer] = useState<string>("");
  useEffect(() => {
    if (data) {
      setIncrementalTimer(data?.phase_elapsed_time);
    }
  }, [data, isRefetching]);

  const lineCleanText = data?.curr_phase ?? "N/A";
  return (
    <div className="aoh-phase">
      <div className="aoh-current-phase aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
        {"current phase"}
      </div>
      <div className="aoh-mb-1 ops-fs-5 aoh-clean-text">{lineCleanText}</div>
      <div className="aoh-phase-progress-bar">
        <CleanProgressBar
          percentageArray={[{ percentage: data?.phase_progress > 100 ? 100 :data?.phase_progress , flag: "BLUE" }]}
        />
      </div>
      <div className="aoh-phase-container aoh-row aoh-mt-3 aoh-mb-2">
        <div className="aoh-col-6 aoh-pl-3 aoh-col-lg-6">
          <div className="aoh-phase-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"phase elapsed time"}
          </div>
          <div className="aoh-target-content ops-fs-5 ops-fw-bold">
            {incrementalTimer !== "" ? (
              <Timer
                startTime={incrementalTimer}
                setIncrementalTimer={(timer) => {
                  setIncrementalTimer(timer);
                }}
              />
            ): "N/A"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-phase-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"phase avg trend"}
          </div>
          <div className="aoh-w-100 aoh-predicted-content ops-fw-bold ops-fs-5">
            {data?.phase_avg_trend ?? "N/A"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PhaseSection;
