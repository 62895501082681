import { CloseIcon } from "@gitlab-rtsensing/component-library";
import Errorhandler from "utils/error-handler";
import "./index.scss";
import { useState } from "react";
import { makeBold } from "utils/common-methods";
import APIErrorMessage from "utils/api-error";

interface HeaderAlertProps {
  data: any;
  isLoading: boolean;
  isError: boolean;
}

const HeaderAlert: React.FC<HeaderAlertProps> = ({
  data,
  isLoading,
  isError,
}: HeaderAlertProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [animation, setAnimation] = useState(false);
  const handleClose = () => {
    setIsVisible(false);
  };

  const handleAnimation = () => {
    setAnimation(true);
  };

  const title = !isLoading && !isError && data && data?.line_no && (
    <>
      {makeBold(data?.line_no)} is currently down due to {data?.reason_code}.   
    </>
  );
  return (
    <>
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {isVisible && !isLoading && !isError && data ? (
        <div
          className={`aoh-header-alert-wrapper ${
            animation ? "fade-out" : ""
          } aoh-d-flex align-items-center justify-content-between ops-mb-0 aoh-w-100 `}
          onTransitionEnd={handleClose}
        >
          <div className="aoh-sm-row aoh-md-row aoh-md-row aoh-d-flex justify-content-left">
            <div className="aoh-pl-3 aoh-pl-sm-4 aoh-pl-md-4 ops-fs-5 aoh-notification-title ops-font-arial">
              {title} 
            </div>
          </div>

          <div
            className="aoh-close-icon-wrapper aoh-d-flex aoh-pr-3 aoh-pl-3"
            onClick={handleAnimation}
          >
            <CloseIcon fill={"var(--ops-gray-700)"} width={24} height={24} />
          </div>
        </div>
      ) : !isLoading && !isError && (!data || data === null) ? (
        <div className="aoh-header-alert-wrapper aoh-header-alert-wrapper--no-data">
          <APIErrorMessage apierror={"No Data Available"} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderAlert;
