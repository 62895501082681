import "./index.scss";
import "../../../assets/bootstrap.scss";
import LineComposedChart from "./line-composed-chart";
import CardComponent from "../../resuable/card";
import CompletionSection from "./completion-section";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { batchCompletionDataType } from "../../../utils/data-types";

interface BatchCompletionDataProps {
  batchCompletionData: batchCompletionDataType;
  isLoading: boolean;
  isError: boolean;
}

const BatchCompletion: React.FC<BatchCompletionDataProps> = (
  props: BatchCompletionDataProps
) => {
  const { batchCompletionData, isError, isLoading } = props;

  return (
    <CardComponent header="Batch Completion" headerStatusColor="Green">
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {!isLoading && !isError && batchCompletionData ? (
        <div className="aoh-line-container aoh-mt-3">
          {
          batchCompletionData?.graph_data?.length > 0 ? (
            <LineComposedChart graphData={batchCompletionData.graph_data} />
          ) : (
            <APIErrorMessage apierror={"No  Graph Data Available"} />
          )}
          <CompletionSection
            actual={batchCompletionData.actual}
            comparisionColorCode={batchCompletionData.comparison_color_code}
            predictedCompletion={batchCompletionData.predicted_completion}
            target={batchCompletionData.target}
            targetCompletion={batchCompletionData.target_completion}
            unitsProduced={batchCompletionData.units_produced}
            unitsPlanned={batchCompletionData.units_planned}
          />
        </div>
      ) : !isLoading && !isError && !batchCompletionData ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    </CardComponent>
  );
};

export default BatchCompletion;
