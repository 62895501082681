import React from "react";
import "./index.scss";

interface ActiveAlarmsRefreshProps {
  totalActiveAlarms: string;
}

const ActiveAlarmsRefresh: React.FC<ActiveAlarmsRefreshProps> = (
  props: ActiveAlarmsRefreshProps
) => {
  const { totalActiveAlarms } = props;
  return (
    <div className="d-flex aoh-active-alarms-refresh-container  aoh-mt-0 aoh-pl-0 ops-fw-semibold aoh-text-uppercase">
      <div className="align-items-center ops-fs-6 aoh-ml-sm-3 aoh-active-value">
        {totalActiveAlarms ?? "N/A"}
      </div>
      <div className="align-items-center aoh-pl-1 ops-fs-6 aoh-active-text">
        {"Alarms"}
      </div>
    </div>
  );
};
export default ActiveAlarmsRefresh;
