import moment from "moment";
import "./index.scss";
import React from "react";
import {
  Label,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  DotProps,
  ComposedChart,
  Brush,
} from "recharts";
import { ParseFloat } from "utils/common-methods";

interface CustomDotProps extends DotProps {
  payload?: {
    name: string;
    oee: number;
    dotted: boolean;
  };
}

const CustomizedDot: React.FC<CustomDotProps> = ({
  cx,
  cy,
  stroke,
  payload,
}) => {
  if (cx === undefined || cy === undefined || !payload || !payload.dotted) {
    return null;
  }

  return payload.dotted ? (
    <svg
      x={cx - 3.5}
      y={cy - 3.5}
      width={7}
      height={7}
      fill={stroke}
      viewBox="0 0 7 7"
    >
      <circle cx={3.5} cy={3.5} r={3.5} />
    </svg>
  ) : null;
};

interface KpiLineChartGraphProps {
  graphData: OeePercentageValue[];
}

interface OeePercentageValue {
  oee_percentage: number;
  oee_value: string;
}

const CustomTooltip = (childProp: any) => {
  if (childProp.active && childProp.payload && childProp.payload?.length) {
    const defaultOeeValue = "00:00"
    const oeeValue = childProp.payload[0].payload.oee_value || defaultOeeValue;
    const [date, time] = oeeValue.split('T');
    const [hours, minutes] = time.split(':');
    const formattedTime = `${hours}:${minutes}`;
    return (
      <div className="aoh-custom-tooltip-batch aoh-pl-2 aoh-pt-2 aoh-pb-2 aoh-pr-4">
        <div className="aoh-pb-1 aoh-batch-date">
         <span className="aoh-article-title ops-fw-semibold">DATE:</span> {date}
        </div>
        <div className="aoh-pb-1 aoh-batch-time">
        <span className="aoh-article-title ops-fw-semibold">TIME:</span> {formattedTime}
        </div>
        <div className="aoh-pb-1 aoh-batch-actual">
          OEE3%: {childProp.payload[0].payload.oee_percentage}%
        </div>
      </div>
    );
  }
  return null;
};

const LineComposedChart: React.FC<KpiLineChartGraphProps> = (
  props: KpiLineChartGraphProps
) => {
  const { graphData } = props;
  const memoGraphData = graphData.map((item, index) => {

    return ({
      ...item,
      date: item.oee_value,
      dotted: index === graphData.length - 1,
    })
  })
  const renderCustomAxisTick = ({ x, y, payload }: { x: number, y: number, payload: any }) => {
    const date = new Date(payload.value).toLocaleString("en", {
      month: "2-digit",
      day: "2-digit"
    })
    const time = new Date(payload.value).toLocaleString("en", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit"
    })
    return (
      <>
        <text offset="5" x={x} y={y + 4}>{date}</text>
        <text offset="5" x={x} y={y + 20}>{time}</text>
      </>
    );
  }
  return (
    <div className="aoh-mt-2 aoh-row aoh-kpi-graph-div aoh-mb-0 aoh-pb-0">
      <ResponsiveContainer width="99%" height="95%">
        <ComposedChart
          data={[...memoGraphData]}
          margin={{
            top: 0,
            right: 10,
            left: -18,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="none"
            vertical={false}
            stroke="#e3e3e3"
            horizontalPoints={memoGraphData.length > 196 ? [0, 29, 58, 85, 100] : [0, 29, 58, 85, 118]}
          />
          <XAxis
            // tickFormatter={(val, i) => {
            //   // return val;
            //   return `${ParseFloat((parseInt(val)/60).toFixed(2),2)}H`;
            // }}
            dataKey={"date"}
            stroke="#9c9c9c"
            tickLine={false}
            axisLine={false}
            interval={23}
            padding={"gap"}
            height={32}
            tick={renderCustomAxisTick}
          />
          <YAxis
            // domain={[0, 100]}
            // ticks={[0, 25, 50, 75, 100]}
            interval={0}
            padding={{ bottom: 10 }}
            stroke="#9c9c9c"
            tickLine={false}
            axisLine={false}
          >
            <Label
              value="OEE3 %"
              position="insideLeft"
              offset={22}
              style={{ textAnchor: "middle" }}
              // dy={11}
              angle={-90}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />

          <Line
            type="monotone"

            dataKey="oee_percentage"
            stroke="#0063c3"
            dot={<CustomizedDot />}
            strokeWidth={"2px"}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
export default LineComposedChart;
