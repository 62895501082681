import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define a type for the user data
interface User {
  id: number;
  name: string;
  username: string;
  email: string;
}

// Define a type for the slice state
interface UserState {
  users: User[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
};

// Create an async thunk for fetching users
const fetchUsers = createAsyncThunk<User[], undefined>(
  'users/fetchUsers',
  async () => {
    const response = await fetch('https://jsonplaceholder.typicode.com/users');
    if (!response.ok) {
      throw new Error('Failed to fetch users');
    }
    const responseData = await response.json();
    return responseData;
  }
);

// Create the slice
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch users';
      });
  },
});

// Export the async thunk for use in components
export { fetchUsers };

// Export the reducer
export default userSlice.reducer;