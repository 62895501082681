import React, { useState, useEffect } from 'react';

interface countdownTimerProps {
  startTime: number;
}

const CountdownTimer = ({ startTime }: countdownTimerProps) => {
  const [time, setTime] = useState<number>(startTime);

  useEffect(() => {
    setTime(startTime)
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 60);
      }, 60000);

      return () => clearInterval(timer);
    }
  }, [startTime]);

  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;

    const hDisplay = h.toString().padStart(2, '0');
    const mDisplay = m.toString().padStart(2, '0');
    const sDisplay = s.toString().padStart(2, '0');

    return `${hDisplay}:${mDisplay} hrs`;
  };

  return <div>{formatTime(time)}</div>;
};

export default CountdownTimer;
