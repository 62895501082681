import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import "./index.scss";

const BatchCompletionLabels: React.FC = () => {
  return (
    <div className="aoh-d-flex aoh-status-labels aoh-pt-0 aoh-mt-0 aoh-mb-2">
      <div className="aoh-running-status aoh-pl-0">
        <div className="aoh-d-flex align-items-center">
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={"#0063C3"}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"actual"}
          </div>
        </div>
      </div>
      <div className="aoh-running-status aoh-pl-2 ">
        <div className="aoh-d-flex align-items-center aoh-running-label">
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={"#75A8DD"}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"target"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchCompletionLabels;
