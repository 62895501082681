import "./index.scss";
import "../../assets/bootstrap.scss";
import { getKPIContent } from "../../api/batch-information/kpi-api";
import KpiContainer from "./KPI/container.aoh";
import { getBatchCompletionContent } from "../../api/batch-information/batch-completion-api";
import BatchCompletionContainer from "./batch-completion/container.aoh";
import UpcomingLotsLine1Container from "./upcoming-lots-line-1/container.aoh";
import ShiftInfoTabContainer from "./shift-info-tab/container.aoh";
import BatchStatusOverviewContainer from "./batch-status-overview/container.aoh";
import BatchScheduleContainer from "./batch-schedule/container.aoh";
import { getUpcomingLotsLine1Content } from "api/scheduling/upcoming-lots-line-1-api";
import MachineStatusContainer from "./machine-status/container.aoh";
import StatusOverviewContainer from "./status-overview/container.aoh";
import HeaderAlertContainer from "./header-alert/container.aoh";
import { useContext } from "react";
import { AppContext } from "context/app-context";
import { CarouselLeftIcon } from "@gitlab-rtsensing/component-library";
import { useNavigate } from "react-router-dom";
import { useWindowSizeListener } from "hooks/use-windowsize-listener";
import { getIdpDetails } from "api/batch-information/idp-details-api";
import RteStatusContainer from "./rte-status-container/container.aoh";

interface BatchInfoProps {
  batchInfoRef: any
  sqdipRef: any
  id: string
}
const ShopFloorTabContent = (props: BatchInfoProps) => {
  const { appState } = useContext(AppContext)
  const navigate = useNavigate()
  const deviceType = useWindowSizeListener()
  return (
    <div className="aoh-row aoh-batch-info-container aoh-pt-sm-6 aoh-pt-9 aoh-mb-4">
      {deviceType === 'mobile' && <div className="aoh-w-100 aoh-mt-3">
        {deviceType === 'mobile' && <CarouselLeftIcon onClick={() => { navigate(-1) }} />}
      </div>}

      <div className="aoh-d-flex aoh-w-100">
        <HeaderAlertContainer />
      </div>
      <div >
        <ShiftInfoTabContainer />

      </div>
      {appState.phase !== "Run" &&
        appState.phase !== "Setup" &&
        appState.phase !== "Closedown" &&
        appState.nonCommercialFlag && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-lg-4 aoh-col-md-6  aoh-mt-4 aoh-mt-sm-4 aoh-col-sm-12">
            <StatusOverviewContainer />
          </div>
        )}
      {(appState.phase === "Run" ||
        appState.phase === "Setup" ||
        appState.phase === "Closedown") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-lg-4 aoh-col-md-6  aoh-mt-4 aoh-mt-sm-4 aoh-col-sm-12">
            <BatchStatusOverviewContainer ref={props.batchInfoRef} id={"aoh-current-batch"} />
          </div>
        )}
      {appState.phase === "Run" && (
        <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-12 aoh-mt-4 ">
          <KpiContainer getKPIContent={getKPIContent} />
        </div>
      )}
      {(appState.phase === "Run" ||
        appState.phase === "Setup") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-md-6 aoh-mt-4 aoh-col-sm-12">
            <BatchScheduleContainer />
          </div>
        )}
      {(appState.phase === "Run" ||
        appState.phase === "Setup" ||
        appState.phase === "Closedown") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-12 aoh-mt-4 ">
            <BatchCompletionContainer
              getBatchCompletionContent={getBatchCompletionContent}
            />
          </div>
        )}
      {(appState.phase === "Setup" || appState.phase === "Run") && (<div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-md-6 aoh-mt-4 aoh-col-sm-12">
        <RteStatusContainer
          getIdpDetails={getIdpDetails}
        />
      </div>)}
      <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-md-6 aoh-mt-4 aoh-col-sm-12">
        <UpcomingLotsLine1Container
          getUpcomingLotsLine1Content={getUpcomingLotsLine1Content}
        />
      </div>
      {(appState.phase === "Run" ||
        appState.phase === "Setup" ||
        appState.phase === "Closedown") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-12 aoh-mt-4 ">
            <MachineStatusContainer />
          </div>
        )}
      {/* TODO: Need to un comment this code once these components are available. */}
      {/* {(appState.phase === "Run" ||
          appState.phase === "Setup" ||
          appState.phase === "Closedown") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-12 aoh-mt-4 ">
            <EjectAndRejectContainer
              getEjectAndRejectContent={getEjectAndRejectContent}
            />
          </div>
        )} */}
      {/* {(appState.phase === "Run" ||
          appState.phase === "Setup" ||
          appState.phase === "Closedown") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-12 aoh-mt-4 ">
            <SQDIPSummaryContainer />
          </div>
        )} */}

      {/* {(appState.phase === "Run" || appState.phase === "Setup") && (
          <div className="aoh-d-flex aoh-batch-card-wrapper aoh-col-4 aoh-col-md-6 aoh-mt-4 aoh-col-sm-12">
            <BatchStatusAlarmsContainer />
          </div>
        )} */}
      {(appState.phase === "Run" ||
        appState.phase === "Setup" ||
        appState.phase === "Closedown") && (
          <div className="aoh-d-flex  ">
            {/* <SqdipMainSection ref={props.sqdipRef} id = "aoh-sqdip" /> */}
          </div>
        )}
    </div>
  );
};

export default ShopFloorTabContent;
