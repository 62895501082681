import CircleIcon from "assets/icons/circle-icon";
import React, { useState } from "react";
import "./index.scss";

interface LineDisplayProps {
  lineDisplayHandler: (title: string) => void;
  lineDisplayValues: ColorToggleProps;
}
interface ColorToggleProps {
  oee: boolean;
  target: boolean;
  availability: boolean;
  performance: boolean;
  quality: boolean;
}

const KpiModalStatusLabels: React.FC<LineDisplayProps> = (
  props: LineDisplayProps
) => {
  const { lineDisplayHandler, lineDisplayValues } = props;

  const changeCircleColor = (title: string) => {
    lineDisplayHandler(title);
  };

  return (
    <div className="aoh-d-flex aoh-status-labels aoh-mt-4 aoh-mb-2">
      <div className="aoh-running-status aoh-pl-2">
        <div
          onClick={() => changeCircleColor("oee")}
          className="aoh-d-flex align-items-center"
        >
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={`${lineDisplayValues.oee ? "#0063c3" : "white"}`}
              stroke={`${lineDisplayValues.oee ? "#0063c3" : "#9C9C9C"}`}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"oee"}
          </div>
        </div>
      </div>
      <div className="aoh-running-status aoh-pl-2 ">
        <div
          onClick={() => changeCircleColor("target")}
          className="aoh-d-flex align-items-center aoh-running-label"
        >
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={`${lineDisplayValues.target ? "#686868" : "white"}`}
              stroke={`${lineDisplayValues.target ? "#686868" : "#9C9C9C"}`}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"target"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status aoh-ml-2 ">
        <div
          className="aoh-d-flex align-items-center aoh-running-label"
          onClick={() => changeCircleColor("availability")}
        >
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={`${lineDisplayValues.availability ? "#2FBCB6" : "white"}`}
              stroke={`${lineDisplayValues.availability ? "#2FBCB6" : "#9C9C9C"}`}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"availability"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status aoh-ml-2 ">
        <div 
          className="aoh-d-flex align-items-center aoh-running-label"
          onClick={() => changeCircleColor("performance")}
        >
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={`${lineDisplayValues.performance ? "#D62728" : "white"}`}
              stroke={`${lineDisplayValues.performance ? "#D62728" : "#9C9C9C"}`}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"performance"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status  aoh-ml-2 ">
        <div
          className="aoh-d-flex align-items-center aoh-running-label"
          onClick={() => changeCircleColor("quality")}
        >
          <div className="aoh-pr-1 aoh-d-flex">
            <CircleIcon
              fill={`${lineDisplayValues.quality ? "#FFFF00" : "white"}`}
              stroke={`${lineDisplayValues.quality ? "#FFFF00" : "#9C9C9C"}`}
            />
          </div>
          <div className="aoh-running-label aoh-d-flex aoh-text-uppercase ops-fs-6 ops-fw-bold">
            {"quality"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default KpiModalStatusLabels;
