import { Status } from "@gitlab-rtsensing/component-library";
import "./index.scss";
import Title from "./title";
import Errorhandler from "utils/error-handler";
import RefreshTag from "components/resuable/refresh-tag";
import {
  batchInformationHeaderDataType,
  refreshDataType,
} from "utils/data-types";
import StatusLogo from "components/resuable/status-logo";

interface BatchInformationHeaderProps {
  BatchInformationHeaderdata: batchInformationHeaderDataType;
  isError: boolean;
  isLoading: boolean;
  refreshData: refreshDataType;
  isRefreshError: boolean;
  isRefreshLoading: boolean;
}
const BatchInformationHeader: React.FC<BatchInformationHeaderProps> = (
  props: BatchInformationHeaderProps
) => {
  const colorCode = props.BatchInformationHeaderdata?.color_code;
  return (
    <div className="aoh-row aoh-title d-flex align-items-center ops-mb-0 aoh-pt-2">
      <Errorhandler
        isLoading={props.isLoading}
        isError={props.isError}
        loaderColor={"BLUE"}
        hideLoader={true}
      />
      {!props.isLoading &&
      !props.isError &&
      props.BatchInformationHeaderdata ? (
        <>
          <div className="d-flex aoh-mt-0 aoh-mb-0 align-items-center">
            <div className="d-flex align-items-center  ops-fs-6 aoh-mr-2 ops-fw-semibold ops-font-arial">
              <Title
                title={
                  props?.BatchInformationHeaderdata?.material_desc ?? "N/A"
                }
              />
            </div>
            
              <div className={`d-flex align-items-center aoh-setup-status-${props.BatchInformationHeaderdata?.color_code?.toLowerCase()} text-uppercase aoh-mr-2 aoh-pl-1 aoh-pr-2 ops-fs-6 ops-fw-semibold ops-font-arial`}>
                <span className="aoh-d-flex aoh-mt-1 aoh-pr-1 align-items-center">
                <StatusLogo color={props.BatchInformationHeaderdata?.color_code?.toLowerCase()} size={16} className="ops-px-1 " alertClassName="aph-pb-1"/>
                </span>
                {props?.BatchInformationHeaderdata.status ?? "N/A"}
              </div>
                     
          </div>
          <div className="d-flex aoh-mt-2 aoh-refreshed-tag">
            <Errorhandler
              isLoading={props.isLoading}
              isError={props.isError}
              loaderColor={"BLUE"}
            />
            {!props.isRefreshLoading &&
            !props.isRefreshError &&
            props.refreshData ? (
              <RefreshTag data={props.refreshData?.batchinfo_refresh} />
            ) : !props.isRefreshLoading &&
              !props.isRefreshError &&
              !props.refreshData ? (
              <></>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : !props.isLoading &&
        !props.isError &&
        !props.BatchInformationHeaderdata ? (
        <></>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BatchInformationHeader;
