import React from "react";
import "./index.scss";

interface OeeSectionProps {
  colorCode: string;
  label: string;
  progressLabel: string;
  value: number;
}
const OeeSection: React.FC<OeeSectionProps> = (props: OeeSectionProps) => {
  const { colorCode, label, progressLabel, value } = props;
  return (
    <div className="aoh-oee aoh-d-flex justify-content-between aoh-mb-0 aoh-align-items-center">
      <div className="aoh-oee-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2 aoh-mt-1">
        {"oee3 for batch"}
      </div>
      <div className="d-flex aoh-align-items-center">
        <div className="aoh-curent-oee aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mr-1 aoh-mt-1 aoh-mb-2">
          {"current oee3"}
        </div>
        <div className="aoh-pl-1 aoh-oee-percent-wrapper ops-fw-semibold ops-fs-4 aoh-mb-2">
          {progressLabel ?? "N/A"}
        </div>
      </div>
    </div>
  );
};
export default OeeSection;
