import "./index.scss";
import "../../../assets/bootstrap.scss";
import ArticleCard from "./article-card";
import CardComponent from "../../resuable/card";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { idpDetailsDataType, idpDetailsDataObject } from "utils/data-types";

interface RteStatusProps {
    idpDetailsData: idpDetailsDataType;
    isLoading: boolean;
    isError: boolean;
}

const idpDetails = {
    status: "SUCCESS",
    message: "Data retrieval successful",
    data: [
        {
            hu_number_final: "079051",
            time_out_of_cold_storage: "08/20/2024 10:06:03 PM",
            remaining_time: "26:12",
            updated_on: "2024-08-21T01:49:32.389405"
        },
        {
            hu_number_final: "079013",
            time_out_of_cold_storage: "08/20/2024 05:08:26 PM",
            remaining_time: "21:15",
            updated_on: "2024-08-21T01:49:32.389405"
        }
    ]
}

const RteStatus: React.FC<RteStatusProps> = (
    props: RteStatusProps
) => {

    const { idpDetailsData, isError, isLoading } = props;
    const articleLength = idpDetailsData?.length
    return (
        <CardComponent header={"Remaining RTE for IDP HUs"}>
            <Errorhandler
                isLoading={isLoading}
                isError={isError}
                loaderColor={"BLUE"}
            />
            <div className="aoh-row d-flex aoh-mt-3">
                {!isLoading && !isError && idpDetailsData && idpDetailsData?.length > 0 && (
                    <>
                        <div className="aoh-article-title ops-fs-6 ops-fw-semibold aoh-col-lg-5 aoh-col-sm-5 aoh-col-4">
                            HU NUMBER
                        </div>
                        <div className="aoh-article-title ops-fs-6 ops-fw-semibold aoh-col-lg-7 aoh-col-md-7 aoh-col-sm-7 aoh-pl-5  aoh-col-4">
                            REMAINING RTE
                        </div>
                    </>
                )}
            </div>
            {
                !isLoading && !isError && idpDetailsData && idpDetailsData?.length > 0 ? (
                    idpDetailsData?.map((article: idpDetailsDataObject, index: number) => {
                        return (
                            <ArticleCard
                                totalCard={articleLength}
                                index={index}
                                key={index}
                                huNumberFinal={article.hu_number_final}
                                remainingTime={article.remaining_time}
                            />
                        );
                    })
                ) : !isLoading &&
                    !isError &&
                    (!idpDetailsData || idpDetailsData?.length === 0) ? (
                    <APIErrorMessage apierror={"No Active IDP"} />
                ) : (
                    <></>
                )
            }
        </CardComponent >
    );
};

export default RteStatus;
