import { fetchApi, nonCommTilePayloadType } from "api/fetch-api";

export const getNonCommHeaderContent = async (data:  nonCommTilePayloadType) => {
  const response = await fetchApi({
    header: {},
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/shopfloor/non_comm_refresh_date`,
    data,
  });

  return response;
};
