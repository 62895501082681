import React from "react";
import "./index.scss";
import "../../../../../../../assets/bootstrap.scss";
import VectorDropdownIcon from "components/resuable/vector-dropdown-icon";

interface StageStatusSectionProps {
  machine: string;
  statusValue: string;
  statusColorCode: string;
  activeAlarm: string;
  visibleState: boolean;
  toggleVisibility: (value: boolean) => void;
}
const LabelStatusSection: React.FC<StageStatusSectionProps> = (
  props: StageStatusSectionProps
) => {
  const {
    machine,
    activeAlarm,
    visibleState,
    toggleVisibility,
  } = props;

  const vectorToggle = () => {
    toggleVisibility(!visibleState);
  };

  return (
    <>
      <div
        className="aoh-machine-status-wrap aoh-row aoh-mt-2 aoh-mb-1"
        onClick={vectorToggle}
      >
        <div className="d-flex aoh-col-md-9 aoh-col-sm-8 aoh-col-lg-9">
          <div className="aoh-vector ops-fs-5 ops-fw-semibold aoh-pt-1 aoh-pl-2 aoh-pr-2 aoh-mb-0">
            <VectorDropdownIcon selectedDirection={visibleState} />
          </div>
          <div className="aoh-stage-header ops-fs-5 ops-fw-semibold aoh-mb-0">
            {machine}
          </div>
        </div>
      </div>
    </>
  );
};
export default LabelStatusSection;
