import { useState, useContext } from "react";
import RefreshTag from "components/resuable/refresh-tag";
import AlarmsStatusContent from "./alarms-status";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import { Loader } from "@gitlab-rtsensing/component-library";
import CustomDropdown from "components/resuable/dct-custom-dropdown";
import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import ActiveAlarmsRefresh from "./active-alarms-refresh";
import { MatomoContext } from "utils/matomo-context-provider";

interface StatusStateDataProps {
  machineStatusHourData: any;
  statusStateData: any;
  isLoading: boolean;
  isError: boolean;
  dropdownClickFn: (value1: string, value2: string) => void;
  isRefetching: boolean;
}

const HourTimeline: React.FC<StatusStateDataProps> = (
  props: StatusStateDataProps
) => {
  const context = useContext(MatomoContext)
  const { statusStateData, machineStatusHourData, isRefetching } = props;
  const statusStateDataObj = statusStateData?.show_status_drop_down?.map(
    (item: any, index: number) => {
      return { name: item, value: item };
    }
  );

  const hourStateDataObj = statusStateData?.time_frame_drop_down?.map(
    (item: any, index: number) => {
      return { name: item, value: item };
    }
  );

  const [hourState, setHourState] = useState({
    timeFrame: "",
    oeeDataValue: "Date",
  });
  const [statusState, setStatusState] = useState({
    showStatus: "",
    oeeDataValue: "Show",
  });

  const handleTimeChange = (value: string) => {
    props.dropdownClickFn(statusState.showStatus, value);
    setHourState({...hourState, timeFrame: value});
    context.trackApplicationFeature("filter", "Time Frame", value);
  }

  const handleStatusChange = (value: string) => {
    props.dropdownClickFn(value, hourState.timeFrame);
    setStatusState({...statusState, showStatus: value});
    context.trackApplicationFeature("filter", "Show Status", value);
  }

  return (
    <>
      <Errorhandler
        isLoading={props.isLoading}
        isError={props.isError}
        loaderColor={"BLUE"}
      />
      {!props.isLoading &&
      !props.isError &&
      props.statusStateData &&
      statusStateData?.show_status_drop_down?.length > 0 ? (
        <>
          <div className="aoh-machine-modal-container aoh-mt-2">
            <div className="aoh-d-flex aoh-flex-wrap aoh-w-100 aoh-align-items-center aoh-mt-4 aoh-justify-content-between">
              <div>
                <div className="ops-fw-semibold ops-fs-5 aoh-d-flex aoh-align-items-center aoh-status-graph-label">
                  Machine Timeline
                  <div
                    className="aoh-vertical-line
                    aoh-pt-1 
                    aoh-d-flex 
                    aoh-justify-content-center 
                    aoh-item-center "
                  ></div>
                  <div className="aoh-active-alarms-refresh">
                    <ActiveAlarmsRefresh
                      totalActiveAlarms={
                        machineStatusHourData?.total_active_alarms
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="aoh-mt-sm-2">
                <RefreshTag
                  data={machineStatusHourData?.last_refreshed_in_minutes}
                />
              </div>
            </div>
            <div className="aoh-d-flex aoh-align-items-center aoh-justify-content-between">
              <div className="aoh-mb-2 ">
                <CustomDropdown
                  label="TIME FRAME"
                  handlerChange={(value: string) => handleTimeChange(value)}
                  optionList={hourStateDataObj}
                  selectedValue={hourState.timeFrame}
                  initialLabel={hourStateDataObj[0]?.name}
                />
              </div>
              <div className="aoh-mb-2">
                <CustomDropdown
                  label="SHOW STATUS"
                  handlerChange={(value: string) => handleStatusChange(value)}
                  optionList={statusStateDataObj}
                  selectedValue={statusState.showStatus}
                  initialLabel={statusStateDataObj[0]?.name}
                />
              </div>
            </div>
            {isRefetching ? (
              <div className="aoh-w-100 d-flex aoh-justify-content-center">
                <Loader />
              </div>
            ) : machineStatusHourData?.machine_list?.length > 0 ? (
              <div className={"aoh-alarms-status-card-wrapper"}>
                {machineStatusHourData?.machine_list
                  ?.sort((a: any, b: any) => a.order_id - b.order_id)
                  .map((elData: any, i: number) => {
                    return (
                      <div
                        className={
                          "aoh-alarms-status-card" +
                          " aoh-d-flex justify-content-center align-items-center ops-mb-0 aoh-w-100 aoh-mb-2 aoh-mt-2"
                        }
                        key={i}
                      >
                        <AlarmsStatusContent
                          alarmStatusData={elData}
                          idMachineList={i}
                        />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div>
                <APIErrorMessage apierror={"No Data Available"} />
              </div>
            )}
          </div>
        </>
      ) : !props.isLoading &&
        !props.isError &&
        (!props.statusStateData ||
          props.statusStateData === null ||
          statusStateData?.show_status_drop_down?.length === 0) ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    </>
  );
};
export default HourTimeline;
