import "./index.scss";
import React, { useState } from "react";
import {
  LineChart,
  Label,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  DotProps,
} from "recharts";
import ProductionKpiLabels from "../production-labels";
import KpiBatchTooltip from "components/shop-floor-tab/KPI/kpi-tooltips/kpi-batch-tooltip";
import KpiShiftTooltip from "components/shop-floor-tab/KPI/kpi-tooltips/kpi-shift-tooltip";
import KpiDateTooltip from "components/shop-floor-tab/KPI/kpi-tooltips/kpi-date-tooltip";
interface CustomDotProps extends DotProps {
  payload?: {
    name: string;
    uv: number;
    dotted: boolean;
  };
}

interface KpiLineChartGraphProps {
  graphData: OeePercentageValue[];
  productionDataSelect: string;
}

interface OeePercentageValue {
  date: string;
  actual: number;
  target: number;
}

const ProductionLineChart: React.FC<KpiLineChartGraphProps> = (
  props: KpiLineChartGraphProps
) => {
  const { graphData, productionDataSelect } = props;
  const [lineDisplay, setLineDisplay] = useState({
    oee: true,
    target: true,
  });
  const [lineProductionData, setLineProductionData] = useState<any>([]);  

  const target = true;
  const CustomizedDot: React.FC<CustomDotProps> = ({
    cx,
    cy,
    stroke,
    payload,
  }) => {
    if (cx === undefined || cy === undefined || !payload || !payload.dotted) {
      return null;
    }

    return payload.dotted ? (
      <svg
        x={cx - 3.5}
        y={cy - 3.5}
        width={7}
        height={7}
        fill={stroke}
        viewBox="0 0 7 7"
      >
        <circle cx={3.5} cy={3.5} r={3.5} />
      </svg>
    ) : null;
  };

  const CustomTooltip = (childProp: any) => {
    if (childProp.active && childProp.payload && childProp.payload?.length) {
      return (
        <div className="custom-tooltip">
          {productionDataSelect === "Batch" ? (
            <KpiBatchTooltip kpiBatchData={undefined} isThroughput={true} />
          ) : productionDataSelect === "Shift" ? (
            <KpiShiftTooltip kpiShiftData={undefined} isThroughput={false} />
          ) : productionDataSelect === "Time" ? (
            <KpiDateTooltip kpiDateData={undefined} isThroughput={true} />
          ) : null}
        </div>
      );
    }
    return null;
  };

  const CustomTicks = (props: any) => {
    const { x, y, payload, value } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={3} y={2} dy={12} fill="#666">
          <tspan textAnchor="middle" x="0">
            {payload.value.split(" ")[0]}
          </tspan>
          {/* <tspan textAnchor="middle" x="0" dy="15">{payload.value.split(" ")[1]}</tspan> */}
        </text>
      </g>
    );
  };

  return (
    <>
      <div className="aoh-pd-0 aoh-mt-2 aoh-row aoh-kpi-graph aoh-mb-2">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={graphData ?? []}
            onMouseMove={(data) => {
              setLineProductionData(data);
            }}
            margin={{
              top: 20,
              right: 0,
              left: -16,
              bottom: 5,
            }}
          >
            <CartesianGrid
              // strokeDasharray="none"
              vertical={false}
              stroke="#e3e3e3"
              horizontalPoints={[21, 57, 91, 128, 165]}
            />
            <XAxis
              dataKey="date"
              tick={<CustomTicks />}
              // ticks={xaxisTicks}
              stroke="#e3e3e3"
              // tickLine={false}
              axisLine={false}
              interval={0}
              padding={{ left: 20, right: 15 }}
              height={30}
            />
            <YAxis
              domain={[0, 50]}
              ticks={[0, 10, 20, 30, 40, 50]}
              // tickFormatter={(val) => `${val}%`}
              stroke="#9c9c9c"
              tickLine={false}
              axisLine={false}
            >
              <Label
                value="THROUGHPUT (UPM)"
                position="insideLeft"
                offset={0}
                width={300}
                style={{ textAnchor: "middle" }}
                // dy={1}
                dx={21}
                angle={-90}
              />
            </YAxis>
            <Tooltip
              content={<CustomTooltip />}
              position={{
                x: lineProductionData.activeCoordinate?.x - 100,
                y: lineProductionData.activeCoordinate?.y - 300,
              }}
            />
            {lineDisplay.oee && (
              <Line
                // type="monotone"
                
                dataKey="actual"
                stroke="#0063c3"
                dot={<CustomizedDot />}
                strokeWidth={"1px"}
                isAnimationActive={false}
              />
            )}
            {lineDisplay.target && (
              <Line
                // type="monotone"
                
                dataKey="target"
                stroke="#686868"
                strokeDasharray="3 3"
                // dot={<CustomizedDot />}
                dot={false}
                strokeWidth={1}
                isAnimationActive={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <ProductionKpiLabels />
    </>
  );
};
export default ProductionLineChart;
