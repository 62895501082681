// import KpiModalLineChart from "./kpi-modal-line-chart";
import { Tooltip } from "@gitlab-rtsensing/component-library";
import RefreshTag from "components/resuable/refresh-tag";
import "./index.scss";
import MetricBox from "components/resuable/metric-box";
import "../../../../../assets/bootstrap.scss";
import DctDropDown from "components/resuable/dropdown";
import ProductionLineChart from "./production-line-chart";
import { useState } from "react";
const kpiContants = [
  {
    label: "OEE",
    value: "85%",
    value_diff: "(5%)",
    color_code: "RED",
  },
  {
    label: "AVAILABILITY",
    value: "67%",
    value_diff: "(5%)",
    color_code: "RED",
  },
];
interface ProductionPercentageValue {
  date: string;
  actual: number;
  target: number;
  dotted: boolean;
}
const productionData: ProductionPercentageValue[] = [
  {
    date: "13/13",
    actual: 14,
    target: 30,
    dotted: true,
  },
  {
    date: "13/14",
    actual: 41,
    target: 15,
    dotted: true,
  },
  {
    date: "13/15",
    actual: 36,
    target: 18,
    dotted: true,
  },
  {
    date: "13/16",
    actual: 27,
    target: 25,
    dotted: true,
  },
  {
    date: "13/17",
    actual: 47,
    target: 20,
    dotted: true,
  },
  {
    date: "13/18",
    actual: 32,
    target: 9,
    dotted: true,
  },
  {
    date: "13/19",
    actual: 6,
    target: 36,
    dotted: true,
  },
];

const ProductionComponent = () => {
  const [productionState, setProductionState] = useState({
    timeFrame: "",
    productionDataValue: "Batch",
  });
  return (
    <div>
      <div className="aoh-d-flex aoh-w-100 aoh-justify-content-between">
        <div className="ops-fw-semibold ops-fs-5 aoh-mt-2 aoh-d-flex">
          Overall Equipment Effectiveness
          <Tooltip
            className=" 
          aoh-ml-2 
          aoh-pt-1 
          aoh-d-flex 
          aoh-justify-content-center 
          aoh-item-center 
          component-library-tooltip"
          >
            Overall Equipment Effectiveness
          </Tooltip>
        </div>

        <div className="aoh-mt-2">
          <RefreshTag data={"12 min Ago"} />
        </div>
      </div>

      <div className="oee-label aoh-mt-2 aoh-mb-2">
        {" Current vs Prev Week AVG".toUpperCase()}
      </div>
      <div className="aoh-d-flex aoh-justify-content-between aoh-flex-wrap">
        {kpiContants.map((item, i) => (
          <div key={i} className="aoh-metric-card-wrapper">
            <MetricBox
              colorCode={item.color_code}
              label={item.label}
              metricValue={item.value}
              metricValue2={item.value_diff}
            />
          </div>
        ))}
      </div>
      <div className="aoh-d-flex aoh-justify-content-between aoh-mt-1">
        <div className=" aoh-w-50">
          <DctDropDown
            label="TIME FRAME"
            handlerChange={(data: string) => {
              setProductionState({
                ...productionState,
                timeFrame: data,
              });
            }}
            optionList={[
              { name: "Last 7 Days", value: "Last 7 Days" },
              { name: "Last 24 Hours", value: "Last 24 Hours" },
            ]}
            selectedValue={productionState.timeFrame}
          />
        </div>
        <div className="aoh-pl-2 aoh-w-50">
          <DctDropDown
            label="DATA"
            handlerChange={(data: string) => {
              setProductionState({
                ...productionState,
                productionDataValue: data,
              });
            }}
            optionList={[
              {
                name: "Batch",
                value: "Batch",
              },
              {
                name: "Shift",
                value: "Shift",
              },
              {
                name: "Time",
                value: "Time",
              },
            ]}
            selectedValue={productionState.productionDataValue}
          />
        </div>
      </div>

      <div>
        <ProductionLineChart
          graphData={productionData}
          productionDataSelect={productionState.productionDataValue}
        />
      </div>
    </div>
  );
};
export default ProductionComponent;
