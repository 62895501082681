/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, NavSection } from './types';
import { FadeTransition } from '../fade-transition';
import SectionLink from './section-link';
import SectionTitle from './section-title';
import './index.scss';
import { AppContext } from 'context/app-context';
import { ChevronIcon } from 'assets/icons/chevron-icon';

export interface DesktopSideNavProps {
  activeRouteData: NavLink;
  hideNav: boolean;
}

export const DesktopSideNav = (props: DesktopSideNavProps) => {
  const { activeRouteData, hideNav } = props;
  const [opened, setOpened] = useState(() => window.innerWidth >= 1400);
  const [navRoot, setNavRoot] = useState<any>(null)

  const contentRoot =
    document.getElementById('dct-main-content') ?? ({} as Element);
  const { appState } = useContext(AppContext)
  useEffect(() => {
    const navRefRoot = appState.navRoot;
    if (navRefRoot) {
      setNavRoot(appState?.navRoot)
    }
  }, [appState?.navRoot])
  useEffect(() => {
    if (opened && navRoot) {
      navRoot?.classList.add('nav-active');
      contentRoot?.classList?.add('nav-active');
    } else {
      navRoot?.classList?.remove('nav-active');
      contentRoot?.classList?.remove('nav-active');
    }

    return () => {
      navRoot?.classList?.remove('nav-active');
      contentRoot?.classList?.remove('nav-active');
    }
  }, [opened, navRoot?.classList, contentRoot?.classList]);

  if (hideNav) {
    navRoot?.classList?.add('sensing-nav-hide');
    navRoot?.classList?.remove('nav-active');
    contentRoot?.classList?.remove('nav-active');
  } else {
    navRoot?.classList?.remove('sensing-nav-hide');
  }

  const toggleNav = () => {
    setOpened(!opened);
  };

  const renderSubLinks = (
    sectionData: Array<NavSection>,
    linkType: 'internal' | 'external' | 'internalSection',
  ) => {
    const subLinkSections: Array<JSX.Element> = [];
    if (sectionData?.length) {
      sectionData.forEach(section => {
        subLinkSections.push(
          <SectionTitle key={section.sectionLabel} section={section} />,
        );

        if (section.links && section.links?.length) {
          section.links.forEach((link, index) => {
            if (link.authorized !== false) {
              subLinkSections.push(
                <SectionLink
                  key={link.label + index}
                  type={linkType}
                  url={link.url ?? '#'}
                  disabled={link.disabled}
                >
                  {link.label}
                </SectionLink>,
              );
            }
          });
        }
      });
    }

    return subLinkSections;
  };
  if (!navRoot) return <></>
  return createPortal(
    <div>
      <>
        <div className="nav-header-block">
          <FadeTransition itemKey={activeRouteData.label}>
            <p className="nav-header-title">
              {activeRouteData.label.toUpperCase()}
            </p>
          </FadeTransition>
          <button
            className={`sensing-nav-side-toggle-button ${opened ? 'sensing-nav-side-toggle-button--nav-active' : ''
              }`}
            onClick={toggleNav}
          >
            <ChevronIcon direction={`${opened ? 'LEFT' : 'RIGHT'}`} />
          </button>
        </div>
        <FadeTransition itemKey={activeRouteData.label}>
          {activeRouteData.jumpToSections?.length ? (
            <div className="nav-sub-links-block">
              {renderSubLinks(
                activeRouteData.jumpToSections,
                'internalSection',
              )}
            </div>
          ) : (
            <div />
          )}
          {activeRouteData.internalSections?.length ? (
            <div className="nav-sub-links-block">
              {renderSubLinks(activeRouteData.internalSections, 'internal')}
            </div>
          ) : (
            <div />
          )}
          {activeRouteData.externalSections?.length ? (
            <div className="nav-sub-links-block">
              {renderSubLinks(activeRouteData.externalSections, 'external')}
            </div>
          ) : (
            <div />
          )}
        </FadeTransition>
      </>

    </div>,
    navRoot,
  );
};

export default DesktopSideNav;
