import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import ShopFloorTab from ".";
import { getAllLines } from "api/batch-information/all-lines-api";

interface BatchInformationContainerProps {
  sqdipRef: any
  batchInfoRef: any
}
const ShopFloorContainer = ({ sqdipRef, batchInfoRef }: BatchInformationContainerProps) => {
  const { appState, setAppState } = useContext(AppContext);

  const location: any = useParams();
  const { data: allLinesData, isLoading, isError } = useQuery({
    queryKey: ["getAllLines", location.line, location.site],
    queryFn: getAllLines.bind(this, {
      site: location.site,
    }),
    refetchInterval: appState.refetchInterval,
  });

  useEffect(() => {
    setAppState((prevState: any) => {
      return {
        ...prevState,
        allLines: []
      }
    })

    setTimeout(() => {
      allLinesData?.data.data.forEach((line: any) => {
        setAppState((prevState: any) => {
          return {
            ...prevState,
            allLines: [...prevState.allLines, line.line_desc]
          }
        })
      });
    }, 1000);
  }, [allLinesData])

  return (
    <>
      <ShopFloorTab
        sqdipRef={sqdipRef}
        batchInfoRef={batchInfoRef}
        data={allLinesData?.data?.data}
        isError={isError}
        isLoading={isLoading}
      />
    </>
  );
};
export default ShopFloorContainer;
