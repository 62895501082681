import { useQuery } from "react-query";
import { useContext, useEffect } from "react";
import ShiftInfoTab from ".";
import { getShiftInfo } from "api/batch-information/shift-info";
import { useParams } from "react-router-dom";
import BatchInformationHeader from "../batch-info-header";
import { AppContext } from "context/app-context";
import NonComHeaderContainer from "../non-comm-header/container.aoh";
import { getRefreshData } from "api/batch-information/refresh-api";

const ShiftInfoTabContainer = () => {
  const { setAppState, appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: shiftInfoData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getShiftInfo", location.line],
    queryFn: getShiftInfo.bind(this, {
      site: location.site,
      line_no: location.line,
      mfg_stage: appState.mfg_stage,
      building: appState.building,
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !== "",
  });

  useEffect(() => {
    if (shiftInfoData) {
      setAppState({
        ...appState,
        curr_stage: shiftInfoData?.data?.data?.stage,
        phase: shiftInfoData?.data?.data?.stage,
        nonCommercialFlag: shiftInfoData?.data?.data?.non_commercial_flag,
      });
    }
  }, [shiftInfoData]);

  const {
    data: refreshData,
    isLoading: isRefreshLoading,
    isError: isRefreshError,
  } = useQuery({
    queryKey: "getRefreshData",
    queryFn: getRefreshData.bind(this, {
      site: location.site,
      line_no: location.line,
      mfg_stage: appState.mfg_stage,
      building: appState.building,
    }),
    refetchInterval: appState.refetchInterval,
    enabled:
      appState.nonCommercialFlag === false &&
      appState.mfg_stage !== "" &&
      appState.building !== "",
  });
  return (
    <>
      {/* <ShiftInfoTab
        ShiftInfoTabData={shiftInfoData?.data?.data}
        isError={isError}
        isLoading={isLoading}
      /> */}
      {!shiftInfoData?.data?.data?.non_commercial_flag && (
        <BatchInformationHeader
          BatchInformationHeaderdata={shiftInfoData?.data?.data}
          isError={isError}
          isLoading={isLoading}
          refreshData={refreshData?.data?.data}
          isRefreshLoading={isRefreshLoading}
          isRefreshError={isRefreshError}
        />
      )}
      {shiftInfoData?.data?.data?.non_commercial_flag && (
        <NonComHeaderContainer />
      )}
    </>
  );
};
export default ShiftInfoTabContainer;
