import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import './index.scss'

const StatusLabels: React.FC = () => {
  return (
    <div className="aoh-status-labels aoh-row aoh-mt-2 aoh-mb-2">
      <div className=" aoh-running-status aoh-res-running-label aoh-pl-3 aoh-col-lg-2 aoh-col-2">
        <div className="aoh-d-flex align-items-center">
          <div className="aoh-pr-1 aoh-pb-1">
            <CircleIcon fill={"#008533"} />
          </div>
          <div className="aoh-running-label  ops-fs-5 ops-fw-bold">
            {"Running"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status aoh-res-down-label aoh-pl-4 aoh-col-lg-2 aoh-col-2">
        <div className="aoh-d-flex align-items-center">
          <div className="aoh-pr-1 aoh-pb-1">
            <CircleIcon fill={"#D62728"} />
          </div>
          <div className="aoh-running-label  ops-fs-5 ops-fw-bold">
            {"Down"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status aoh-res-idle-label aoh-ml-2 aoh-col-lg-1 aoh-col-1">
        <div className="aoh-d-flex align-items-center">
          <div className="aoh-pr-1 aoh-pb-1">
            <CircleIcon fill={"#9C9C9C"} />
          </div>
          <div className="aoh-running-label  ops-fs-5 ops-fw-bold">
            {"Idle"}
          </div>
        </div>
      </div>
      <div className=" aoh-running-status aoh-res-changeover-label aoh-ml-3 aoh-col-lg-2 aoh-col-2">
        <div className="aoh-d-flex align-items-center">
          <div className="aoh-pr-1 aoh-pb-1">
            <CircleIcon fill={"#0063C3"} />
          </div>
          <div className="aoh-running-label  ops-fs-5 ops-fw-bold">
            {"Changeover"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatusLabels;
