import React, { useContext } from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import {
  Button,
  GroupedTextMetric,
  MetricCard,
  Status,
} from "@gitlab-rtsensing/component-library";
import ProgressbarIndicator from "components/resuable/mutli-progress-indicator";
import { getStatusText } from "utils/common-methods";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "context/app-context";
import { useQuery } from "react-query";
import { getIdpDetails } from "api/batch-information/idp-details-api";
import CircleIcon from "assets/icons/circle-icon";
import { Tooltip } from "react-tooltip";
import CountdownTimer from "components/resuable/countdowntimer/countdown-timer";
import { getBatchCompletionContent } from "api/batch-information/batch-completion-api";

interface MetricProps {
  lineNo: string;
  viewChangeFn: () => void;
  lineStatus: string;
  statusColor: string;
  batchNo: string;
  batchName: string;
  batchProgress: string;
  phase: string;
  machineStatus: string;
  oee: string;
  sortType: string;
  progress: { status: string; percentage: number }[];
  isNonCommercial: boolean;
  building:string;
}

const LineCardContentMobile: React.FC<MetricProps> = (props: MetricProps) => {
  const {
    progress,
    viewChangeFn,
    lineStatus,
    statusColor,
    batchNo,
    batchName,
    batchProgress,
    phase,
    machineStatus,
    oee,
    lineNo,
    isNonCommercial,
    building
  } = props;

  const { stage, site, plant } = useParams();
  const navigation = useNavigate();
   //Idp-details-api
   const { appState } = useContext(AppContext);
   const location: any = useParams();
   const { data: idpDetailsData, isLoading: isLoadingIdp, isError: isErrorIdp } = useQuery({
     queryKey: ['getIdpDetailsData', props.lineNo],
     queryFn: getIdpDetails.bind(this, {
       site: location.site,
       building: props.building,
       line_no: props.lineNo
     }),
     refetchInterval: appState.refetchInterval
   });

  //  batch-completion-api
   const { data: batchCompletionData, isLoading: isBatchCompletionLoading, isError: isBatchCompletionError } = useQuery({
    queryKey: ["getBatchCompletionContent", props.lineNo],
    queryFn: getBatchCompletionContent.bind(this, {
      site: location.site,
      line_no: props.lineNo,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !== ""
  });
  const actualPercentage = batchCompletionData?.data?.data?.actual;
  const cleanedPercentage = actualPercentage?.replace(/[^0-9.]/g, '');
  const percentageFloat = parseFloat(cleanedPercentage);
  const cappedPercentage = (!isNaN(percentageFloat) ? Math.min(percentageFloat, 100) : 0).toFixed(2) + "%";

  const percentageArray = progress?.map(
    (progressEntity: { status: string; percentage: number }) => {
      if (progressEntity.status?.toUpperCase() === "RUNNING") {
        return { percentage: progressEntity.percentage, flag: "GREEN" };
      } else if (progressEntity.status?.toUpperCase() === "DOWN") {
        return { percentage: progressEntity.percentage, flag: "RED" };
      } else if (progressEntity.status?.toUpperCase() === "CHANGEOVER") {
        return { percentage: progressEntity.percentage, flag: "BLUE" };
      } else return { percentage: progressEntity.percentage, flag: "GREY" };
    }
  );
  
  const handleLineClick = () => {
    navigation(`/manufacturing/${site}/shop-floor/${lineNo}`);
    viewChangeFn();
  };
  const hourToMilliseconds = (time: string) => {
    const second = time.split(":")
    const result = (+second[0] * (60000 * 60) + (+second[1] * 60000))
    return result
  }
  
  const remainingTimeChecker = (time: number) => {
    const totalMinutes = time / 60;
    if (totalMinutes > 120) {
      return '#008533';
    } else if (totalMinutes >= 15 && totalMinutes < 120) {
      return '#FFA500';
    }
    return '#D62728';
  };
  return (
    <MetricCard
      className={
        (isNonCommercial ? "aoh-non-comm-mobile" : "") +
        " aoh-metric-card aoh-pd-0"
      }
    >
      <>
        <MetricCard.Content className="aoh-d-flex aoh-metric-card-content ">
          <>
            <div className="aoh-d-flex ops-mb-2">
              <div className="aoh-col-4">
                <div className=" ops-fs-6 aoh-text-uppercase ops-fw-semibold aoh-key ">
                  LINE
                </div>
                <div className="  ops-fs-5 aoh-text-uppercase ops-fw-semibold ops-mt-1 aoh-value aoh-pointer aoh-line-hover" onClick={handleLineClick}>
                  {lineNo ?? "N/A"}
                </div>
              </div>
              <div className="aoh-col-4">
                <div className=" ops-fs-6 aoh-text-uppercase ops-fw-semibold aoh-pl-1 aoh-key">
                  LINE Status
                </div>
                {
                  <div className=" aoh-d-flex ops-fw-semibold  ops-fs-5 aoh-text-uppercase ops-mt-1 aoh-value">
                    <span className="aoh-line-status-mobile">
                      <Status
                        height={14}
                        alert={getStatusText(statusColor)}
                        alertClassName={`${
                          statusColor === "GRAY" &&
                          "ops-text-light aoh-gray-alert-icon"
                        } aoh-disable-status-hover`}
                      />
                    </span>
                    <span>{lineStatus ?? "N/A"}</span>
                  </div>
                }
              </div>
              <div className="aoh-col-4">
                <div className=" ops-fs-6 aoh-text-uppercase ops-fw-semibold aoh-key">
                  Batch No.
                </div>
                <div className="ops-fs-5 aoh-text-uppercase ops-mt-1 ops-fw-medium aoh-value">
                  {!isNonCommercial ? batchNo ?? "N/A" : "-"}
                </div>
              </div>
            </div>
            <GroupedTextMetric
              groupedFields={[
                {
                  fields: [
                    {
                      label: "Batch Name",
                      value: !isNonCommercial ? batchName ?? "N/A" : "-",
                    },
                  ],
                  title: "",
                },
                {
                  fields: [
                    {
                      label: "Batch Progress",
                      value: "",
                    },
                  ],
                  title: "",
                },
              ]}
              isColumn={false}
            />
            <div className="aoh-d-flex  align-items-center aoh-status ops-fs-5 aoh-status ops-mb-2">
              {!isNonCommercial && (
                <div className="aoh-progress-bar">
                  <ProgressbarIndicator
                    percentageArray={percentageArray ?? 0}
                  />
                </div>
              )}
              {!isNonCommercial ? (
                <div className="ops-mx-1 ops-fw-semibold">
                  {cappedPercentage ?? "N/A"}
                </div>
              ) : (
                "-"
              )}
            </div>
            <GroupedTextMetric
              groupedFields={[
                {
                  fields: [
                    {
                      label: "Phase",
                      value: !isNonCommercial ? phase ?? "N/A" : "-",
                    },
                    {
                      label: "Machine Status",
                      value: !isNonCommercial ? machineStatus ?? "N/A" : "-",
                    },

                    {
                      label: "OEE3",
                      value: !isNonCommercial ? oee ?? "N/A" : "-",
                    },
                  ],
                  title: "",
                },
              ]}
              isColumn={false}
            />
            <div className="aoh-col-4">
              <div className=" ops-fs-6 aoh-text-uppercase ops-fw-semibold aoh-key ">
                RTE Status
              </div>
              {(phase === "Setup" || phase === "Run")
                ? <div className="ops-fs-5 aoh-text-uppercase ops-fw-semibold ops-mt-1 aoh-value aoh-pointer aoh-mobile-flex">
                  {idpDetailsData?.data?.data?.length > 0
                    ? idpDetailsData?.data?.data?.map((item: any, i: any) => (
                      item?.remaining_time === "00:00" ? null : (
                        <div key={i} className="circle-tooltip-item">
                          <CircleIcon
                            fill={remainingTimeChecker(hourToMilliseconds(item?.remaining_time) / 1000)}
                            height={15}
                            width={15}
                          />
                        </div>
                      )
                    ))
                    : <span className="no-active-idp">No Active IDP</span>}
                  <Tooltip
                    id="my-tooltip-inline"
                    className="tooltip-container-rte"
                    render={({ content, activeAnchor }) => {
                      const time = activeAnchor?.getAttribute('data-time') ?? "00:00";
                      const line = activeAnchor?.getAttribute('data-lineNo');
                      const timeNumber = hourToMilliseconds(time) / 1000
                      return (
                        <div>
                          <h2 className="rte-heading">Remaining RTE</h2>
                          <div className="aoh-tooltip-body">
                            <div className="aoh-tooltip-rte-hu">HU {line ?? 'not set'}</div>
                            <div
                              style={{ backgroundColor: remainingTimeChecker(timeNumber) }}
                              className="aoh-tooltip-rte-time">
                              <CountdownTimer startTime={timeNumber} />
                            </div>
                          </div>

                        </div>
                      )
                    }}
                  />
                </div> 
                :<div className="ops-fs-5 aoh-text-uppercase ops-fw-semibold ops-mt-1 aoh-value aoh-pointer aoh-mobile-flex-hyphen">-</div>}
            </div>
          </>
        </MetricCard.Content>
      </>
    </MetricCard>
  );
};

export default LineCardContentMobile;
