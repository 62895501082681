import { Tooltip } from "@gitlab-rtsensing/component-library";
import { useState } from "react";
import RefreshTag from "components/resuable/refresh-tag";
import MetricBox from "components/resuable/metric-box";
import DctDropDown from "components/resuable/dropdown";
import KpiModalLineChart from "./kpi-modal-line-chart";
import "./index.scss";
import "../../../../assets/bootstrap.scss";

interface OeePercentageValue {
  date: string;
  oee: number;
  target: number;
  availability: number;
  performance: number;
  quality: number;
  dotted: boolean;
}
const kpiContants = [
  {
    label: "OEE",
    value: "85%",
    value_diff: "(5%)",
    color_code: "RED",
  },
  {
    label: "AVAILABILITY",
    value: "67%",
    value_diff: "(5%)",
    color_code: "RED",
  },
  {
    label: "PERFORMANCE",
    value: "85%",
    value_diff: "(5%)",
    color_code: "GREEN",
  },
  {
    label: "QUALITY",
    value: "75%",
    value_diff: "(5%)",
    color_code: "RED",
  },
];

const oeeData: OeePercentageValue[] = [
  {
    date: "13/13 MON",
    availability: 24,
    oee: 39,
    performance: 86,
    quality: 56,
    target: 31,
    dotted: true,
  },
  {
    date: "13/14",
    availability: 10,
    oee: 34,
    performance: 43,
    quality: 24,
    target: 30,
    dotted: true,
  },
  {
    date: "13/15",
    availability: 22,
    oee: 46,
    performance: 57,
    quality: 33,
    target: 89,
    dotted: true,
  },
  {
    date: "13/16",
    availability: 10,
    oee: 34,
    performance: 43,
    quality: 24,
    target: 30,
    dotted: true,
  },
  {
    date: "13/17",
    availability: 10,
    oee: 34,
    performance: 43,
    quality: 24,
    target: 30,
    dotted: true,
  },
  {
    date: "13/18",
    availability: 39,
    oee: 63,
    performance: 22,
    quality: 45,
    target: 36,
    dotted: true,
  },
  {
    date: "13/19",
    availability: 10,
    oee: 34,
    performance: 43,
    quality: 24,
    target: 30,
    dotted: true,
  },
  {
    date: "13/20 TODAY",
    availability: 10,
    oee: 34,
    performance: 43,
    quality: 24,
    target: 30,
    dotted: true,
  },
];

const OeeComponent = () => {
  const [oeeState, setOeeState] = useState({
    timeFrame: "",
    oeeDataValue: "Batch",
  });
  return (
    <div className="aoh-modal-container aoh-mt-2">
      <div className="aoh-d-flex aoh-w-100 aoh-justify-content-between">
        <div className="ops-fw-semibold ops-fs-5 aoh-mt-2 aoh-d-flex">
          Overall Equipment Effectiveness
          <Tooltip
            className="
          aoh-ml-2 
          aoh-pt-1 
          aoh-d-flex 
          aoh-justify-content-center 
          aoh-item-center 
          component-library-tooltip"
          >
            <div className="aoh-kpi-tooltip">Tooltip content</div>
          </Tooltip>
        </div>
        <div className="aoh-mt-2">
          <RefreshTag data={"6 Min Ago"} />
        </div>
      </div>
      <div className="oee-label aoh-mt-2">
        {" Current vs Prev Week AVG".toUpperCase()}
      </div>
      <div className="aoh-d-flex aoh-justify-content-between aoh-mt-0 aoh-flex-wrap">
        {kpiContants.map((item, i) => (
          <div key={i} className="aoh-metric-card-wraper">
            <MetricBox
              colorCode={item.color_code}
              label={item.label}
              metricValue={item.value}
              metricValue2={item.value_diff}
            />
          </div>
        ))}
      </div>
      <div className="aoh-d-flex aoh-justify-content-between aoh-mt-1">
        <div className=" aoh-w-50">
          <DctDropDown
            label="TIME FRAME"
            handlerChange={(data: string) => {
              setOeeState({
                ...oeeState,
                timeFrame: data,
              });
            }}
            optionList={[
              { name: "Last 7 Days", value: "Last 7 Days" },
              { name: "Last 24 Hours", value: "Last 24 Hours" },
            ]}
            selectedValue={oeeState.timeFrame}
          />
        </div>
        <div className="aoh-pl-2 aoh-w-50">
          <DctDropDown
            label="DATA"
            handlerChange={(data: string) => {
              setOeeState({
                ...oeeState,
                oeeDataValue: data,
              });
            }}
            optionList={[
              {
                name: "Batch",
                value: "Batch",
              },
              {
                name: "Shift",
                value: "Shift",
              },
              {
                name: "Time",
                value: "Time",
              },
            ]}
            selectedValue={oeeState.oeeDataValue}
          />
        </div>
      </div>
      <div>
        <KpiModalLineChart
          graphData={oeeData}
          oeeDataSelect={oeeState.oeeDataValue}
        />
      </div>
    </div>
  );
};
export default OeeComponent;
