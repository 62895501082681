/* eslint-disable @typescript-eslint/restrict-plus-operands */
import moment from "moment";
import momentDuration  from 'moment-duration-format';
momentDuration(moment as any);
//TODO: Status Value in Caps , issue is Alert type does not have values in 'CAPS'
export const getStatusText: (status: string) => "Green" | "Yellow" | "Red" = (
  status: string
) => {
  switch (status) {
    case "GREEN":
      return "Green";
    case "YELLOW":
      return "Yellow";
    case "RED":
      return "Red";
    default:
      return "Red";
  }
};

export const getStatus: (status: string) => string = (status: string) => {
  switch (status) {
    case "running":
      return "Running";
    case "idle":
      return "Idle";
    case "down":
      return "Down";
    case "online":
      return "Online";
    case "changeover":
      return "Changeover";
    default:
      return "";
  }
};

export const getStatusColor: (status: string) => any = (status: string) => {
  switch (status) {
    case "green":
      return "#008533";
    case "grey":
      return "#9C9C9C";
    case "red":
      return "#D62728";
    case "blue":
      return "#0063C3";
    default:
      return "#0063C3";
  }
};

export const makeBold = (word: String | undefined) => {
  return <b> {word}</b>;
};

export const captializeWord = (word: string) => {
  const wordArray = word
    .split("_")
    .map((w: string) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");
  return wordArray;
};

export const capsFirstLetter = (word: string) => {
  const wordArray = word?.toLowerCase();
  return wordArray.charAt(0).toUpperCase() + word.slice(1);
};

export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem("dct-okta-token");
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj?.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return "";
};
export const ParseFloat = (str: string, val: number) => {
  str = str.toString();
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
};

interface memoType {
  down: number;
  running: number;
  idle: number;
  subpercentage: number;
}
interface garphValue {
  active_alarms: number;
  batch: string;
  end_time: string;
  machine: string;
  order_id: number;
  percentage: number;
  refresh_time: number;
  start_time: string;
  state_duration: number;
  status: string;
  status_color_code: string;
  total_time: string;
  start_percentage: number;
  end_percentage: number;
}
export const getAgrregratedPercentage = (
  status: string,
  timeStatusTableData: []
) => {
  const allAggregratedPercentage = timeStatusTableData?.reduce(
    (memo: memoType, currentValue: garphValue) => {
      if (currentValue?.status?.toLowerCase() === "down") {
        const finaldown = memo.down + currentValue.percentage;
        memo = { ...memo, down: finaldown };
      } else if (currentValue?.status?.toLowerCase() === "running") {
        memo.running = memo.running + currentValue.percentage;
      } else if (currentValue?.status?.toLowerCase() === "idle") {
        memo.idle = memo.idle + currentValue.percentage;
      } else if (status === "subgraph") {
        memo.subpercentage =
          memo.subpercentage +
          (currentValue?.end_percentage - currentValue?.start_percentage);
      }
      return memo;
    },
    {
      down: 0,
      running: 0,
      idle: 0,
      subpercentage: 0,
    }
  );
  if (status === "down") {
    return allAggregratedPercentage.down;
  } else if (status === "idle") {
    return allAggregratedPercentage.idle;
  } else if (status === "running") {
    return allAggregratedPercentage.running;
  } else if (status === "subgraph") {
    return allAggregratedPercentage?.subpercentage;
  } else {
    return 0;
  }
};

export const getAgrregratedTimevalue = (
  status: string,
  timeStatusTableData: any
) => {
  const allAggregratedTimeValue = timeStatusTableData?.reduce(
    (memo: any, currentValue: any) => {
      if (currentValue?.status?.toLowerCase() === "down") {
        memo.down = memo.down + currentValue.state_duration
      }
      if (currentValue?.status?.toLowerCase() === "running") {
        memo.running = memo.running + currentValue.state_duration
      }
      if (currentValue.status?.toLowerCase() === "idle") {
        memo.idle = memo.idle + currentValue.state_duration
      } else if (status === "subgraph") {
        // memo.subtime.add(currentValue.mode_duration, "s");
        memo.subtime = memo.subtime + currentValue.mode_duration
      }
      return memo;
    },
    {
      down: 0,
      running: 0,
      idle: 0,
      subtime: 0,
    }
  );
  if (status === "down") {
    return moment?.duration(allAggregratedTimeValue.down, "seconds").format("hh:mm:ss");   
  } else if (status === "idle") {
    return moment?.duration(allAggregratedTimeValue.idle, "seconds").format("hh:mm:ss");   
  } else if (status === "running") {
    return moment?.duration(allAggregratedTimeValue.running, "seconds").format("hh:mm:ss");   
  } else if (status === "subgraph") {
    return moment?.duration(allAggregratedTimeValue.subtime, "seconds").format("hh:mm:ss");   
  }
};
