import { forwardRef } from "react";
import Errorhandler from "utils/error-handler";
import CardComponent from "../../resuable/card";
import StageSection from "./stage-section";
import PhaseSection from "./phase-section";
import APIErrorMessage from "utils/api-error";

interface BatchStatusOverviewProps {
  data: any;
  isLoading: boolean;
  isError: boolean;
  id: string;
  phase: string;
  isRefetching: boolean;
}
const BatchStatusOverview = forwardRef<HTMLDivElement, BatchStatusOverviewProps>(
  (props, ref) => {

    return (
      <div id={props.id} ref={ref} className="aoh-w-100">
        <CardComponent header={"Batch Status Overview"} className="aoh-h-100">
          <Errorhandler
            isLoading={props.isLoading}
            isError={props.isError}
            loaderColor={"BLUE"}
          />
          {!props.isLoading && !props.isError && props?.data ? (
            <>
              <StageSection
                data={props?.data}
                isRefetching={props.isRefetching}
                phase={props?.phase}
              />
              {(props.phase === "Setup" || props.phase === "Closedown") && (
                <PhaseSection
                  data={props?.data?.current_phase}
                  phase={props?.phase}
                  isRefetching={props.isRefetching}
                />
              )}
            </>
          ) : !props.isLoading &&
            !props.isError &&
            (!props.data || props.data === null) ? (
            <APIErrorMessage apierror={"No Data Available"} />
          ) : (
            <></>
          )}
        </CardComponent>
      </div>
    );
  }
);
BatchStatusOverview.displayName = "Current Batch Information";
export default BatchStatusOverview;