import { useEffect, useRef, useState } from "react";
import "../../../../../../../../assets/bootstrap.scss";
import "./index.scss";
import { Tooltip } from "react-tooltip";
import AlarmsByDurationTooltip from "../../alarm-by-duration-tooltip";

interface percentDetail {
  idMachineList: React.Key | null | undefined;
  idAlarmGraph: React.Key | null | undefined;
  alarm: string;
  count: number;
  details: string;
  duration: string;
  percentage: number;
  flag: "BLUE";
}
interface HorizontalBarType {
  percentageArray: percentDetail[];
}
const HorizontalBar: React.FC<HorizontalBarType> = (
  props: HorizontalBarType
) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);

  return (
    <div ref={containerRef} className="aoh-horizontal-bar-container">
      {props.percentageArray.map((data: percentDetail, i: number) => (
        <>
          <Tooltip
            opacity={1}
            render={() => {
              return (
                <div key={data.idAlarmGraph}>
                  <AlarmsByDurationTooltip
                    currentAlarmsTooltipData={data}
                    key={data.idAlarmGraph}
                  />
                </div>
              );
            }}
            noArrow={true}
            float
            className={"aoh-alarms-duration-tooltip"}
            style={{ backgroundColor: "white", padding: "0px", zIndex: "1000" }}
            anchorSelect={`#aoh-tooltip-alarm-anchor${data.idAlarmGraph}${data.idMachineList}`}
          ></Tooltip>
          <div
            key={data.idAlarmGraph}
            id={`aoh-tooltip-alarm-anchor${data.idAlarmGraph}${data.idMachineList}`}
            className="child-div-container"
            style={{
              width: `${data.percentage}%`,
              // backgroundColor: "#D62728",
            }}
          ></div>
        </>
      ))}
    </div>
  );
};
export default HorizontalBar;
