import type { MenuProps, TableColumnsType } from 'antd';

export interface LandingStateInterface {
    label: string;
    subLabel: string,
    sideNavLabel: string,
    site: string;
    route: string;
    authorized: boolean;
}

export const availableApps: LandingStateInterface[] = [
    {
        label: "AOH",
        subLabel: "Amgen Ohio",
        sideNavLabel: "Amgen Ohio (AOH)",
        site: "aoh",
        route: "aoh",
        authorized: false,
    },
    {
        label: "ABR",
        subLabel: "Amgen Breda",
        sideNavLabel: "Amgen Breda (ABR)",
        site: "abr",
        route: "abr",
        authorized: false,
    },
    {
        label: "ANC",
        subLabel: "Amgen North Carolina",
        sideNavLabel: "Amgen North Carolina (ANC)",
        site: "anc",
        route: "anc",
        authorized: false,
    },
    {
        label: "ARI",
        subLabel: "Amgen Rhode Island",
        sideNavLabel: "Amgen Rhode Island (ARI)",
        site: "ari",
        route: "ari",
        authorized: false,
    },
    {
        label: "AML",
        subLabel: "Amgen Puerto Rico",
        sideNavLabel: "Amgen Puerto Rico (AML)",
        site: "aml",
        route: "aml",
        authorized: false,
    },
    {
        label: "ADL",
        subLabel: "Amgen Dun Laoghaire",
        sideNavLabel: "Amgen Dun Laoghaire (ADL)",
        site: "adl",
        route: "adl",
        authorized: false,
    },
    {
        label: "ASM",
        subLabel: "Amgen Singapore Manufacturing",
        sideNavLabel: "Amgen Singapore Manufacturing (ASM)",
        site: "asm",
        route: "asm",
        authorized: false,
    },
    {
        label: "ATO",
        subLabel: "Amgen Thousand Oaks",
        sideNavLabel: "Amgen Thousand Oaks (ATO)",
        site: "ato",
        route: "ato",
        authorized: false,
    },
];

export const personas = [
    {
        name: "shopFloor",
        label: "Shop Floor",
        subLabel: "Shop Floor",
        url: "shop-floor/all-lines"
    },
    {
        name: "wct",
        label: "Work Center Team",
        subLabel: "WCT",
        url: "work-center-team"
    },
    {
        name: "eTOC",
        label: "Electronic Table of Contents (eTOC)",
        subLabel: "ETOC",
        url: "etoc"
    }
]

export const permissionObject: Record<string, boolean> = {
    aoh: false,
    abr: false,
    anc: false,
    ari: false,
    aml: false,
    adl: false,
    asm: false,
    ato: false,
};

export const tierItems: MenuProps['items'] = [
    {
      label: 'Tier 0',
      key: '0',
    },
    {
      label: 'Tier 1',
      key: '1',
    },
    {
      label: 'Tier 2',
      key: '2',
    },
    {
      label: 'Tier 3',
      key: '3',
    },
  ];

export const shiftItems: MenuProps['items'] = [
    {
      label: 'Shift 0',
      key: '0',
    },
    {
      label: 'Shift 1',
      key: '1',
    },
    {
      label: 'Shift 2',
      key: '2',
    },
    {
      label: 'Shift 3',
      key: '3',
    },
  ];

export const managerItems: MenuProps['items'] = [
    {
      label: 'Manager 0',
      key: '0',
    },
    {
      label: 'Manager 1',
      key: '1',
    },
    {
      label: 'Manager 2',
      key: '2',
    },
    {
      label: 'Manager 3',
      key: '3',
    },
  ];

export const costCenterItems: MenuProps['items'] = [
    {
      label: 'Manager 0',
      key: '0',
    },
    {
      label: 'Manager 1',
      key: '1',
    },
    {
      label: 'Manager 2',
      key: '2',
    },
    {
      label: 'Manager 3',
      key: '3',
    },
  ];

export const tierMapping: Record<number, string> = {
    0: 'Tier 0',
    1: 'Tier 1',
    2: 'Tier 2',
    3: 'Tier 3'
}

interface DataType {
    key: React.Key;
    issueDate: string;
    issue: string;
    owner: string;
    shortDescription: string;
    type: string;
    ehssProgramArea: string;
    behaviourObservationType: string;
  } 

  function ascendDescend (a: number|string , b: number|string): number {
    if (a < b) {  return -1;}
    if (a > b) {  return 1;}
    return 0;
  }

  export const columns: TableColumnsType<DataType> = [
    {
      title: 'ISSUE DATE',
      dataIndex: 'issueDate',
      sorter: (a, b) => {
        return ascendDescend(a.issueDate,b.issueDate)
      }
    },
    {
      title: 'ISSUE',
      dataIndex: 'issue',
      sorter: (a, b) => {
        return ascendDescend(a.issue,b.issue)
      }
    },
    {
      title: 'OWNER',
      dataIndex: 'owner',
      sorter: (a, b) => {
        return ascendDescend(a.owner,b.owner)
      }
    },
    {
      title: 'SHORT DESCRIPTION',
      dataIndex: 'shortDescription',
      sorter: (a, b) => {
        return ascendDescend(a.shortDescription,b.shortDescription)
      }
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      sorter: (a, b) => {
        return ascendDescend(a.type,b.type)
      }
    },
    {
      title: 'EHSS PROGRAM AREA',
      dataIndex: 'ehssProgramArea',
    },
    {
      title: 'BEHAVIOUR OBSERVATION TYPE',
      dataIndex: 'behaviourObservationType',
    },
  ];  

export const data: DataType[] = [
    {
      key: '1',
      issueDate: '08/07/2024',
      issue: 'Lorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '2',
      issueDate: '08/07/2024',
      issue: 'Lorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '3',
      issueDate: '08/07/2024',
      issue: 'Lorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '4',
      issueDate: '08/07/2024',
      issue: 'Lorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '5',
      issueDate: '05/07/2024',
      issue: 'Aorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '6',
      issueDate: '10/07/2024',
      issue: 'Aorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
    {
      key: '7',
      issueDate: '10/08/2024',
      issue: 'Aorem Ipsum',
      owner: 'At-Risk Condition',
      shortDescription: 'Lorem Ipsum',
      type: 'At-Risk Condition',
      ehssProgramArea: 'Lorem Ipsum',
      behaviourObservationType: 'Lorem Ipsum'
    },
  ];

// export const data = Array.from<DataType>({ length: 50 }).map<DataType>((_, i) => ({
//       key: '1',
//       issueDate: '08/07/2024',
//       issue: 'Lorem Ipsum',
//       owner: 'At-Risk Condition',
//       shortDescription: 'Lorem Ipsum',
//       type: 'At-Risk Condition',
//       ehssProgramArea: 'Lorem Ipsum',
//       behaviourObservationType: 'Lorem Ipsum'
//   }));