import "./index.scss";
import "../../../../../../../assets/bootstrap.scss";
import React, { useContext, useState } from "react";
import CircleIcon from "../../../../../../../assets/icons/circle-icon";

interface MachineStatusDataProps {
  currentStatusTooltipData: any;
}
const HourMachineStatusTooltip: React.FC<MachineStatusDataProps> = (
  props: MachineStatusDataProps
) => {
  const { currentStatusTooltipData } = props;
  return (
    <div className="aoh-tooltip">
      <div className="aoh-tooltip-heading ops-fw-semibold aoh-pl-3 aoh-pt-3 d-flex">
        <>
          {`Status - ${currentStatusTooltipData?.status} `}
          <div className="aoh-ml-2">
            <CircleIcon
              fill={`${
                currentStatusTooltipData?.status === "Down" || currentStatusTooltipData?.status ==="DOWN"
                  ? "#D62728"
                  : currentStatusTooltipData?.status === "Running" || currentStatusTooltipData?.status ==="RUNNING"
                  ? "#008533"
                  : currentStatusTooltipData?.status === "Idle" || currentStatusTooltipData?.status ==="IDLE"
                  ? "#9C9C9C"
                  : "#2FBCB6"
              }`}
            />
          </div>
        </>
      </div>
      <div className="aoh-tooltip-container aoh-row aoh-mt-2 aoh-pl-3">
        <div className="aoh-col-6 ">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"start"}
          </div>
          <div className="aoh-tooltip-content ops-fs-6 ops-fw-semibold">
            {currentStatusTooltipData?.start_time ?? "-"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6 aoh-pl-0">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"end"}
          </div>
          <div className="aoh-w-100 aoh-tooltip-content ops-fw-semibold ops-fs-6">
            {currentStatusTooltipData?.end_time ?? "-"}
          </div>
        </div>
      </div>
      <div className="aoh-tooltip-container aoh-row aoh-mt-2 aoh-mb-0 aoh-pl-3">
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"duration"}
          </div>
          <div className="aoh-tooltip-content ops-fs-6 ops-fw-semibold">
            {currentStatusTooltipData?.total_time ?? "-"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6 aoh-pl-0">
          <div className="aoh-tooltip-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"batch"}
          </div>
          <div className="aoh-w-100 aoh-tooltip-content  ops-fw-semibold ops-fs-6">
            {currentStatusTooltipData?.batch ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HourMachineStatusTooltip;
