import React from 'react';

const RealEstateActionIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25575_152902)">
                <path d="M4.37427 19.375C3.3401 19.375 2.49927 18.5342 2.49927 17.5V8.75C2.49927 8.405 2.77927 8.125 3.12427 8.125C3.46927 8.125 3.74927 8.405 3.74927 8.75V17.5C3.74927 17.845 4.02927 18.125 4.37427 18.125H15.6243C15.9693 18.125 16.2493 17.845 16.2493 17.5V8.75C16.2493 8.405 16.5293 8.125 16.8743 8.125C17.2193 8.125 17.4993 8.405 17.4993 8.75V17.5C17.4993 18.5342 16.6584 19.375 15.6243 19.375H4.37427Z" fill="#595959" />
                <path d="M0.625099 8.12438C0.426765 8.12438 0.238432 8.02854 0.120932 7.86938C0.0217653 7.73438 -0.0182347 7.56938 0.00676529 7.40354C0.0325986 7.23854 0.120099 7.09354 0.255099 6.99438L8.89093 0.661042C9.21593 0.422708 9.59927 0.296875 10.0001 0.296875C10.4001 0.296875 10.7834 0.422708 11.1084 0.661042L19.7443 6.99438C20.0218 7.19771 20.0826 7.59021 19.8793 7.86771C19.7618 8.02771 19.5734 8.12354 19.3751 8.12354C19.2409 8.12354 19.1134 8.08188 19.0051 8.00188L10.3701 1.66854C10.2609 1.58854 10.1334 1.54688 10.0009 1.54688C9.8676 1.54688 9.7401 1.58937 9.63093 1.66854L0.995099 8.00188C0.885932 8.08188 0.758432 8.12438 0.625099 8.12438Z" fill="#595959" />
                <path d="M18.1243 4.375C17.7793 4.375 17.4993 4.095 17.4993 3.75V1.875H15.6243C15.2793 1.875 14.9993 1.595 14.9993 1.25C14.9993 0.905 15.2793 0.625 15.6243 0.625H18.1243C18.4693 0.625 18.7493 0.905 18.7493 1.25V3.75C18.7493 4.095 18.4693 4.375 18.1243 4.375Z" fill="#595959" />
                <path d="M9.99927 16.2508C9.65427 16.2508 9.37427 15.9708 9.37427 15.6258V14.68C9.1326 14.6267 8.9001 14.5417 8.6801 14.4275L8.1376 14.9692C8.01927 15.0875 7.8626 15.1525 7.69593 15.1525C7.52927 15.1525 7.37177 15.0875 7.25427 14.9692C7.01093 14.7258 7.01093 14.3292 7.25427 14.085L7.71843 13.6208C7.4626 13.2883 7.2851 12.9092 7.1951 12.5008H6.24927C5.90427 12.5008 5.62427 12.2208 5.62427 11.8758C5.62427 11.5308 5.90427 11.2508 6.24927 11.2508H7.1951C7.28593 10.8425 7.46343 10.4625 7.71927 10.1308L7.2551 9.66667C7.13677 9.54833 7.07177 9.39167 7.07177 9.225C7.07177 9.05833 7.13677 8.90083 7.2551 8.78333C7.3726 8.665 7.5301 8.6 7.69677 8.6C7.86343 8.6 8.02093 8.665 8.13843 8.78333L8.68093 9.32583C8.90177 9.21083 9.13343 9.12667 9.3751 9.0725V8.125C9.3751 7.78 9.6551 7.5 10.0001 7.5C10.3451 7.5 10.6251 7.78 10.6251 8.125V9.07083C10.8668 9.12417 11.0993 9.20917 11.3193 9.32417L11.8609 8.78167C11.9793 8.66333 12.1359 8.59833 12.3026 8.59833C12.4693 8.59833 12.6268 8.66333 12.7443 8.78167C12.8626 8.89917 12.9276 9.05667 12.9276 9.22333C12.9276 9.39 12.8626 9.5475 12.7443 9.665L12.2801 10.1292C12.5359 10.4617 12.7134 10.8417 12.8034 11.2492H13.7493C14.0943 11.2492 14.3743 11.5292 14.3743 11.8742C14.3743 12.2192 14.0943 12.4992 13.7493 12.4992H12.8034C12.7134 12.9075 12.5351 13.2875 12.2801 13.6192L12.7443 14.0833C12.9876 14.3267 12.9876 14.7233 12.7443 14.9675C12.6259 15.0858 12.4693 15.1508 12.3026 15.1508C12.1359 15.1508 11.9784 15.0858 11.8609 14.9675L11.3193 14.4258C11.0984 14.5408 10.8668 14.6258 10.6251 14.6792V15.625C10.6243 15.97 10.3443 16.2508 9.99927 16.2508ZM9.99927 10.2508C9.10343 10.2508 8.37427 10.98 8.37427 11.8758C8.37427 12.7717 9.10343 13.5008 9.99927 13.5008C10.8951 13.5008 11.6243 12.7717 11.6243 11.8758C11.6243 10.98 10.8951 10.2508 9.99927 10.2508Z" fill="#595959" />
            </g>
            <defs>
                <clipPath id="clip0_25575_152902">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RealEstateActionIcon