import { MetricCard, Modal, Tabs } from "@gitlab-rtsensing/component-library";
import OeeComponent from "./oee";
import './index.scss'
import ProductionComponent from "./production-section";
interface KpiModalInterface {
  opened: boolean;
  close: () => void;
  title: string;
}
const KpiModal: React.FC<KpiModalInterface> = ({ opened, close, title }) => {
  return (
    <Modal
    className={"aoh-kpi-modal"}
    
      opened={opened}
      onClose={() => {
        close();
      }}
    >
      <Modal.Header contentClassName="d-flex aoh-kpi-modal-header align-items-center">
        <Modal.Title title={title} />
      </Modal.Header>
      <MetricCard.Content>
        <div className="d-flex aoh-flex-column aoh-kpi-modal-content w-100">
          <Tabs
            tabs={[
              {
                header: {
                  label: "OEE",
                },
                content: <OeeComponent />,
              },
              {
                header: {
                  label: "Run",
                },
                content: <ProductionComponent/>,
              },
            ]}
          />
        </div>
      </MetricCard.Content>
    </Modal>
  );
};
export default KpiModal;
