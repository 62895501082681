import { useQuery } from "react-query";
import { useContext } from "react";
import UpcomingLotsLine1 from ".";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import { payloadType } from "api/scheduling/upcoming-lots-line-1-api";

interface UpcomingLotsLine1ContainerProps {
  getUpcomingLotsLine1Content:(data: payloadType) => Promise<any>
}
const UpcomingLotsLine1Container = (props:UpcomingLotsLine1ContainerProps) => {
  const {getUpcomingLotsLine1Content}=props

  const { appState } = useContext(AppContext);

  const location: any = useParams();

  const {
    data: upcomingLotsLine1Data,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getUpcomingLotsLine1Content", location.line],
    queryFn: getUpcomingLotsLine1Content.bind(this, {
      site: location.site,
      line_no: location.line,
      curr_stage: appState.curr_stage,
      building: appState.building
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !== "",
  });
  const delayTime = upcomingLotsLine1Data?.data?.data[0]?.delay_time;
  const delayStatus = upcomingLotsLine1Data?.data?.data[0]?.delay_status;
  const absoluteDelay = upcomingLotsLine1Data?.data?.data[0]?.absolute_delay;
  return (
    <UpcomingLotsLine1
      upcomingLotsData={upcomingLotsLine1Data?.data?.data}
      isError={isError}
      isLoading={isLoading}
      delayTime={delayTime}
      delayStatus={delayStatus}
      absoluteDelay={absoluteDelay}
    />
  );
};
export default UpcomingLotsLine1Container;
