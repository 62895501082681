import { useQuery } from "react-query";
import { forwardRef, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "context/app-context";
import BatchStatusOverview from ".";
import { getBatchStatusOverview } from "api/batch-information/batch-status-overview";

interface BatchStatusOverviewContainerProps {
  id: string
}
const BatchStatusOverviewContainer = forwardRef<HTMLDivElement, BatchStatusOverviewContainerProps>(
  (props, ref) => {

    const { appState } = useContext(AppContext);
    const batchStatusRef = useRef<HTMLDivElement>(null)

    const location: any = useParams()
    const {
      data,
      isLoading,
      isError,
      isRefetching
    } = useQuery({
      queryKey: ["getBatchStatusOverview", location.line],
      queryFn: getBatchStatusOverview
        .bind(this, {
          site: location.site,
          line_no: location.line,
          curr_stage: appState.curr_stage,
          mfg_stage: appState.mfg_stage,
          building: appState.building

        }),
      refetchInterval: appState.refetchInterval,
      enabled: appState.phase != null && (appState.mfg_stage !== "" && appState.building !== ""),

    });

    return (
      <>
        <BatchStatusOverview
          ref={batchStatusRef} id={"aoh-current-batch"}
          data={data?.data?.data}
          isLoading={isLoading}
          isError={isError}
          phase={appState.phase}
          isRefetching={isRefetching}
        />
      </>
    );
  });

BatchStatusOverviewContainer.displayName = "BatchStatusOverviewContainer"

export default BatchStatusOverviewContainer;