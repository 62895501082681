import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";

interface CompletionSectionProps {
  actual: string;
  comparisionColorCode: string;
  predictedCompletion: string;
  target: string;
  targetCompletion: string;
  unitsPlanned: string;
  unitsProduced: string;
}

const CompletionSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    actual,
    comparisionColorCode,
    predictedCompletion,
    target,
    unitsPlanned,
    unitsProduced,
  } = props;

  return (
    <>
      <div className="aoh-completion aoh-row aoh-mt-2 aoh-mb-2">
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading ops-fs-6 ops-fw-semibold aoh-mb-2">
            {"PREDICTED COMPLETION"}
          </div>
          <div className="aoh-w-100 aoh-predicted-content ops-fw-semibold ops-fs-5">
            {predictedCompletion ?? "N/A"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-pl-2 aoh-col-lg-6">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
            {"actual vs target"}
          </div>
          {<div className="aoh-actual-target-content ops-fs-5 ops-fw-semibold">
            {actual ?? "N/A"}{" "}
            {!(!actual && !target)&& (<span
              className={
                comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : comparisionColorCode ==="YELLOW"
                  ? "aoh-actual-target-yellow"
                  : ""

              }
            >
              {target ?? "N/A"}
            </span>)}
          </div>}
        </div>
      </div>
      <div className="aoh-completion aoh-row aoh-mt-2 aoh-mb-2">
        
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
            {"units produced/planned"}
          </div>
          <div className="aoh-w-100 aoh-predicted-content aoh-text-uppercase ops-fw-semibold ops-fs-5">
            {unitsProduced ?? "N/A"}
            <span className={"ops-text-light"}>
              {"/"}
              {unitsPlanned ?? "N/A"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompletionSection;
