import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";
import { Status } from "@gitlab-rtsensing/component-library";
import StatusLogo from "components/resuable/status-logo";
import { getStatus } from "utils/common-methods";

interface MetricProps {
  machineValue: string;
  machineFlag: string;
  status: string;
  isActiveAlarms: boolean;
  activeAlarms: number | string;
  downTime: string;
}

const MetricCardContent: React.FC<MetricProps> = (props: MetricProps) => {
  const {
    machineValue,
    machineFlag,
    status,
    activeAlarms,
    isActiveAlarms,
    downTime,
  } = props;
  const a = true;
  //TODO: make changes in the getStatusText fn to accomodate caps values and return the right values
  return (
    <div
      className={`aoh-row ${
        machineFlag === "RED" && isActiveAlarms
          ? "aoh-metric-content--red"
          : "aoh-metric-content"
      } flex-grow-1 aoh-mb-2`}
    >
      <div
        className={`d-flex aoh-col-4 ${
          isActiveAlarms
            ? "aoh-col-lg-4 aoh-col-sm-4"
            : "aoh-col-lg-5 aoh-col-sm-5"
        }   aoh-machine aoh-pt-1 ops-lh-3`}
      >
        <div className="ops-fw-semibold aoh-pb-2 ops-fs-5 aoh-pt-1">
          {machineValue ?? "N/A"}
        </div>
      </div>
      <div
        className={`align-items-center d-flex aoh-col-5 ${
          isActiveAlarms
            ? "aoh-col-lg-6 aoh-col-md-6 aoh-col-sm-6"
            : "aoh-col-lg-7 aoh-col-md-7 aoh-col-sm-7 aoh-pl-5"
        }   aoh-complete  aoh-pt-0 ops-lh-3 aoh-d-flex`}
      >
        <div className="aoh-d-flex aoh-status-container aoh-pt-1">
          <div className="aoh-machine-status-icon">
            {machineFlag && (
              <StatusLogo
                color={machineFlag?.toLowerCase()}
                size={14}
                className="aoh-disable-status-hover aoh-px-1"
                alertClassName="aoh-pb-1"
              />
            )}
          </div>
          <div className="ops-mx-1 aoh-status-text  aoh-status-value ops-fw-medium ops-fs-5">
            <div className="align-items-center aoh-status-value ops-fw-medium ops-fs-5 aoh-pr-1">{getStatus(status?.toLowerCase())  ?? "N/A"}</div>
            {downTime /* time variable for down and idle status */ && (
              <div className="align-items-center aoh-status-value ops-fw-medium ops-fs-5">
                for
                <span className="align-items-center aoh-status-value ops-fw-semibold ops-fs-5">
                  &nbsp;{downTime}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {isActiveAlarms ? (
        <div
          className={`d-flex aoh-col-3 aoh-col-lg-2  aoh-col-md-2 aoh-col-sm-2 aoh-active-alarms ops-lh-3 aoh-pt-2`}
        >
          <div
            className={`d-flex  ${
              machineFlag === "RED"
                ? " ops-fw-semibold"
                : "ops-fw-medium aoh-alarms-value"
            } aoh-pb-2 ops-fs-5 `}
          >
            {activeAlarms}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MetricCardContent;
//
