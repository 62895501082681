import { fetchApi} from "api/fetch-api";

export const getUpcomingLotsLine1Content = async (data:payloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/shopfloor/upcoming_lots`,
    data
  })
 
  return response;
   
}
export interface payloadType {
  site: string;
  line_no: string;
  curr_stage: string;
  building:string;
}
