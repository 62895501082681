import { fetchApi, payloadType } from "api/fetch-api";

export const getBatchStatusOverview = async (data:payloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/shopfloor/batch_status_overview`,
    data 
  })
 
  return response;
   
}
