import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";

interface metricHeaderProps {
  isActiveAlarms: boolean;
  machine: string;
  status: string;
  activeAlarms: string;
}

const MetricHeader: React.FC<metricHeaderProps> = (
  props: metricHeaderProps
) => {
  const { machine, status, activeAlarms, isActiveAlarms } = props;

  return (
    <div
      className={`aoh-metric-header aoh-row aoh-mb-2 ops-fs-6 ops-fw-semibold ops-lh-4 aoh-mt-3 d-flex flex-grow-1 `}
    >
      <div
        className={`${
          isActiveAlarms
            ? "aoh-col-lg-4 aoh-col-sm-4"
            : "aoh-col-lg-5 aoh-col-sm-5"
        } aoh-col-4 `}
      >
        {machine}
      </div>
      <div
        className={`${
          isActiveAlarms
            ? "aoh-col-lg-4 aoh-col-md-6 aoh-col-sm-5"
            : "aoh-col-lg-7 aoh-col-md-7 aoh-col-sm-7 aoh-pl-5"
        }  aoh-col-4 `}
      >
        {status}
      </div>
      {isActiveAlarms && (
        <div className="aoh-active-alarms-header aoh-col-lg-4 aoh-col-md-2 aoh-col-sm-3 aoh-pl-1">
          {activeAlarms}
        </div>
      )}
    </div>
  );
};

export default MetricHeader;
