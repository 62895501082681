import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useContext, useEffect } from "react";
import { AppContext } from "context/app-context";
import DayTimeline from ".";
import { getMachineStatusModal7Day } from "api/batch-information/machine-status-modal-7-day-api";
import { MatomoContext } from "utils/matomo-context-provider";

const DayTimelineContainer: React.FC = () => {
  const { appState } = useContext(AppContext);
  const context = useContext(MatomoContext);

  const location: any = useParams();

  const { data, isLoading, isError } = useQuery({
    queryKey: ["getMachineStatusModal7Day", location.line],
    queryFn: getMachineStatusModal7Day.bind(this, {
      type: "7_days",
      site: location.site,
      line_no: location.line,
      mfg_stage: appState.mfg_stage,
      building: appState.building
      
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !=="",
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (data) {
      context.trackEvent("tab_click_event", "Machine Status", "7 Day Timeline");
    }
  }, [data]);

  return (
    <DayTimeline
      data={data?.data?.data}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
export default DayTimelineContainer;
