interface Props {
  fill: string;
  classname?: string;
  height?: number;
}

const ChangeIcon = ({ fill, classname, height }: Props) => {
  return (
    <svg
      width="14"
      height={height}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4734 3.19336L10.8067 0.526689C10.7446 0.46453 10.6708 0.415222 10.5896 0.381582C10.5083 0.347942 10.4213 0.330627 10.3334 0.330627C10.1559 0.330627 9.98559 0.401153 9.86005 0.526689C9.73452 0.652224 9.66399 0.822488 9.66399 1.00002C9.66399 1.17756 9.73452 1.34782 9.86005 1.47336L11.3934 3.00002H3.66672C3.48991 3.00002 3.32034 3.07026 3.19531 3.19528C3.07029 3.32031 3.00005 3.48988 3.00005 3.66669C3.00005 3.8435 3.07029 4.01307 3.19531 4.13809C3.32034 4.26312 3.48991 4.33336 3.66672 4.33336H13.0001C13.1317 4.3327 13.2601 4.2931 13.3693 4.21954C13.4784 4.14599 13.5634 4.04177 13.6134 3.92002C13.6644 3.79861 13.6784 3.66481 13.6535 3.53548C13.6286 3.40615 13.5659 3.2871 13.4734 3.19336ZM10.3334 5.66669H1.00005C0.868435 5.66735 0.739958 5.70695 0.630811 5.7805C0.521664 5.85406 0.436732 5.95828 0.386719 6.08002C0.335666 6.20143 0.321718 6.33524 0.346634 6.46456C0.37155 6.59389 0.434214 6.71294 0.526719 6.80669L3.19339 9.47335C3.25536 9.53584 3.32909 9.58544 3.41033 9.61928C3.49157 9.65313 3.57871 9.67055 3.66672 9.67055C3.75473 9.67055 3.84186 9.65313 3.9231 9.61928C4.00434 9.58544 4.07808 9.53584 4.14005 9.47335C4.20254 9.41138 4.25213 9.33765 4.28598 9.25641C4.31983 9.17517 4.33725 9.08803 4.33725 9.00002C4.33725 8.91201 4.31983 8.82488 4.28598 8.74364C4.25213 8.6624 4.20254 8.58866 4.14005 8.52669L2.60672 7.00002H10.3334C10.5102 7.00002 10.6798 6.92978 10.8048 6.80476C10.9298 6.67974 11.0001 6.51017 11.0001 6.33335C11.0001 6.15654 10.9298 5.98698 10.8048 5.86195C10.6798 5.73693 10.5102 5.66669 10.3334 5.66669Z"
        fill="#585858"
        className={classname}
      />
    </svg>
  );
};

export default ChangeIcon;
