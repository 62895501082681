import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";

interface CompletionSectionProps {
  availability: KpiLabelValuesProps;
  performance: KpiLabelValuesProps;
  quality: KpiLabelValuesProps;
  currentUpm: KpiUpmLabelValuesProps;
  targetUpm: KpiUpmLabelValuesProps;
}
interface KpiLabelValuesProps {
  color_code: string;
  label: string;
  value: string;
}
interface KpiUpmLabelValuesProps {
  color_code: string;
  label: string;
  value: string;
}

const CompletionSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const { availability, performance, quality, currentUpm, targetUpm } = props;
  return (
    <div className="aoh-container justify-content-between">
      <div className="aoh-completion aoh-row aoh-mt-0 aoh-mb-3 aoh-pb-3">
        <div className="aoh-col-6 aoh-pl-2 aoh-col-lg-4 aoh-col-sm-4">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {availability?.label ?? "availability"}
          </div>
          <div
            className={
              (availability?.color_code === "RED"
                ? "aoh-target-content-red"
                : availability?.color_code === "GREEN"
                ? "aoh-target-content-green"
                : availability?.color_code === "YELLOW"
                ? "aoh-target-content-yellow"
                : "") +
              " aoh-target-content ops-fs-5 aoh-text-uppercase ops-fw-semibold"
            }
          >
            {availability?.value ?? "N/A"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-4 aoh-col-sm-4">
          <div className="aoh-target-predict-heading align-items-center aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {performance?.label ?? "performance"}
          </div>
          <div
            className={
              (performance?.color_code === "RED"
                ? "aoh-performance-content-red"
                : performance?.color_code === "GREEN"
                ? "aoh-performance-content-green"
                : performance?.color_code === "YELLOW"
                ? "aoh-performance-content-yellow"
                : "") +
              " " +
              " aoh-performance-content ops-fs-5 aoh-text-uppercase ops-fw-semibold"
            }
          >
            {performance?.value ?? "N/A"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-4 aoh-col-sm-4">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {quality?.label ?? "quality"}
          </div>
          <div
            className={
              (quality?.color_code === "RED"
                ? "aoh-quality-content-red"
                : quality?.color_code === "GREEN"
                ? "aoh-quality-content-green"
                : quality?.color_code === "YELLOW"
                ? "aoh-quality-content-yellow"
                : "") +
              " aoh-w-100 aoh-quality-content aoh-text-uppercase ops-fw-semibold ops-fs-5"
            }
          >
            {quality?.value ?? "N/A"}
          </div>
        </div>
      </div>
      <div className="aoh-upm aoh-row aoh-mt-2 aoh-mb-2">
        <div className="aoh-col-6 aoh-pl-2 aoh-col-lg-4 aoh-col-sm-4">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {currentUpm?.label ?? "current upm"}
          </div>
          <div className="aoh-current-upm ops-fs-5 ops-fw-semibold">
            <span
              className={
                currentUpm?.color_code === "RED"
                  ? "aoh-actual-target-red"
                  : currentUpm?.color_code === "GREEN"
                  ? "aoh-actual-target-green"
                  : currentUpm?.color_code === "YELLOW"
                  ? "aoh-actual-target-yellow"
                  : ""
              }
            >
              {currentUpm?.value ?? "N/A"}
            </span>
            <span className="ops-fw-medium ops-fw-semibold"> {"packs"}</span>
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-4 aoh-col-sm-4">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {targetUpm?.label ?? "target upm"}
          </div>
          <div className="aoh-w-100 aoh-predicted-content ops-fw-semibold ops-fs-5">
            <span
              className={
                targetUpm?.color_code === "RED"
                  ? "aoh-predicted-target-red"
                  : targetUpm?.color_code === "GREEN"
                  ? "aoh-predicted-target-green"
                  : targetUpm?.color_code === "YELLOW"
                  ? "aoh-predicted-target-yellow"
                  : ""
              }
            >
              {targetUpm?.value ?? "N/A"}
            </span>
            <span className="ops-fw-medium ops-fw-semibold"> {"packs"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompletionSection;
