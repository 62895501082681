
      import API from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../../node_modules/source-map-loader/dist/cjs.js!./Header.module.css";
      
      

var options = {"attributes":{"class":"amgen-dct-aoh"}};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../../../node_modules/source-map-loader/dist/cjs.js!./Header.module.css";
       export default content && content.locals ? content.locals : undefined;
