import { useEffect, useRef, useState } from "react";
import "./index.scss";

interface percentDetail {
  percentage: number;
  flag: string;
}
interface ProductivityType {
  percentageArray: percentDetail[];
}
const ProgressbarIndicator: React.FC<ProductivityType> = ({
  percentageArray,
}) => {
  const [boxWidth, setBoxWidth] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentWidth = containerRef.current?.clientWidth;
    setBoxWidth((prev) => currentWidth ?? prev);
  }, [containerRef]);

  return (
    <div ref={containerRef} className="progress-container">
      {percentageArray.map((data: percentDetail, i: number) => (
        <div
          key={i}
          className={`${
            percentageArray?.length === i + 1
              ? "second-child-div-border"
              : i === 0
              ? "first-child-div-border"
              : "child-div"
          }`}
          style={{
            width: `${data.percentage}%`,
            backgroundColor: `${
              data.flag === "GREEN"
                ? "#008533"
                : data.flag === "RED"
                ? "#D62728"
                : data.flag === "BLUE"
                ? "#0063C3"
                : "#9C9C9C"
            }`,
          }}
        />
      ))}
    </div>
  );
};
export default ProgressbarIndicator;
