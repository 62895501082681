import { useEffect, useState } from "react";

export function useWindowSizeListener (callback?: () => void) {
  const [screenType, setScreenType] = useState<string>("deskto");

  useEffect(() => {
    const handleResize = () => {
      const mobileflag = window.innerWidth < 768;
      const tabflag = window.innerWidth < 992;

      if (mobileflag) {
       setScreenType("mobile");
      } else if (tabflag) {
       
        setScreenType("tab");
      } else  {
  
        setScreenType("desktop");
      }

    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });
 
  return screenType;
}
//600 - ipad

export interface screenType {
  isMobile: boolean;
  isTab: boolean;
  isDesktop: boolean;
}
