import "./index.scss"

interface PropsTypes {
    errorMessage: string,
}
const ErrorPage = (props: PropsTypes) => {
    const {errorMessage} = props;
    return (
        <div className="aoh-error-message-text">{errorMessage}</div>
    )
};
export default ErrorPage;
