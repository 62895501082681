// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorCodedTable_customTable__G58at table {
    padding-left: 14px !important;
}

.ColorCodedTable_customTable__G58at .ant-table-tbody >tr >td {
    padding: 1px;
    height: 0px;
}

.ColorCodedTable_customTable__G58at .ant-table-thead >tr>th {
    padding: 10px;
    padding-left: 1px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/table/ColorCodedTable.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,4BAA4B;AAChC","sourcesContent":[".customTable :global(table) {\n    padding-left: 14px !important;\n}\n\n.customTable :global(.ant-table-tbody >tr >td) {\n    padding: 1px;\n    height: 0px;\n}\n\n.customTable :global(.ant-table-thead >tr>th) {\n    padding: 10px;\n    padding-left: 1px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTable": `ColorCodedTable_customTable__G58at`
};
export default ___CSS_LOADER_EXPORT___;
