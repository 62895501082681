const aohLogo = () => {
    return (
        <svg width="102" height="42" viewBox="0 0 102 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="101" height="41" rx="4.5" fill="#0063C3" />
            <rect x="0.5" y="0.5" width="101" height="41" rx="4.5" stroke="#0063C3" />
            <path d="M22 29.8995L26.9497 24.9497C29.6834 22.2161 29.6834 17.7839 26.9497 15.0503C24.2161 12.3166 19.7839 12.3166 17.0503 15.0503C14.3166 17.7839 14.3166 22.2161 17.0503 24.9497L22 29.8995ZM22 32.7279L15.636 26.364C12.1213 22.8492 12.1213 17.1508 15.636 13.636C19.1508 10.1213 24.8492 10.1213 28.364 13.636C31.8787 17.1508 31.8787 22.8492 28.364 26.364L22 32.7279ZM22 22C23.1046 22 24 21.1046 24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20C20 21.1046 20.8954 22 22 22ZM22 24C19.7909 24 18 22.2091 18 20C18 17.7909 19.7909 16 22 16C24.2091 16 26 17.7909 26 20C26 22.2091 24.2091 24 22 24Z" fill="white" />
            <path d="M42.9631 30H39.5881L45.733 12.5455H49.6364L55.7898 30H52.4148L47.7528 16.125H47.6165L42.9631 30ZM43.0739 23.1562H52.2784V25.696H43.0739V23.1562ZM72.8651 21.2727C72.8651 23.1534 72.5128 24.7642 71.8082 26.1051C71.1094 27.4403 70.1548 28.4631 68.9446 29.1733C67.7401 29.8835 66.3736 30.2386 64.8452 30.2386C63.3168 30.2386 61.9474 29.8835 60.7372 29.1733C59.5327 28.4574 58.5781 27.4318 57.8736 26.0966C57.1747 24.7557 56.8253 23.1477 56.8253 21.2727C56.8253 19.392 57.1747 17.7841 57.8736 16.4489C58.5781 15.108 59.5327 14.0824 60.7372 13.3722C61.9474 12.6619 63.3168 12.3068 64.8452 12.3068C66.3736 12.3068 67.7401 12.6619 68.9446 13.3722C70.1548 14.0824 71.1094 15.108 71.8082 16.4489C72.5128 17.7841 72.8651 19.392 72.8651 21.2727ZM69.6861 21.2727C69.6861 19.9489 69.4787 18.8324 69.0639 17.9233C68.6548 17.0085 68.0866 16.3182 67.3594 15.8523C66.6321 15.3807 65.794 15.1449 64.8452 15.1449C63.8963 15.1449 63.0582 15.3807 62.331 15.8523C61.6037 16.3182 61.0327 17.0085 60.6179 17.9233C60.2088 18.8324 60.0043 19.9489 60.0043 21.2727C60.0043 22.5966 60.2088 23.7159 60.6179 24.6307C61.0327 25.5398 61.6037 26.2301 62.331 26.7017C63.0582 27.1676 63.8963 27.4006 64.8452 27.4006C65.794 27.4006 66.6321 27.1676 67.3594 26.7017C68.0866 26.2301 68.6548 25.5398 69.0639 24.6307C69.4787 23.7159 69.6861 22.5966 69.6861 21.2727ZM75.8544 30V12.5455H79.0163V19.9347H87.1044V12.5455H90.2749V30H87.1044V22.5852H79.0163V30H75.8544Z" fill="white" />
        </svg>
    )
}

export default aohLogo
