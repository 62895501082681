import React from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import { ClockIcon } from "@gitlab-rtsensing/component-library";

interface TagsProps {
  data: string | undefined
  backgroundColor ?: string
}

const RefreshTag: React.FC<TagsProps> = (props : TagsProps) => {
    
  return (
    <div className={` aoh-mt-0 d-flex align-items-center ${props.backgroundColor?"aoh-grey-tag-details":"aoh-tag-details"}`}>   
      <div
        className="d-flex aoh-tag-data aoh-p-1 ops-fs-6 align-items-center aoh-text-uppercase ops-fw-semibold"
        onClick={() => {}}
      >
        <div>
          {
            <ClockIcon
              fill="#797979"
              height={18}
              className="aoh-tag-icon"
            />
          }
        </div>

        {"data refreshed "}
        <div className="aoh-tag-time ops-fs-6 align-items-center aoh-text-uppercase ops-fw-semibold">
          &nbsp;{props.data ?? "N/A" }
        </div>
      </div>
    </div>
  );
};

export default RefreshTag;
