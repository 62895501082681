/* eslint-disable no-debugger */
import React, { useRef, useEffect, useContext } from 'react'
import './index.scss'
import { AppContext } from '../../../context/app-context'
interface PageLayoutProps {
  children: JSX.Element,
  className?: string
}
const PageLayout: React.FC<PageLayoutProps> = ({ children, className }) => {
  const navRef = useRef<any>(null)
  const { setAppState, appState } = useContext(AppContext)
  useEffect(() => {

    const navRefCurrent = navRef.current
    if (navRefCurrent) {
      setAppState({ ...appState, navRoot: navRefCurrent })
    }
  }, [navRef?.current])
  return (
    <>
      <div style={{ overflowY: "auto" }}>
        <div id="dct-desktop-side-nav" ref={navRef}></div>
        <div id={'dct-main-content'} className={className ? `${className} aoh-mt-5` : 'aoh-layout-wrapper aoh-mt-5'}>
          {children}
        </div>
      </div>
    </>
  )
}

export default PageLayout
