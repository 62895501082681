import withModal from '../components/common/modal/ModalHoc';

interface MyComponentProps {
  openModal: () => void;
  title: string;
}

const MyComponent: React.FC<MyComponentProps> = ({ openModal, title }) => {
  return (
    <div>
      <h2>{title}</h2>
      <button onClick={openModal}>
        Open Modal
      </button>
    </div>
  );
};

export default withModal(MyComponent);