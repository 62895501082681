import { fetchApi,  shiftPayloadType } from "api/fetch-api";

export const getRefreshData= async (data:shiftPayloadType) => {
  const response = await fetchApi({
    header: {},
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/shopfloor/refresh`,
    data 
  })
 
  return response;
   
}
