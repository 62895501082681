import React from 'react';

const ProjectBookBuildingIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25575_152917)">
                <path d="M6.25 17.5C5.905 17.5 5.625 17.22 5.625 16.875C5.625 16.53 5.905 16.25 6.25 16.25H6.875V8.75C6.875 8.06083 7.43583 7.5 8.125 7.5H11.875C12.5642 7.5 13.125 8.06083 13.125 8.75V16.25H13.75C14.095 16.25 14.375 16.53 14.375 16.875C14.375 17.22 14.0942 17.5 13.75 17.5H6.25ZM11.875 16.25V13.75H10C9.655 13.75 9.375 13.47 9.375 13.125C9.375 12.78 9.655 12.5 10 12.5H11.875V11.25H10C9.655 11.25 9.375 10.97 9.375 10.625C9.375 10.28 9.655 10 10 10H11.875V8.75H8.125V16.25H11.875Z" fill="#595959" />
                <path d="M5.62501 20.0008C3.90168 20.0008 2.50001 18.5992 2.50001 16.8758V3.20417C2.49418 3.01083 2.50918 2.80583 2.54251 2.605C2.85001 1.0675 4.15001 0 5.70418 0L5.77168 0.000833333H16.875C17.2192 0.000833333 17.5 0.280833 17.5 0.625833C17.5 0.970833 17.2192 1.25083 16.875 1.25083L5.70418 1.25C4.76751 1.25 3.95501 1.915 3.77168 2.83167C3.75918 2.90917 3.75084 2.995 3.74834 3.08167C3.74918 3.09667 3.75001 3.11083 3.75001 3.12583V3.22917C3.78918 4.08833 4.44668 4.82917 5.31168 4.975C5.41418 4.9925 5.51918 5.00083 5.62334 5.00083H16.25C16.9392 5.00083 17.5 5.56167 17.5 6.25083V18.7508C17.5 19.44 16.9392 20.0008 16.25 20.0008H5.62501ZM3.75001 16.8758C3.75001 17.91 4.59084 18.7508 5.62501 18.7508H16.25V6.25083H5.62501C5.45418 6.245 5.28251 6.2375 5.10334 6.2075C4.60834 6.12333 4.14501 5.9225 3.75001 5.62583V16.8758Z" fill="#595959" />
                <path d="M5.625 3.75C5.28 3.75 5 3.47 5 3.125C5 2.78 5.28 2.5 5.625 2.5H15.625C15.97 2.5 16.25 2.78 16.25 3.125C16.25 3.47 15.9692 3.75 15.625 3.75H5.625Z" fill="#595959" />
            </g>
            <defs>
                <clipPath id="clip0_25575_152917">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ProjectBookBuildingIcon