import React from 'react';
import { Table } from 'antd';
import classes from './ColorCodedTable.module.css';

const dataSource = [
  { key: '1', name: 'Cost Center 1', value1: '10%', value2: -5, value3: 20, value4: -15 },
  { key: '2', name: 'Cost Center 2', value1: '', value2: 15, value3: -20, value4: 5 },
  { key: '3', name: 'Cost Center 3', value1: 30, value2: 25, value3: -10, value4: -5 },
  { key: '4', name: 'Cost Center 4', value1: -20, value2: -15, value3: 10, value4: 30 },
  { key: '5', name: 'Cost Center 5', value1: -20, value2: -25, value3: 10, value4: 30 }
];

const columns = [
  {
    title: 'COST CENTER',
    dataIndex: 'name',
    key: 'name',
    width: '203.5px'
  },
  {
    title: '08/09',
    dataIndex: 'value1',
    key: 'value1',
    width: '203.5px',
    render: (value: number) => (
      <div style={{
        backgroundColor: value > 0 ? '#1BA13D' : '#E01029',
        color: 'white',
        padding: '8px',
        textAlign: 'center',
        height: '100%'
      }}>
        {value}
      </div>
    ),
  },
  {
    title: '09/09',
    dataIndex: 'value2',
    key: 'value2',
    width: '203.5px',
    render: (value: number) => (
      <div style={{
        backgroundColor: value > 0 ? '#1BA13D' : '#E01029',
        color: 'white',
        padding: '8px',
        textAlign: 'center',
        height: '100%'
      }}>
        {value}
      </div>
    ),
  },
  {
    title: '10/09',
    dataIndex: 'value3',
    key: 'value3',
    width: '203.5px',
    render: (value: number) => (
      <div style={{
        backgroundColor: value > 0 ? '#1BA13D' : '#E01029',
        color: 'white',
        padding: '8px',
        textAlign: 'center',
        height: '100%'
      }}>
        {value}
      </div>
    ),
  },
  {
    title: '11/09',
    dataIndex: 'value4',
    key: 'value4',
    width: '203.5px',
    render: (value: number) => (
      <div style={{
        backgroundColor: value > 0 ? '#1BA13D' : '#E01029',
        color: 'white',
        padding: '8px',
        textAlign: 'center',
        height: '100%'
      }}>
        {value}
      </div>
    ),
  },
  {
    title: '12/09',
    dataIndex: 'value4',
    key: 'value4',
    width: '203.5px',
    render: (value: number) => (
      <div style={{
        backgroundColor: value > 0 ? '#1BA13D' : '#E01029',
        color: 'white',
        padding: '8px',
        textAlign: 'center',
        height: '100%'
      }}>
        {value}
      </div>
    ),
  }
];

const ColorCodedTable = () => {
  return <Table 
    className={classes.customTable}
    dataSource={dataSource} 
    columns={columns} 
    pagination={false} 
  />;
};

export default ColorCodedTable;
