import headerClass from "./Header.module.css"
import WCT from "../../../../assets/icons/work_center_team.png"
import AOH from "../../../../assets/icons/aoh-logo"
import type { MenuProps } from 'antd';
import AohDropdown from "components/common/dropdown/aohdropdown";

interface HeaderType {
  items: MenuProps['items']
  handleMenuClick?: MenuProps['onClick']
  title: string
  dropOptions?: string
}

const Header: React.FC<HeaderType> = ({ items, handleMenuClick, title ,dropOptions}) => {

  const { headerContainer, wctLogo, dateText, coContainer, subContainer, aohLogo} = headerClass
  const date = new Date()
  const day = date.toLocaleDateString('en-US', { weekday: 'long' })
  const month = date.toLocaleDateString('en-US', { month: 'long' })
  return (
    <div className={headerContainer} >
      <div className={coContainer}>
        <div className={aohLogo}>
        <AOH />
        </div>
        <img className={wctLogo} src={WCT} alt="WCT" />
      </div>
      <div className={subContainer}>
          <AohDropdown items={items} handleMenuClick={handleMenuClick} title={title} dropOptions={dropOptions}/>
        <span className={dateText}>
        {`${day} ${month} ${date.getDate()}, ${date.getFullYear()}`}
        </span>
      </div>
    </div>
  )
}

export default Header;
