import React from "react";
import "./index.scss";
import "../../../../../assets/bootstrap.scss";

const StageStatusSection: React.FC = () => {

  return (
    <>
      <div className="aoh-stage-status-wrapper aoh-row aoh-mt-2 aoh-mb-3">
        <div className="">
            <div className="aoh-stage-header aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
                {"stage"}
            </div>
            <div className="d-flex ops-fs-4 aoh-mt-1 aoh-setup-down-container ops-fw-bold">
                <div className="align-items-center aoh-status-production-text">{"Non-Commercial Activity"}</div>
            </div>
        </div>
      </div>
    </>
  );
};
export default StageStatusSection;
