// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_headerContainer__v8C9I {
    margin-top: 120px;
    border: 1px solid #D9D9D9;
    width: 1070px;
    height: 52px;
    border-radius: 10px;
    padding: 5px 20px 5px 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #c2c0c080;

}

.Header_aohLogo__pcAyi{
    margin-top: 7px;
}

.Header_coContainer__weUeS{
    display: flex;
    justify-content: end;
    align-items: center;
}

.Header_subContainer__ef1Dw{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 28px;
    gap: 12px;
    margin-top: 6px;
}

.Header_wctLogo__ZvCPj{
    width: 235px;
    height: 45px;
    padding: 5px 0px 5px 0px;
    margin-left: 20px;
}


.Header_dateText__zmpaX{
    font-size: 12px;
}

`, "",{"version":3,"sources":["webpack://./src/page/aoh/wct/header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,sCAAsC;;AAE1C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,wBAAwB;IACxB,iBAAiB;AACrB;;;AAGA;IACI,eAAe;AACnB","sourcesContent":[".headerContainer {\n    margin-top: 120px;\n    border: 1px solid #D9D9D9;\n    width: 1070px;\n    height: 52px;\n    border-radius: 10px;\n    padding: 5px 20px 5px 5px;\n    display: flex;\n    justify-content: space-between;\n    background-color: white;\n    box-shadow: 0px 0px 10px 0px #c2c0c080;\n\n}\n\n.aohLogo{\n    margin-top: 7px;\n}\n\n.coContainer{\n    display: flex;\n    justify-content: end;\n    align-items: center;\n}\n\n.subContainer{\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    height: 28px;\n    gap: 12px;\n    margin-top: 6px;\n}\n\n.wctLogo{\n    width: 235px;\n    height: 45px;\n    padding: 5px 0px 5px 0px;\n    margin-left: 20px;\n}\n\n\n.dateText{\n    font-size: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `Header_headerContainer__v8C9I`,
	"aohLogo": `Header_aohLogo__pcAyi`,
	"coContainer": `Header_coContainer__weUeS`,
	"subContainer": `Header_subContainer__ef1Dw`,
	"wctLogo": `Header_wctLogo__ZvCPj`,
	"dateText": `Header_dateText__zmpaX`
};
export default ___CSS_LOADER_EXPORT___;
