import React from "react";
import "./index.scss";
import "../../../../../../assets/bootstrap.scss";
import Title from "components/shop-floor-tab/batch-info-header/title";

interface CompletionSectionProps {
  actual: string;
  comparisionColorCode: string;
  predictedCompletion: string;
  target: string;
  targetCompletion: string;
  unitsPlanned: string;
  unitsProduced: string;
}

const ShiftSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    actual,
    comparisionColorCode,
    predictedCompletion,
    target,
    targetCompletion,
    unitsPlanned,
    unitsProduced,
  } = props;
  const status = true;
  return (
    <>
      <div className="aoh-d-flex aoh-sm-row aoh-md-row aoh-lg-row aoh-pt-0 aoh-mt-0 aoh-align-items-center aoh-justify-content-between">
        <div className="aoh-d-flex aoh-batch-title aoh-col-md-6">
          <Title
            title={"Shift Lorem"}
            height={"ops-fs-4"}
            weight={"ops-fw-semibold"}
          />
        </div>

        {!status && (
          <div className="aoh-mt-sm-0 aoh-col-md-5 aoh-title-status-complete ops-fs-6 aoh-mt-md-0">
            <div>{"COMPLETE"}</div>
          </div>
        )}
        {status && (
          <div className="aoh-mt-sm-0 aoh-col-md-5 aoh-title-status-progress ops-fs-6 aoh-mt-md-0">
            <div>{"IN PROGRESS"}</div>
          </div>
        )}
      </div>
      <div className="aoh-batch-section aoh-row aoh-mt-2 aoh-mb-1">
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"START"}
          </div>
          <div className="aoh-starttime-content ops-fs-6 ops-fw-semibold">
            {"6/6/23 01:00AM PST" ?? "N/A"}
          </div>
        </div>
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-target-predict-heading ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"END"}
          </div>
          <div className="aoh-w-100 aoh-endtime-content ops-fw-semibold ops-fs-6">
            {"7/6/23 11:00PM PST" ?? "N/A"}
          </div>
        </div>
      </div>
      <div className="aoh-batch-section aoh-row aoh-mt-1 aoh-pb-3">
        <div className="aoh-col-lg-12">
          <div className="aoh-target-predict-heading aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"MANAGER"}
          </div>
          <div className="aoh-material-content ops-fs-6 ops-fw-semibold">
            {"JANE D." ?? "N/A"}
          </div>
        </div>
      </div>
    </>
  );
};
export default ShiftSection;
