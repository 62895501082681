import { fetchApi, payloadType } from "api/fetch-api";

export const getMachineStatusContent = async (data: payloadType) => {
const response = await fetchApi({
  header: {},
  method: "POST",
  url: `${process.env.REACT_APP_API_URL}/shopfloor/current_machine_status`,
  data,
});
  return response;
   
}
