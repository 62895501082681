import "./index.scss"

interface RouteErrorProps {
    routeerror : string;
}
const RouteErrorMessage = (props:RouteErrorProps) => {
    return (
        <main className=" aoh-d-flex aoh-w-100 aoh-route-error">
            <>
                <div
                    id="tv-left"
                    className=" aoh-d-flex aoh-justify-content-center aoh-w-100 aoh-h-100 aoh-align-items-center">
                        <div className="ops-fs-3 ops-fw-semibold">
                            {props.routeerror}
                        </div>
                </div>
            </>
        </main>
    
    );
};

export default RouteErrorMessage;