import React from "react";
import "./index.scss";
import "../../../../assets/bootstrap.scss";
import CountdownTimer from "components/resuable/countdowntimer/countdown-timer";

interface ArticleProps {
    totalCard: number;
    index: number;
    key: number;
    huNumberFinal: string;
    remainingTime: string;
}

const ArticleCard: React.FC<ArticleProps> = (props: ArticleProps) => {
    const {
        totalCard,
        index,
        huNumberFinal,
        remainingTime
    } = props;

    const remainingTimeChecker = (time: number) => {
        const totalMinutes = time / 60;
        if (totalMinutes > 120) {
            return 'aoh-text-green';
        } else if (totalMinutes >= 15 && totalMinutes < 120) {
            return 'aoh-text-orange';
        }
        return 'aoh-text-red';
    };
    const hourToMilliseconds = (time: string) => {
        const second = time.split(":")
        const result = (+second[0] * (60000 * 60) + (+second[1] * 60000))
        return result
    }

    const remainingTimeC = hourToMilliseconds(remainingTime) / 1000;
    if (remainingTimeC === 0) return null;
    return (
        <div key={index} className="aoh-article-card-borderless">
            <div className="article-details">
                <div className="aoh-row d-flex aoh-mt-3">
                    <div className="ops-fs-5 ops-fw-semibold aoh-col-lg-5 aoh-col-sm-5 aoh-col-4">
                        HU {huNumberFinal}
                    </div>
                    <div className={`ops-fs-5 ops-fw-semibold aoh-col-lg-7 aoh-col-md-7 aoh-col-sm-7 aoh-pl-5  aoh-col-4 ${remainingTimeChecker(remainingTimeC)}`}>
                    <CountdownTimer key={index} startTime={remainingTimeC} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
