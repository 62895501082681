import "./index.scss";
import "../../../../../../../assets/bootstrap.scss";
import React from "react";
import MetricCardContent from "./metric-card-content";
import StatusTimeHeader from "./metric-header";

import {
  getAgrregratedPercentage,
  getAgrregratedTimevalue,
} from "utils/common-methods";

interface TimeStatusTableProps {
  timeStatusTableData: any;
  subgraphStatus: any;
}

const TimeStatusTable: React.FC<TimeStatusTableProps> = (
  props: TimeStatusTableProps
) => {
  const { timeStatusTableData, subgraphStatus } = props;
  const statusArray = [
    {
      status: "Down",
      percentage: getAgrregratedPercentage("down",timeStatusTableData),
      time: getAgrregratedTimevalue("down",timeStatusTableData),
    },
    {
      status: "Idle",
      percentage: getAgrregratedPercentage("idle",timeStatusTableData),
      time: getAgrregratedTimevalue("idle",timeStatusTableData),
    },
    {
      status: "Running",
      percentage: getAgrregratedPercentage("running",timeStatusTableData),
      time: getAgrregratedTimevalue("running",timeStatusTableData),
    },
  ];
  const subgraphAggregate = [
    {
      status: subgraphStatus?.length > 0 ? subgraphStatus[0].status: 'N/A',
      percentage: getAgrregratedPercentage("subgraph",subgraphStatus),
      time: getAgrregratedTimevalue("subgraph", subgraphStatus)
    }
  ]

  return (
    <>
      <div className="aoh-status-time-card aoh-w-100">
        <StatusTimeHeader />

        {statusArray?.map(
          (
            el: any,
            i: React.Key | null | undefined
          ) => {
            return (
              (el.percentage!==0 && <MetricCardContent
                statusValue={el.status}
                timePercentage={el.percentage.toFixed(2).toString()}
                totalTime={el.time}
                key={i}
              />)
            );
          }
        )}
        <div className="aoh-w-100 aoh-divider aoh-mt-1 aoh-mb-1" />
        {subgraphAggregate?.map(
          (
            el: any,
            i: React.Key | null | undefined
          ) => {
            return (
              (el.percentage!==0 && <MetricCardContent
                statusValue={el.status}
                timePercentage={el.percentage.toFixed(2).toString()}
                totalTime={el.time}
                key={i}
              />)
            );
          }
        )}
      </div>
    </>
  );
};

export default TimeStatusTable;
