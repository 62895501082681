/* eslint-disable react/no-is-mounted */
interface Options {
  url: string;
  siteId: number;
  pathBasename: string;
  enableLinkTracking?: boolean;
  trackDocumentTitle?: boolean | string;
  jsFilename?: string;
  phpFilename?: string;
}

export class AmgenMatamoTracker {
  options;
  previousPath: any;
  unListenFromHistory: (() => void) | undefined;
  track: ((arg0: any) => any) | undefined;

  constructor (opts: Options) {
    const options = opts;
    options.enableLinkTracking =
      options.enableLinkTracking !== undefined
        ? options.enableLinkTracking
        : true;
    options.trackDocumentTitle =
      options.trackDocumentTitle !== undefined
        ? String(options.trackDocumentTitle) + '-Landing'
        : true;
    options.jsFilename = options.jsFilename ?? 'piwik.js';
    options.phpFilename = options.phpFilename ?? 'piwik.php';

    this.options = options;

    if (this.options.url === undefined || this.options.siteId === undefined) {
      throw new Error(
        'AmgenMatamoTrackerTracker cannot be initialized! SiteId and url are mandatory.',
      );
    }

    this.initMatamo();
  }

  initMatamo () {
    //initialize AmgenMatamoTracker on each page
    if (typeof window !== 'undefined') {
      let url = this.options.url;

      if (url.includes('http://') || url.includes('https://')) {
        url = `${url}/`;
      } else {
        url =
          document.location.protocol === 'https:'
            ? `https://${url}/`
            : `http://${url}/`;
      }

      (window as any)._paq = (window as any)._paq || []; // eslint-disable-line  no-underscore-dangle
      const OktaLS = JSON.parse(
        localStorage.getItem('dct-okta-token')
          ? (localStorage.getItem('dct-okta-token') as string)
          : '{}',
      );

      const userId = OktaLS
        ? OktaLS?.idToken?.claims
          ? OktaLS?.idToken?.claims.email
          : undefined
        : undefined;

      if (userId) {
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        this.push(['setLinkClasses', 'download-button']);
        this.push(['setUserId', userId]);
        // this.push(['setFunctionGroup', functionGroup]);
        this.push(['trackPageView']);

        this.push(['setSiteId', this.options.siteId]);
        this.push([
          'setTrackerUrl',
          `${url + String(this.options.phpFilename)}`,
        ]);

        if (this.options.enableLinkTracking) {
          this.push(['enableLinkTracking']);
        }

        const scriptElement = document.createElement('script');
        const refElement = document.getElementsByTagName('script')[0];

        scriptElement.type = 'text/javascript';
        scriptElement.defer = true;
        scriptElement.async = true;

        let jsFilename = this.options.jsFilename ? this.options.jsFilename : '';

        if (
          jsFilename.indexOf('http://') !== 0 &&
          jsFilename.indexOf('https://') !== 0
        ) {
          jsFilename = url + jsFilename;
        }

        scriptElement.src = jsFilename;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        refElement.parentNode
          ? refElement.parentNode.insertBefore(scriptElement, refElement)
          : null;
      }
    }

    return {
      push: this.push,
      track: this.track,
      connectToHistory: this.connectToHistory,
      disconnectFromHistory: this.disconnectFromHistory,
    };
  }

  push (args: any[]) {
    return (window as any)._paq.push(args);
  }

  parseJwt (token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  connectToHistory (history: {
    getCurrentLocation: () => any;
    location: any;
    listen: (arg0: (loc: any) => void) => any;
  }) {
    const prevLoc =
      typeof history.getCurrentLocation === 'undefined'
        ? history.location
        : history.getCurrentLocation();
    this.previousPath =
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      prevLoc.path || (prevLoc.pathname + prevLoc.search).replace(/^\//, '');
    this.unListenFromHistory = history.listen(loc => {
      typeof this.track === 'function' && this.track(loc);
    });

    return history;
  }

  getBasePath () {
    if (typeof this.options.pathBasename !== 'undefined') {
      return `${window.location.origin}/${this.options.pathBasename}/`;
    }
    return `${window.location.origin}/`;
  }

  disconnectFromHistory () {
    if (this.unListenFromHistory) {
      this.unListenFromHistory();

      return true;
    }

    return false;
  }
}
