import React, { useContext } from "react";
import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import Tag from "components/resuable/tag";
import { AppContext } from "context/app-context";
import StatusLogo from "components/resuable/status-logo";

interface StageStatusSectionProps {
  data : any
}
const StageStatusSection: React.FC<StageStatusSectionProps> = ({data}:StageStatusSectionProps) => {

  const { appState } = useContext(AppContext);
  const isStatusRunning = (data.status=== "Running" && appState.phase === "Setup");
  
  return (
    <>
      <div className="aoh-stage-status-wrapper aoh-row aoh-mt-2 aoh-mb-3">
        <div className="aoh-col-6 aoh-pl-3 aoh-col-lg-6">
          <div className="aoh-stage-header aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-0">
            {"stage"}
          </div>
          {isStatusRunning ? (
            <div className="d-flex ops-fs-4 aoh-setup-tag-container ops-fw-bold">
              <div className="aoh-status-setup-text">{"Setup"}</div>
              <div className="aoh-status-tag aoh-pl-2 d-flex">
                <Tag name={""} canClick={false} />
              </div>
            </div>
          ) : (
            <div className="d-flex ops-fs-4 aoh-mt-2 aoh-setup-down-container ops-fw-bold">
              <div className="align-items-center aoh-status-production-text">{data.curr_stage ?? "N/A"}</div>
            </div>
          )}
        </div>
        <div className="aoh-col-6 aoh-col-lg-6">
          <div className="aoh-stage-header aoh-text-uppercase ops-fs-6 ops-fw-semibold aoh-mb-2">
            {"status"}
          </div>
          <div className="d-flex aoh-status-content aoh-text-uppercase ops-fw-semibold ops-fs-5">
            
              <div className={`d-flex align-items-center aoh-setup-status-${data?.status_color_code?.toLowerCase()} text-uppercase aoh-mr-2 aoh-pl-1 aoh-pr-2 ops-fs-6 ops-fw-semibold ops-font-arial`}>
                <div className="aoh-d-flex aoh-status-icon aoh-mt-1 aoh-pr-1 align-items-center">
                 <StatusLogo color={data?.status_color_code?.toLowerCase()} size={16} className="ops-px-1 " alertClassName="aph-pb-1"/>
                </div>
                {data.status_msg ?? "N/A"}
              </div>
        
          </div>
        </div>
      </div>
    </>
  );
};
export default StageStatusSection;
