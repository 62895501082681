import React, { useState } from "react";
import "./index.scss";
import "../../../../../../assets/bootstrap.scss";
import moment from "moment";
import HourMachineStatusBar from "./hour-machine-status-bar";
import LabelStatusSection from "./machine-label-status";
import TimeStatusTable from "./time-and-status-table";
import MachineAlarmsBarTable from "./machine-alarms-bar-table";
import HourMachineStatusFloatBar from "./hour-machine-status-float-bar";
import APIErrorMessage from "utils/api-error";
interface MetricProps {
  alarmStatusData: any;
  idMachineList: React.Key | null | undefined;
}

const AlarmsStatusContent: React.FC<MetricProps> = (props: MetricProps) => {
  const { alarmStatusData } = props;
  const [isVisible, setIsvisible] = useState(false);
  const [tooltipState, setTooltipState] = useState({});

  const toggleVisibilityFn = () => {
    setIsvisible((isVisible) => !isVisible);
  };
  const tooltipToggleFn = (tooltipStateValue: any) => {
    setTooltipState(tooltipStateValue);
  };

  const startTime = moment(
    alarmStatusData?.graph_data[0].start_time,
    "MM/DD/YYYY h:mm A"
  ).format("h A");
  const midTime = moment(
    alarmStatusData?.graph_data[0].start_time,
    "MM/DD/YYYY h:mm A"
  )
    .add(12, "hours")
    .format("h A");
  const endTime = moment(
    alarmStatusData?.graph_data[0].start_time,
    "MM/DD/YYYY h:mm A"
  )
    .add(1, "days")
    .format("M/D/YYYY h A");

  return (
    <div
      className={
        "aoh-row aoh-alarms-status-container justify-content-center aoh-w-100"
      }
    >
      <div className="aoh-machine-label-status aoh-pt-1">
        <LabelStatusSection
          machine={alarmStatusData.machine}
          statusValue={alarmStatusData.status}
          statusColorCode={alarmStatusData.status_color_code}
          activeAlarm={alarmStatusData.active_alarms}
          visibleState={isVisible}
          toggleVisibility={toggleVisibilityFn}
        />
      </div>

      <div className="aoh-row">
        <div className="aoh-d-flex aoh-alarms-status-bar aoh-mb-0 aoh-pl-3">
          <div className="aoh-v-line-start"></div>
          <div style={{ position: "relative" }} className="aoh-w-100">
            <div id="aoh-data-anchor">
              <HourMachineStatusBar
                graphArray={alarmStatusData?.graph_data}
                idMachineList={props.idMachineList}
                tooltipToggle={tooltipToggleFn}
              />
            </div>

            <div className="aoh-w-100 aoh-x-axis d-flex">
              <div className="aoh-x-axis-tick "></div>
              <div className="aoh-x-axis-tick "></div>
              <div className="aoh-x-axis-tick"></div>
              <div className="aoh-x-axis-tick"></div>
              <div className="aoh-x-axis-tick"></div>
            </div>
            {alarmStatusData?.batch_change_info?.map((item:any, i:number) => (<div key={i}
              id="marker"
              style={{
                background: "#2F2F2F",
                width: "1px",
                height: "28px",
                position: "absolute",
                left: `${item?.batch_percentage}%`,
                top: "0px",
              }}
            />))}
          </div>

          <div className="aoh-v-line-end"></div>
        </div>
        {alarmStatusData?.sub_graph_data.length > 0 && (
          <div
            id={"aoh-float-data-anchor"}
            className="aoh-height aoh-w-100 aoh-d-flex"
          >
            <HourMachineStatusFloatBar
              floatBarGraphData={alarmStatusData?.sub_graph_data}
              idMachineList={props.idMachineList}
            />
          </div>
        )}

        <div className="aoh-w-100 aoh-mt-1 aoh-mb-1 aoh-x-axis-label-container aoh-ml-0 aoh-mr-1 aoh-d-flex">
          <div className="aoh-x-axis-label aoh-pr-2 ops-fs-6 aoh-text-uppercase ops-fw-semibold">
            {startTime ?? "N/A"}
          </div>
          <div className="aoh-x-axis-label aoh-pl-5 ops-fs-6 aoh-text-uppercase ops-fw-semibold">
            {midTime ?? "N/A"}
          </div>
          <div className="aoh-d-flex aoh-pr-0 aoh-x-axis-label ops-fs-6 aoh-text-uppercase ops-fw-semibold">
            {endTime ?? "N/A"}
          </div>
        </div>
      </div>

      {
        <div
          className={`aoh-content-container ${
            isVisible ? "aoh-table-visible" : "aoh-table-hidden"
          }`}
        >
          <TimeStatusTable subgraphStatus={alarmStatusData?.sub_graph_data} timeStatusTableData={alarmStatusData.graph_data} />
          {alarmStatusData?.alarm_graph?.length !== 0 && (
            <div className="ops-fs-6 aoh-mt-2 aoh-mb-2 ops-fw-semibold aoh-top-3-alarms-header">
              TOP 3 ALARMS BY DURATION
            </div>
          )}
          {alarmStatusData?.alarm_graph.length > 0 ? (
            <div>
              {alarmStatusData?.alarm_graph?.map(
                (el: any, i: React.Key | null | undefined) => {
                  return (
                    <MachineAlarmsBarTable
                      key={i}
                      idMachineList={props.idMachineList}
                      idAlarmGraph={i}
                      alarmData={el}
                    />
                  );
                }
              )}
            </div>
          ) : (
            <div>
              <APIErrorMessage apierror={"No Alarms By Duration data Available"} />
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default AlarmsStatusContent;
