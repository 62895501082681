import React from "react";
import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import Title from "components/shop-floor-tab/batch-info-header/title";

interface CompletionSectionProps {
  actual: string;
  comparisionColorCode: string;
  predictedCompletion: string;
  target: string;
  targetCompletion: string;
  unitsPlanned: string;
  unitsProduced: string;
}

const ThroughputSection: React.FC<CompletionSectionProps> = (
  props: CompletionSectionProps
) => {
  const {
    actual,
    comparisionColorCode,
    predictedCompletion,
    target,
    targetCompletion,
    unitsPlanned,
    unitsProduced,
  } = props;

  return (
    <>
      <div className="aoh-d-flex aoh-sm-row aoh-md-row aoh-throughput-content-container aoh-justify-content-between">
        <div className="aoh-pb-0 aoh-avg-target-title">
          <Title
            title="Batch Average vs Target"
            height={"ops-fs-5"}
            weight={"ops-fw-semibold"}
          />
        </div>
      </div>
      <div className="aoh-throughput-content aoh-row aoh-mt-2 aoh-mb-1">
        <div className="aoh-col-12 aoh-col-lg-12">
          <div className="aoh-throughput-value-heading ops-fs-6 ops-fw-semibold aoh-mb-1">
            {"THROUGHPUT (UPM)"}
          </div>
          <div className="aoh-throughput-content-value ops-fs-5 ops-fw-semibold">
            {"85%" ?? "N/A"}
            <span
              className={
                (comparisionColorCode === "RED"
                  ? "aoh-actual-target-red"
                  : comparisionColorCode === "GREEN"
                  ? "aoh-actual-target-green"
                  : "") +
                " " +
                "aoh-pl-1"
              }
            >
              {"(-2%)" ?? "N/A"}
            </span>
          </div>
        </div>
        
      </div>
      
    </>
  );
};
export default ThroughputSection;
