import React from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";

import VectorIcon from "assets/icons/vector-icon";

interface DownProps {
  selectedDirection: boolean;
}

const VectorDropdownIcon: React.FC<DownProps> = (props: DownProps) => {
  const { selectedDirection } = props;
  return (
    <>
      {selectedDirection && (
        <span className=" aoh-vector-rotate-icon">
          <VectorIcon fill={"#9C9C9C"} />
        </span>
      )}
      {!selectedDirection  && (
        <span className="d-flex aoh-mb-1">
          <VectorIcon fill={"#9C9C9C"} />
        </span>
      )}
    </>
  );
};

export default VectorDropdownIcon;
