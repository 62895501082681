import React from "react";
import "./index.scss";
import "../../../../../../../../assets/bootstrap.scss";

const StatusTimeHeader: React.FC = () => {
  return (
    <>
      <div className=" aoh-metric-header aoh-row aoh-mb-2 aoh-pr-1 aoh-mt-1 ops-fs-6 ops-fw-semibold ops-lh-4 d-flex flex-grow-1 justify-content-between">
      <div className="aoh-col-4 aoh-col-sm-4 aoh-col-lg-4 aoh-machine-header-content">
          <div>{"STATUS"}</div><span className="aoh-alarm-content">{""}</span>
        </div>
        <div className="aoh-col-4 aoh-col-sm-4 aoh-col-lg-3 aoh-total-header-content">
          <span>{"TIME%"}</span><span className="aoh-alarm-content">{""}</span>
        </div>
        <div className="aoh-col-4 aoh-col-sm-4 aoh-col-lg-5 aoh-total-header-content">
        {"TOTAL TIME"}<br></br><span className="aoh-alarm-content">{""}</span>
        </div>
      
      </div>
    </>
  );
};

export default StatusTimeHeader;
