import React from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import ChangeoverIcon from "assets/icons/changeover-icon";
interface TagsProps {
  name: any;
  canClick: boolean;
}

const Tag: React.FC<TagsProps> = (props: TagsProps) => {
  const { name, canClick } = props;

  return (
    <div className={"aoh-tag ops-fs-5 aoh-mt-2 d-flex"}>
      <div
        className="d-flex aoh-tag-name ops-fs-6 ops-fw-semibold ops-fw-bold aoh-text-uppercase"
        onClick={() => {}}
      >
        <div className="aoh-changeover-icon aoh-pr-1 align-items-center">
          <ChangeoverIcon height={9} fill="#797979" />
        </div>
        <div>{"changeover"}</div>
      </div>
    </div>
   
  );
};

export default Tag;
