import moment from 'moment';
import React, { useEffect } from 'react';
import configureTimer from 'utils/timer-util';
interface TimerType {
  startTime: string;
  setIncrementalTimer: (timer: string) => void;
}
const Timer = (props: TimerType) => {
    const {startTime, setIncrementalTimer} = props;
    useEffect(() => {
        const interval = setTimeout(() => {
            const getDays =  Math.floor(parseInt(startTime?.split(':')[0]) / 24)
            const remainingHours = parseInt(startTime?.split(':')[0]) % 24
            const getMinute = parseInt(startTime?.split(':')[1])
            const getSecond = parseInt(startTime?.split(':')[2])
            const formattedTime = `${getDays}:${remainingHours}:${getMinute}:${getSecond}`
            const newIncrementalTimer =getDays === 0 ? moment(startTime, 'HH:mm:ss').add("1", 's').format('HH:mm:ss') : moment(formattedTime, 'DD:HH:mm:ss').add("1", 's').format('HH:mm:ss')
            const modifiedTimer = getDays=== 0 ? newIncrementalTimer: configureTimer(startTime, newIncrementalTimer)
            setIncrementalTimer(modifiedTimer);
        }, 1000);
        return () => clearInterval(interval);
    });

  return <>{startTime}</>;
};

export default Timer;
