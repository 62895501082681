import { Table } from 'antd';
import TableCss from './AohTable.module.css';
import {columns, data} from '../../../utils/constants'

const AohTable = () => {

  const { tableContainer } = TableCss

  const showTotal = (total: number,range: [number,number]) => `${range[0]} - ${range[1]} of ${total} items`
  return (
    <div className={tableContainer}>
      <Table columns={columns} dataSource={data} pagination={{pageSize: 5, showTotal: showTotal, showSizeChanger: false}} />
    </div>
  )
}

export default AohTable
