import "./index.scss";
import "../../../../../assets/bootstrap.scss";
import CompletionSection from "../completion-section";
import Errorhandler from "utils/error-handler";
import APIErrorMessage from "utils/api-error";
import CardComponent from "components/resuable/card";
import ThroughputSection from "../throughput-section";
import DateSection from "./date-section";

interface KpiModalTooltipDataProps {
  kpiDateData: any;
  isThroughput: Boolean;

}

const KpiDateTooltip: React.FC<KpiModalTooltipDataProps> = (
  props: KpiModalTooltipDataProps
) => {
  const { kpiDateData, isThroughput } = props;
  return (
    <CardComponent header={""}>
      <div className="aoh-date-tooltip-container">
        <div className="aoh-date-container aoh-w-100">
          <DateSection
            actual={"actual"}
            comparisionColorCode={"RED"}
            predictedCompletion={"predicted_completion"}
            target={"target"}
            targetCompletion={"target_completion"}
            unitsProduced={"units_produced"}
            unitsPlanned={"units_planned"}
          />
        </div>
        <div className="aoh-date-completion-container aoh-pt-3 aoh-pb-2">
        {!isThroughput && (
            <CompletionSection
              actual={"actual"}
              comparisionColorCode={"RED"}
              predictedCompletion={"predicted_completion"}
              target={"target"}
              targetCompletion={"target_completion"}
              unitsProduced={"units_produced"}
              unitsPlanned={"units_planned"}
            />
          )}
          {isThroughput && (
            <ThroughputSection
              actual={""}
              comparisionColorCode={"GREEN"}
              predictedCompletion={""}
              target={""}
              targetCompletion={""}
              unitsPlanned={""}
              unitsProduced={""}
            />
          )}
        </div>
      </div>

    </CardComponent>
  );
};

export default KpiDateTooltip;
