import React from "react";
import CardComponent from "../../resuable/card";
import { StepsTimeline } from "./steps-timeline";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import APIErrorMessage from "utils/api-error";
import Errorhandler from "utils/error-handler";
import { batchScheduleDataType, batchScheduleDataObject} from 'utils/data-types';

interface ScheduleCardProps {
  batchScheduleData: batchScheduleDataType;
  isLoading: boolean;
  isError: boolean;
}

const BatchSchedule: React.FC<ScheduleCardProps> = (props) => {
  const { batchScheduleData, isLoading, isError } = props;
  return (
    <CardComponent header={"Batch Schedule"}>
      <Errorhandler
        isLoading={isLoading}
        isError={isError}
        loaderColor={"BLUE"}
      />
      {!isLoading &&
      !isError &&
      batchScheduleData &&
      batchScheduleData?.length > 0 ? (
        <div className="d-flex relative">
          <div className="d-flex flex-column absolute-container rotation-1">
            <div className="d-flex align-items-center justify-content-center absolute ">
              {batchScheduleData?.map(
                (data: batchScheduleDataObject, index: number) => {
                  return (
                    <StepsTimeline
                      key={index}
                      index={index}
                      lineType={data.status === "Complete" ? "SOLID" : "DASH"}
                      nextLine={batchScheduleData?.length ?? 0}
                      status={data.status}
                    />
                  );
                }
              )}
            </div>
          </div>
          <div className="ops-ps-7 d-flex flex-column">
            {batchScheduleData?.map(
              (
                el: batchScheduleDataObject,
                index: number
              ) => {
               
                return (
                  
                  <div key={index}>
                    <div
                      className={!index ? "d-flex ops-pt-3" : "d-flex ops-pt-4"}
                    >
                      <div className="aoh-schedule-title ops-fw-medium ops-fs-5 ">
                        <span className="ops-text-dark ops-fw-semibold ops-fs-5">
                          {el?.phase ?? "N/A"}{" "}
                        </span>
                        {el?.status ?? ""}
                      </div>
                    </div>
                    <div className="d-flex ops-pt-2">
                      <div
                        className={
                          (el?.status==="Complete" || el?.status==="In Progress"
                            ? "ops-text-dark"
                            : "ops-text-light") + " ops-fw-medium ops-fs-5"
                        }
                      >
                        <span
                          className={
                            "ops-text-light ops-fw-semibold ops-fs-6 ops-me-13 aoh-start"
                          }
                        >
                          START{" "}
                        </span>
                        {el?.status==="Up Next"
                          ? <span className="ops-fw-semibold aoh-start-batch-phase">{"Est. "+el?.start}</span>
                          : <span className="ops-fw-semibold aoh-start-batch-phase">{el?.start ?? "N/A"}</span>}
                        {el?.start?.startsWith("Today") && (
                          <span
                            className={
                              el?.start?.startsWith("Today")
                                ? "ops-text-dark ops-fw-semibold ops-fs-5 aoh-start-batch-phase"
                                : "ops-text-light ops-fw-semibold ops-fs-5 aoh-start-batch-phase"
                            }
                          >
                            {" "}
                            {el?.start?.split("Today")?.[1] ?? "N/A"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex ops-pt-2">
                      <div
                        className={
                          (el?.status==="Complete" 
                            ? "ops-text-dark"
                            : "ops-text-light") + " ops-fw-medium ops-fs-5"
                        }
                      >
                        <span className="ops-text-light ops-fw-semibold ops-fs-6 ops-me-17">
                          END
                        </span>
                       <span className="ops-fw-semibold"> {el?.status==="Up Next" || el?.status==="In Progress"
                          ? "Est. "+ el?.end
                          : (el?.end ?? "N/A")}</span>
                        {el?.end?.startsWith("Today") && (
                          <span
                            className={
                              el?.end?.startsWith("Today")
                                ? "ops-text-dark ops-fw-semibold ops-fs-5 "
                                : "ops-text-light ops-fw-semibold ops-fs-5"
                            }
                          >
                            {" "}
                            {el?.phase==="Up Next"? ("Est."+ el?.end) : el?.end ?? "N/A"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : !isLoading &&
        !isError &&
        (!batchScheduleData || batchScheduleData?.length === 0) ? (
        <APIErrorMessage apierror={"No Data Available"} />
      ) : (
        <></>
      )}
    </CardComponent>
  );
};

export default BatchSchedule;
